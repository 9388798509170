import noPhoto from 'assets/img/no-photos.png';
import { fetchProductDetailsApi } from 'entities/Product/services/productApi';
import { makeAutoObservable, runInAction } from 'mobx';

import { ButtonState } from 'shared/enum/enums';
import { ProductData } from 'shared/types/ProductDetailsType';
import { updateViewedProducts } from 'shared/utils/updateViewedProducts';

import { RootStore } from 'stores/RootStore';

class ProductDetailsStore {
  product: ProductData | null = null;
  productState: 'pending' | 'fulfilled' | 'rejected' | null = null;
  error: Error | null = null;
  buttonState: ButtonState = ButtonState.IDLE;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  async loadData(productUrl: string) {
    this.productState = 'pending';
    try {
      const response = await fetchProductDetailsApi(productUrl);
      runInAction(() => {
        this.product = response.data.data;
        this.productState = 'fulfilled';
        this.error = null;

        if (this.product.id) {
          updateViewedProducts(String(this.product.id)); // Обновляем список просмотренных товаров в LocalStorage
          this.fetchViewedProducts(); // Загружаем просмотренные товары
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('Неизвестная ошибка');
        this.productState = 'rejected';
      });
    }
  }

  setButtonState(newState: ButtonState) {
    this.buttonState = newState;
  }

  updateButtonState() {
    if (!this.product?.cost_params?.in_stock) {
      this.setButtonState(ButtonState.OUT_OF_STOCK);
    } else if (!this.rootStore.cartStore.isFirstProduct(this.product.id)) {
      this.setButtonState(ButtonState.IN_CART);
    } else {
      this.setButtonState(ButtonState.IDLE);
    }
  }

  getFallbackImage() {
    return this.product?.detail_picture || this.product?.preview_picture || noPhoto;
  }

  async fetchViewedProducts() {
    const viewedProductIds = JSON.parse(localStorage.getItem('viewedProducts') || '[]');
    await this.rootStore.viewedProductsStore.fetchViewedProducts(viewedProductIds.map(Number));
  }

  get isLoading() {
    return this.productState === 'pending';
  }

  get productId(): string | null {
    return this.product ? String(this.product.id) : null;
  }
}

export default ProductDetailsStore;
