import { FC, useState } from 'react';
import ToggleComponent from 'ui-kit/ToggleComponent';

import { RecursiveCategory } from 'stores/MenuStore';

import ThirdLevelCategory from '../ThirdLevelCategory';
import * as S from './styles';

type SubCategoryProps = {
  subCategory: RecursiveCategory;
};

const SubCategory: FC<SubCategoryProps> = ({ subCategory }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleContent = () => {
    setIsExpanded(!isExpanded);
  };

  // eslint-disable-next-line no-nested-ternary
  const elementsToShow = subCategory.children_recursive
    ? isExpanded
      ? subCategory.children_recursive
      : subCategory.children_recursive.slice(0, 6)
    : [];

  return (
    <S.BoxesList key={subCategory.id} className="list-unstyled">
      <S.BoxesTitle>
        <S.BoxesLink to={subCategory.url}>{subCategory.name}</S.BoxesLink>
      </S.BoxesTitle>
      {elementsToShow.map((thirdLevelCategory: RecursiveCategory) => (
        <ThirdLevelCategory key={thirdLevelCategory.id} thirdLevelCategory={thirdLevelCategory} />
      ))}
      {subCategory.children_recursive && subCategory.children_recursive.length > 6 && (
        <ToggleComponent
          isVisible={isExpanded}
          onClick={toggleContent}
          text={isExpanded ? 'Свернуть' : 'Показать ещё'}
          iconName="arrow2"
        />
      )}
    </S.BoxesList>
  );
};

export default SubCategory;
