import { SearchCategoryType } from 'shared/types/SearchType';

import * as S from './styles';

type SearchCategoriesProps = {
  categories: SearchCategoryType[];
  onClose: () => void;
};

const SearchCategories = ({ categories, onClose }: SearchCategoriesProps) => {
  return categories.length >= 1 ? (
    <S.Block>
      <S.Title>Категории</S.Title>
      {categories.map((item: SearchCategoryType) => (
        <S.ContentItemLink key={item.name} onClick={onClose} to={item.url}>
          <S.Label>{item.name}</S.Label>
        </S.ContentItemLink>
      ))}
    </S.Block>
  ) : null;
};

export default SearchCategories;
