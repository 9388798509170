import api from 'stores/Api';
import { toServerFormatState } from 'stores/catalog/FilterCatalogStore';

type RequestBodyProps = {
  sort: string;
  direction: string;
  filter: toServerFormatState;
  page: number;
  get_filter?: boolean;
  search?: string;
};

const getApiTypeFromPath = (path: string): string => {
  const pathMapping: { [key: string]: string } = {
    catalog: 'catalog_elements',
    brands: 'brands',
    search: '/search/detail',
  };

  const segments = path.split('/');

  for (const segment of segments) {
    if (pathMapping[segment]) {
      return pathMapping[segment];
    }
  }

  return '';
};

const createRequestBody = (
  sort: string,
  direction: string,
  filter: toServerFormatState,
  page: number,
  includeFilter: boolean,
  searchQuery: string
): RequestBodyProps => {
  const requestBody: RequestBodyProps = {
    sort,
    direction,
    filter,
    page,
  };

  if (includeFilter) {
    requestBody.get_filter = true;
  }
  if (searchQuery) {
    requestBody.search = searchQuery;
  }

  return requestBody;
};

const fetchCatalogElementsApi = async (
  path: string,
  trimmedUrl: string,
  sort: string,
  direction: string,
  filter: toServerFormatState,
  page: number
) => {
  const apiType = getApiTypeFromPath(path);
  const query = new URLSearchParams(window.location.search).get('q') || '';
  const isPageOne = page === 1;
  const isSearchDetail = apiType === '/search/detail';
  const includeFilter = isPageOne;
  const searchQuery = isSearchDetail ? query : '';

  const requestBody = createRequestBody(sort, direction, filter, page, includeFilter, searchQuery);

  try {
    const response = await api.post(`${apiType}${trimmedUrl}`, requestBody);
    return response;
  } catch (error) {
    console.error('API Request failed:', error);
    throw error;
  }
};

export default fetchCatalogElementsApi;
