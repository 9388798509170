import { Helmet } from 'react-helmet-async';
import { FavoritesPage } from 'modules/Favorites';

import Breadcrumbs from 'components/Breadcrumb';
import Container from 'components/Container';
import ViewedProducts from 'components/ViewedProducts';

const Favorite = () => {
  return (
    <Container>
      <Helmet>
        <title>Товары в избранном</title>
      </Helmet>

      <Breadcrumbs />

      <h1 className="mb-5">Товары в избранном</h1>

      <FavoritesPage />
      <ViewedProducts />
    </Container>
  );
};

export default Favorite;
