import { observer } from 'mobx-react-lite';
import Loader from 'ui-kit/Loader';

import rootStore from 'stores/RootStore';

import CompareProductItem from '../components/CompareProductItem';
import TabsContainer from '../components/CompareTabs';
import { Category, CategoryData } from '../types/types';
import * as S from './styles';

export const ComprasionPage = observer(() => {
  const { comparisonListData, setActiveCategory, activeCategory, isLoading, totalElements } = rootStore.compareStore;

  if (isLoading) {
    return <Loader />;
  }

  if (
    !comparisonListData ||
    !comparisonListData[activeCategory] ||
    comparisonListData[activeCategory].elements.length === 0
  ) {
    return <S.Text>Пока здесь нет товаров для сравнения.</S.Text>;
  }

  const categories = comparisonListData.reduce((acc: Category[], value: CategoryData) => {
    return [...acc, { name: value.category_name, count: value.total_elements }];
  }, []);

  return (
    <>
      <S.HeaderBlock>
        <S.StyledSectionHeader showAll={false} badgeCount={totalElements} pageHeadingTitle="Сравнение" />
        {/* Пока функционал не готов */}
        {/* <S.StyledCheckbox>Показывать только различия</S.StyledCheckbox> */}
        {/* <S.StyledButton variant="secondary">
          <S.TrashIcon icon="trash" className="icon" /> Удалить список
        </S.StyledButton> */}
      </S.HeaderBlock>

      {categories && (
        <TabsContainer categories={categories} onClick={setActiveCategory} activeCategory={activeCategory} />
      )}

      <S.CompareProductContainer>
        {comparisonListData[activeCategory].elements.map((item, index) => (
          <CompareProductItem key={`${item.id}_${index}`} product={item} />
        ))}
      </S.CompareProductContainer>
    </>
  );
});
