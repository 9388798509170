import { makeAutoObservable, runInAction } from 'mobx';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';

import api from 'stores/Api';
import { RootStore } from 'stores/RootStore';

export interface Brand {
  id: number;
  name: string;
  url: string;
}

export type BrandsResponse = {
  data: Array<{ [key: string]: Brand[] }>;
};

class BrandsListStore {
  data: { [key: string]: Brand[] } | null = null;
  dataState: IPromiseBasedObservable<{ [key: string]: Brand[] }> | null = null;
  error: Error | null = null;

  constructor(private rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  async loadData() {
    try {
      const response = await api.get<BrandsResponse>('brands/list');
      const formattedData: { [key: string]: Brand[] } = {};

      response.data.data.forEach((item) => {
        Object.entries(item).forEach(([key, brands]) => {
          formattedData[key] = brands;
        });
      });

      runInAction(() => {
        this.data = formattedData;
        this.dataState = fromPromise(Promise.resolve(this.data));
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('Неизвестная ошибка');
        throw error;
      });
    }
  }
}

export default BrandsListStore;
