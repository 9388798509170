import { observer } from 'mobx-react-lite';
import Loader from 'ui-kit/Loader';

import CaseProducts from 'components/CaseProducts';

import rootStore from 'stores/RootStore';

const ViewedProducts = observer(() => {
  const { viewedProductsStore } = rootStore;

  if (viewedProductsStore.isLoading) return <Loader position="static" />;

  const products = viewedProductsStore.viewedData;

  if (products.length === 0) return null;

  return <CaseProducts idSlider="5" pageHeadingTitle="Просмотренное" link="#Просмотренное" products={products} />;
});

export default ViewedProducts;
