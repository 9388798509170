import { IMaskInput } from 'react-imask';
import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

export const ModalInput = styled(IMaskInput)`
  letter-spacing: ${pxToVw(1)};
  margin-bottom: ${pxToVw(16)};
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.375rem ${pxToVw(16)};
  color: var(--text-primary);
  font-weight: 400;
  font-size: ${pxToVw(16)};
  line-height: 1.5;
  text-overflow: ellipsis;
  background-color: var(--color-field-bg);
  background-clip: padding-box;
  border: ${pxToVw(1)} solid var(--color-field-bg);
  border-radius: ${pxToVw(16)};
  transition: border-color 0.2s ease-in-out;
  appearance: none;
  height: ${pxToVw(52)};
  &:focus {
    outline: var(--primary);
    border-color: var(--primary);
  }
`;

export const ModalText = styled.div`
  margin-bottom: ${pxToVw(16)};
`;

export const ModalEditBtn = styled.button`
  background-color: transparent;
  padding: 0;
  border-width: 0;
`;
