import { FC, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Dropdown from 'ui-kit/Dropdown';
import Loader from 'ui-kit/Loader';

import ProductCard from 'components/ProductCard';

import useScrollPagination from 'shared/hooks/useScrollPagination';
import { dropdownOptions } from 'shared/mocks/productsData';
import { ProductType } from 'shared/types/ProductType';

import { SortByProps } from 'stores/catalog/FilterCatalogStore';
import rootStore from 'stores/RootStore';

import * as S from './styles';

const CatalogSection: FC = observer(() => {
  const { isLoading, sortBy, setCurrentPage, currentPage, catalogData } = rootStore.filterCatalogStore;

  const handleScroll = useMemo(
    () => () => {
      setCurrentPage(currentPage + 1);
    },
    [currentPage, setCurrentPage]
  );
  const containerRef = useScrollPagination(handleScroll);

  const handleSortSelection = (sort: string, label: string) => {
    const direction = label === 'Дешевле' ? 'ASC' : 'DESC';
    const params: SortByProps = { sort, direction, label };

    rootStore.filterCatalogStore.setSortBy(params);
  };

  return (
    <S.CatalogContainer ref={containerRef}>
      {isLoading && <Loader position="static" />}
      <S.DropContainer>
        <Dropdown defaultOption={sortBy.label} options={dropdownOptions} onSelect={handleSortSelection} />
      </S.DropContainer>
      <S.ProductContainer>
        {catalogData.length > 0 ? (
          catalogData.map((item: ProductType, index) => (
            <S.ProductCardContainer key={index}>
              <ProductCard data={item} />
            </S.ProductCardContainer>
          ))
        ) : (
          <S.EmptyContainer>
            <S.Title>Ничего не найдено</S.Title>
          </S.EmptyContainer>
        )}
      </S.ProductContainer>
    </S.CatalogContainer>
  );
});

export default CatalogSection;
