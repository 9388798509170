import { FC, useCallback } from 'react';
import Sticky from 'react-stickynode';
import { observer } from 'mobx-react-lite';

import FiltersCatalog from 'components/Filters';

import { IFiltersStoreType } from 'shared/types/ProductType';

import rootStore from 'stores/RootStore';

import * as S from './styles';

const FiltersSection: FC = observer(() => {
  const { filterCatalogStore } = rootStore;

  const handleResetFilters = useCallback(() => {
    filterCatalogStore.resetFilters();
  }, [filterCatalogStore]);

  return (
    <S.FilterContainer>
      <Sticky enabled={true} top={0} bottomBoundary={0} innerZ="15">
        <S.FilterWrap>
          <S.FilterInner>
            {filterCatalogStore.filtersData.map((accordionItem: IFiltersStoreType) => (
              <FiltersCatalog key={accordionItem.id} accordionItem={accordionItem} />
            ))}
          </S.FilterInner>
          <S.ButtonReset onClick={handleResetFilters} className="filter__btn-reset btn-primary btn">
            Сбросить
          </S.ButtonReset>
        </S.FilterWrap>
      </Sticky>
    </S.FilterContainer>
  );
});

export default FiltersSection;
