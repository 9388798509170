import {
  FC,
  FormEvent,
  KeyboardEvent as ReactKeyboardEvent,
  MouseEvent as ReactMouseEvent,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import _debounce from 'lodash/debounce';
import { observer } from 'mobx-react-lite';
import { RoutesLinks } from 'RoutesLinks';
import Loader from 'ui-kit/Loader';

import SearchInput from 'components/Search/SearchInput';

import { useOnClickOutside } from 'shared/hooks/useOnClickOutside';

import rootStore from 'stores/RootStore';

import { IHeaderSearch } from './Models';
import SearchResultList from './SearchResultList';
import * as S from './styles';

const HeaderSearch: FC<IHeaderSearch> = observer(() => {
  const { searchPreviewStore } = rootStore;
  const navigate = useNavigate();
  const [query, setQuery] = useState('');

  const dropdown = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent | TouchEvent) => {
    if (dropdown.current && !dropdown.current.contains(e.target as Node)) {
      searchPreviewStore.setIsOpened(false);
    }
  };

  useOnClickOutside(dropdown, handleClickOutside);

  const handleBlur = (e: FormEvent<HTMLInputElement>) => {
    return e;
  };

  const debouncedFetchResults = _debounce(() => {
    searchPreviewStore.fetchSearchResults();
  }, 1000);

  const handleKeyPress = (e: ReactKeyboardEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    const isShowAutocomplete: boolean = value.length > 1;
    if (e.code === 'Enter' && isShowAutocomplete) {
      searchPreviewStore.setIsOpened(false);
      navigate(`${RoutesLinks.SEARCH}?q=${value}`);
    }
  };

  const handleSearch = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      setQuery(value);
      const isShowAutocomplete: boolean = value.length > 1;

      searchPreviewStore.updateQuery(value);
      debouncedFetchResults();
      searchPreviewStore.setIsOpened(isShowAutocomplete);
    },
    [debouncedFetchResults, searchPreviewStore]
  );

  const handleButtonSearch = () => {
    const isShowAutocomplete: boolean = query.length > 1;
    if (isShowAutocomplete) {
      searchPreviewStore.setIsOpened(false);
      navigate(`${RoutesLinks.SEARCH}?q=${query}`);
    }
  };

  const handleFocus = (e: ReactMouseEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const isShowAutocomplete: boolean = value.length > 1;
    searchPreviewStore.setIsOpened(isShowAutocomplete);
  };

  const handleClose = () => {
    searchPreviewStore.updateQuery('');
    searchPreviewStore.setIsOpened(false);
  };

  return (
    <S.HeaderSearch ref={dropdown} className="header__search">
      {searchPreviewStore.isLoading && <Loader />}
      <SearchInput
        onFocus={handleFocus}
        onInput={handleSearch}
        onBlur={handleBlur}
        onButtonClick={handleButtonSearch}
        onKeyPress={handleKeyPress}
      />
      <S.Drop $isopened={searchPreviewStore.isOpenedSearch.toString()}>
        <SearchResultList catalogList={searchPreviewStore.catalogList} onClose={handleClose} />
      </S.Drop>
    </S.HeaderSearch>
  );
});

export default HeaderSearch;
