import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

export const BoxesItem = styled.li`
  &:not(:last-of-type) {
    margin-bottom: ${pxToVw(8)};
  }
`;

export const BoxesLink = styled(Link)`
  display: inline-flex;
  color: var(--text-secondary);
`;
