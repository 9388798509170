import React, { useRef, useState } from 'react';
import { A11y, Navigation } from 'swiper/modules';
import { Swiper, SwiperRef } from 'swiper/react';

import SliderArrows from 'components/SliderArrows';

import { TabProps } from '../../types/types';
import * as S from './styles';

const Tabs: React.FC<TabProps> = ({ categories, onClick, activeCategory }) => {
  const swiperRef = useRef<SwiperRef>(null);
  const prevProducts = `prev-products-Tabs`;
  const nextProducts = `next-products-Tabs`;

  const [activeIndex, setActiveIndex] = useState<number>(activeCategory);

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handleTabClick = (index: number) => {
    setActiveIndex(index);
    if (onClick) {
      onClick(index);
    }
  };

  return (
    <S.TabsContainer>
      <S.NavBarInner>
        <Swiper
          modules={[Navigation, A11y]}
          spaceBetween={32}
          slidesPerView={'auto'}
          navigation={{
            prevEl: `.${prevProducts}`,
            nextEl: `.${nextProducts}`,
          }}
          ref={swiperRef}
        >
          {categories.map((category, index) => (
            <S.TabContainer
              key={index}
              className={`nav-bar__item ${activeIndex === index ? 'active' : ''}`}
              onClick={() => handleTabClick(index)}
            >
              <S.TabTitle>{category.name}</S.TabTitle>
              <S.TabCount color="warn">{category.count}</S.TabCount>
            </S.TabContainer>
          ))}
        </Swiper>
        <SliderArrows goPrev={goPrev} goNext={goNext} prevClass={prevProducts} nextClass={nextProducts} />
      </S.NavBarInner>
    </S.TabsContainer>
  );
};

export default Tabs;
