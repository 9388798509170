import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${pxToVw(32)};
`;

export const Text = styled.div`
  font-weight: 700;
`;

export const FlexBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${pxToVw(32)};
  margin-bottom: ${pxToVw(32)};
`;

export const FavoritesContainer = styled.div`
  flex-grow: 1;
`;
