import { LoaderFunctionArgs } from 'react-router-dom';

import rootStore from 'stores/RootStore';

export const appLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const kiosk = url.searchParams.get('kiosk');
  await rootStore.cartStore.fetchProductsFromCart();
  await rootStore.compareStore.fetchComparisonListMini();
  rootStore.favoritesStore.getFavorites();
  if (kiosk !== null) localStorage.setItem('kiosk', kiosk);

  return null;
};
