import { FC, ReactNode } from 'react';

import * as S from './styles';

interface SideBoxProps {
  title: string;
  icon: string;
  children?: ReactNode;
}

const SideBox: FC<SideBoxProps> = ({ title, icon, children }) => {
  return (
    <S.Box>
      <S.Title>
        <S.Icon icon={icon} />
        {title}
      </S.Title>
      {children}
    </S.Box>
  );
};

export default SideBox;
