import { observer } from 'mobx-react-lite';

import rootStore from 'stores/RootStore';

import * as S from './styles';

const CartTotal = observer(() => {
  const { cartStore } = rootStore;

  const handleCheckout = () => {
    cartStore.openThankYouModal();
  };

  return (
    <S.StyledTotal total={cartStore.total}>
      <S.TotalBlock>
        <S.BoldTitle>Оплата:</S.BoldTitle>
        <S.BlueTitle>На кассе</S.BlueTitle>
      </S.TotalBlock>

      <S.Btn onClick={handleCheckout} disabled={!cartStore.productsData.length}>
        <S.WalletIcon icon="wallet" /> Оформить
      </S.Btn>
    </S.StyledTotal>
  );
});

export default CartTotal;
