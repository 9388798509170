import styled from 'styled-components';
import Button from 'ui-kit/Button';
import Checkbox from 'ui-kit/Checkbox';
import SectionHeader from 'ui-kit/SectionHeader';
import Sprite from 'ui-kit/Sprite';

import { pxToVw } from 'shared/helpers/pxToVw';
import { ClassNameProps } from 'shared/types/ClassNameProps';

export const CompareProductContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-wrap: nowrap;
`;

export const HeaderBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${pxToVw(24)};
`;

export const StyledSectionHeader = styled(SectionHeader)`
  margin-bottom: ${pxToVw(0)};
  margin-right: ${pxToVw(24)};
`;

export const StyledCheckbox = styled(Checkbox)`
  flex-grow: 0;
  margin-right: ${pxToVw(24)};
  font-weight: 700;
  color: #181818;
`;

export const TrashIcon = styled(Sprite)<ClassNameProps>`
  width: ${pxToVw(20)};
  height: ${pxToVw(20)};
  pointer-events: none;
  fill: var(--primary);
  margin-right: ${pxToVw(4)};
`;

export const StyledButton = styled(Button)`
  padding: ${pxToVw(4)} ${pxToVw(8)};
`;

export const Text = styled.div`
  font-weight: 700;
`;
