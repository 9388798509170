import { FC } from 'react';

import { IContainer } from './IContainer';
import * as S from './styles';

const Container: FC<IContainer> = ({ className, children }) => {
  return <S.Container className={className}>{children}</S.Container>;
};

export default Container;
