import { ReactComponent as ArrowIcon } from 'assets/img/icons/arrow-icon.svg';
import { fadeInAnimation } from 'modules/Header/components/MiniCartItem/styles';
import styled from 'styled-components';
import Input from 'ui-kit/Input';

import { pxToVw } from 'shared/helpers/pxToVw';

export const AccordionContainer = styled.div`
  border-radius: ${pxToVw(4)};
  margin-bottom: ${pxToVw(12)};
  overflow: hidden;
  border: none;
  outline: none;
`;

export const StyledArrowIcon = styled(ArrowIcon)<{ $isOpen: boolean }>`
  width: ${pxToVw(24)};
  height: ${pxToVw(24)};
  margin-right: ${pxToVw(12)};
  transition: transform 0.3s ease;
  transform: rotate(${({ $isOpen }: { $isOpen: boolean }) => ($isOpen ? '0deg' : '-90deg')});
`;

export const AccordionHeader = styled.div`
  margin-bottom: ${pxToVw(8)};
  font-family: var(--body-font-family);
  font-size: ${pxToVw(18)};
  font-weight: 700;
  color: var(--primary);
  cursor: pointer;
`;

export const AccordionContent = styled.div<{ $isOpen: boolean; $blockwidth: string; $showMore: boolean }>`
  display: block;
  position: relative;
  max-height: ${({ $isOpen, $blockwidth }: { $isOpen: boolean; $blockwidth: string }) =>
    $blockwidth && $isOpen ? $blockwidth : '0'};
  overflow: ${({ $showMore }: { $showMore: boolean }) => ($showMore ? '' : 'hidden')};
  overflow-x: ${({ $showMore }: { $showMore: boolean }) => ($showMore ? 'hidden' : '')};
  transition: max-height 0.3s ease;
`;

export const ShowMoreButton = styled.div`
  padding-top: ${pxToVw(12)};
  padding-bottom: ${pxToVw(12)};
  font-size: ${pxToVw(14)};
  font-weight: 700;
  color: var(--primary);
  cursor: pointer;
`;

export const StyledInput = styled(Input)`
  margin: ${pxToVw(12)} 0;
  width: 100%;
  height: ${pxToVw(36)};
  padding: ${pxToVw(8)} ${pxToVw(14)};
  border-radius: ${pxToVw(8)};
  font-size: ${pxToVw(14)};
  line-height: ${pxToVw(18)};
  color: var(--body-color);
  background-color: var(--white);
  border: ${pxToVw(1)} solid var(--primary);
  animation: ${fadeInAnimation} 0.3s ease forwards;
`;
