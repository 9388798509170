import { lazy } from 'react';
import { Helmet } from 'react-helmet-async';

import Breadcrumbs from 'components/Breadcrumb';
import Container from 'components/Container';

import rootStore from 'stores/RootStore';

import * as S from './styles';

const LazyTopBrands = lazy(() => import('components/TopBrands'));

const MainBrands = () => {
  const { data } = rootStore.brandsListStore;

  return (
    <Container>
      <Helmet>
        <title>Все бренды</title>
      </Helmet>

      <Breadcrumbs />

      <h1 className="mb-4">Главные бренды</h1>

      <LazyTopBrands showAllBtn={false} />

      <h2 className="mb-4">Все бренды</h2>

      <S.List className="list-unstyled">
        {data
          ? Object.entries(data).map(([letter, brands]) => (
              <S.ListItem key={letter}>
                <S.ItemLetter>{letter}</S.ItemLetter>
                <S.ListLinks className="list-unstyled">
                  {brands.map((brand) => (
                    <S.LinksItem key={brand.id}>
                      <S.ListValue to={brand.url}>
                        {brand.name}
                        <S.ListArrow icon="arrow" />
                      </S.ListValue>
                    </S.LinksItem>
                  ))}
                </S.ListLinks>
              </S.ListItem>
            ))
          : ''}
      </S.List>
    </Container>
  );
};

export default MainBrands;
