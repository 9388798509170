import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

export const CardSticker = styled.div`
  position: absolute;
  top: ${pxToVw(16)};
  left: ${pxToVw(18)};
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;

export const CardStickerItem = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;
  order: 2;
  min-height: ${pxToVw(24)};

  &:first-of-type {
    order: 0;

    &.box {
      order: 1;

      + .badge__item {
        order: 0;
        width: auto;
        margin-right: ${pxToVw(8)};
      }
    }
  }

  &:not(:last-of-type) {
    margin-right: ${pxToVw(8)};
    margin-bottom: ${pxToVw(6)};
  }

  &:not(:first-of-type, .box) {
    width: 100%;
  }

  &.box {
    order: 1;
    margin-right: ${pxToVw(6)};
  }
`;
