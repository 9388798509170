import styled from 'styled-components';
import Button from 'ui-kit/Button';

import { pxToVw } from 'shared/helpers/pxToVw';

export const Control = styled(Button)`
  position: absolute;
  top: 50%;
  margin-top: ${pxToVw(-22)};
  z-index: 1;
  display: flex;
  padding: 0;
  width: ${pxToVw(44)};
  height: ${pxToVw(44)};
  transition: opacity 0.2s ease-in-out;

  &.swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }

  &.prev {
    left: ${pxToVw(-44)};
    transform: rotate(180deg);
  }

  &.next {
    right: ${pxToVw(-44)};
  }
`;
