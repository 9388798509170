import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';
import { truncateMultiLine } from 'shared/helpers/truncateMultiLine';
import { ClassNameProps } from 'shared/types/ClassNameProps';

export const PromotionsCard = styled(Link)<ClassNameProps>`
  max-width: ${pxToVw(220)};
  height: ${pxToVw(301)};
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: ${pxToVw(16)};
  color: var(--white);
  line-height: 1.25;
  margin: 0 auto;
`;

export const PromotionsCardMedia = styled.div`
  height: ${pxToVw(220)};
  position: relative;
`;

export const PromotionsCardImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`;

export const PromotionsCardContent = styled.div`
  padding: ${pxToVw(6)} ${pxToVw(16)} ${pxToVw(7)} ${pxToVw(24)};
  height: ${pxToVw(81)};
  position: relative;
  z-index: 1;
  margin-top: auto;
  color: var(--white);
  display: flex;
  align-items: center;
  background-color: inherit;
  box-shadow: var(--shadow-Z200);
`;

export const PromotionsCardTitle = styled.div`
  font-size: ${pxToVw(16)};
  ${truncateMultiLine(3)}
`;
