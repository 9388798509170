import check from 'assets/img/decor/check-white.svg';
import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';
import { FormCheckProps } from 'shared/types/FormCheckTypes';
import { calculatePaddings } from 'shared/utils/calculatePaddings';

export const Checkbox = styled.div<FormCheckProps>`
  min-height: ${pxToVw(24)};
  margin-bottom: 0;
  display: inline-flex;
  align-items: center;
  line-height: 1.2;
  user-select: none;
  flex-direction: ${(props: FormCheckProps) => (props.$inline ? 'row' : 'column')};
  flex-grow: 1;
`;

export const Input = styled.input<FormCheckProps>`
  appearance: none;
  order: ${(props: FormCheckProps) => (props.$reverse ? '1' : '0')};
  width: ${pxToVw(24)};
  height: ${pxToVw(24)};
  border: ${pxToVw(2)} solid var(--stroke);
  border-radius: ${pxToVw(5)};
  background-image: url('${check}');
  background-color: var(--white);
  background-repeat: no-repeat;
  background-position: center;
  background-size: ${pxToVw(16)};
  transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;

  &:checked {
    background-color: var(--primary);
    border-color: var(--primary);
  }

  &:focus {
    box-shadow: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const Label = styled.label<FormCheckProps>`
  padding: ${(props: FormCheckProps) => calculatePaddings(props)};
  display: flex;
  margin-bottom: 0;
  width: 100%;
  justify-content: flex-start;
  align-items: center;

  ${Input}:disabled + & {
    color: var(--text-disabled);
    cursor: not-allowed;
  }
`;
