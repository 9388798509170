import { FC } from 'react';

import { ISearchInput } from './Models/ISearchInput';
import * as S from './styles';

const SearchInput: FC<ISearchInput> = ({ className, onInput, onBlur, onFocus, onButtonClick, onKeyPress }) => {
  return (
    <S.Block>
      <S.SearchInput
        className={className}
        onInput={onInput}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        placeholder="Искать товары со скидкой"
        aria-label="Поиск"
      />

      <S.Btn onClick={onButtonClick} variant="transparent" type="button" aria-label="Искать">
        <S.Icon icon="search" />
      </S.Btn>
    </S.Block>
  );
};

export default SearchInput;
