import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { commonImageStyles } from 'shared/helpers/generalStyles';
import { pxToVw } from 'shared/helpers/pxToVw';
import { truncateMultiLine } from 'shared/helpers/truncateMultiLine';

export const ProductHeading = styled.header`
  margin-bottom: ${pxToVw(16)};
  display: flex;
  align-items: flex-start;
`;

export const Title = styled.h1`
  max-width: 75%;
  font-size: ${pxToVw(24)};
  line-height: ${pxToVw(32)};
  ${truncateMultiLine(3)}
`;

export const Brand = styled.div`
  margin-left: auto;
  flex-shrink: 0;
  max-width: ${pxToVw(85)};
`;

export const BrandBox = styled(Link)`
  padding: ${pxToVw(4)} ${pxToVw(8)};
  height: ${pxToVw(50)};
  background-color: var(--white);
  border-radius: ${pxToVw(8)};
  display: flex;
`;

export const BrandImg = styled.img`
  ${commonImageStyles}
`;
