import styled, { keyframes } from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

import { ReactComponent as ArrowIcon } from '../../assets/img/icons/arrow-icon.svg';

const rotateIn = keyframes`
  from {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(0deg);
  }
`;

const rotateOut = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-90deg);
  }
`;

export const OptionItem = styled.div`
  padding: ${pxToVw(8)} ${pxToVw(12)};
  cursor: pointer;

  &:hover {
    background-color: rgba(232, 232, 232, 1);
  }
`;

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  min-height: ${pxToVw(40)};
  width: ${pxToVw(210)};
`;

export const Dropdown = styled.div`
  width: ${pxToVw(210)};
  height: 100%;
  padding: ${pxToVw(12)};
  font-size: ${pxToVw(16)};
  font-weight: 700;
  border-radius: ${pxToVw(8)};
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  color: black;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

export const DropdownList = styled.div`
  position: absolute;
  width: ${pxToVw(210)};
  top: ${pxToVw(50)};
  left: 0;
  z-index: 1;
  background-color: white;
  border-radius: ${pxToVw(8)};
`;

export const StyledArrowIcon = styled(ArrowIcon)<{ $isOpen: boolean }>`
  width: ${pxToVw(18)};
  height: ${pxToVw(18)};
  position: absolute;
  top: ${pxToVw(16)};
  right: ${pxToVw(10)};
  animation: ${({ $isOpen }: { $isOpen: boolean }) => ($isOpen ? rotateIn : rotateOut)} 0.3s forwards;
`;
