import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { observer } from 'mobx-react-lite';
import Loader from 'ui-kit/Loader';

import Container from 'components/Container';
import ProductCard from 'components/ProductCard';

import * as S from 'shared/helpers/generalStyles';
import { ProductType } from 'shared/types/ProductType';

import rootStore from 'stores/RootStore';

const PopularProduct = observer(() => {
  const { popularProductStore } = rootStore;

  useEffect(() => {
    popularProductStore.loadData();
  }, [popularProductStore]);

  if (popularProductStore.isLoading) return <Loader position="static" />;

  if (popularProductStore.isError) {
    console.log('Error: ', popularProductStore.errorMessage);
    return null;
  }

  const products = (popularProductStore.data as ProductType[]) || [];

  return (
    <Container>
      <Helmet>
        <title>Самые популярные товары</title>
      </Helmet>

      <h1 className="mb-5">Самые популярные товары</h1>

      <S.Row>
        {products.map((instance) =>
          instance.cost_params?.cost === 0 ? null : (
            <S.Card key={instance.id}>
              <ProductCard data={instance} />
            </S.Card>
          )
        )}
      </S.Row>
    </Container>
  );
});

export default PopularProduct;
