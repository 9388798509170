import styled from 'styled-components';
import Button from 'ui-kit/Button';

import { pxToVw } from 'shared/helpers/pxToVw';
import { ClassNameProps } from 'shared/types/ClassNameProps';

export const Showcase = styled.section<ClassNameProps>`
  margin-bottom: ${pxToVw(32)};
  position: relative;
`;

export const ShowcaseBtn = styled(Button)`
  margin-top: ${pxToVw(16)};
  min-width: ${pxToVw(168)};

  .icon {
    margin-left: ${pxToVw(8)};
    width: ${pxToVw(16)};
    height: ${pxToVw(16)};
    fill: var(--white);
  }
`;
