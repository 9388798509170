import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { RoutesLinks } from 'RoutesLinks';
import Avatar from 'ui-kit/Avatar';
import { Size } from 'ui-kit/Avatar/types';

import rootStore from 'stores/RootStore';

import * as S from './styles';

const NavEnd: FC = observer(() => {
  const { authStore, modalStore, cartStore } = rootStore;
  const isLoggedIn = authStore.status === 'Success';
  const navigate = useNavigate();

  const handleLogout = async () => {
    await authStore.logout();
    if (!authStore.isLoggingOut) {
      toast.info('Вы успешно вышли из аккаунта');
      modalStore.closeUserControl();
    }
  };

  return (
    <S.Nav>
      <S.NavContainer>
        <S.NavItem onClick={() => navigate(RoutesLinks.CATALOG)}>
          <S.NavItemInner>
            <S.NavItemIcon icon="search" />
            <span>Каталог</span>
          </S.NavItemInner>
        </S.NavItem>

        <S.NavItem onClick={() => navigate(RoutesLinks.COMPARE)}>
          <S.NavItemInner>
            <S.NavItemIcon icon="compare" />
            <span>Сравнить</span>
          </S.NavItemInner>
        </S.NavItem>

        <S.NavItemBasket onClick={() => navigate(RoutesLinks.CART)}>
          <S.NavItemInner>
            <S.NavItemIcon icon="basket-line" />
            {cartStore.productsData.length > 0 ? (
              <S.NavItemBasketCount>{cartStore.productsData.length}</S.NavItemBasketCount>
            ) : (
              ''
            )}
          </S.NavItemInner>
        </S.NavItemBasket>

        <S.NavItem onClick={() => navigate(RoutesLinks.FAVORITE)}>
          <S.NavItemInner>
            <S.NavItemIcon icon="favorite-stroke" />
            <span>Избранное</span>
          </S.NavItemInner>
        </S.NavItem>

        {isLoggedIn ? (
          <S.NavItem onClick={handleLogout}>
            <S.NavItemInner>
              <Avatar title={authStore.userInfo?.name} size={Size.Small} />
              <div className="mt-1">Выйти?</div>
            </S.NavItemInner>
          </S.NavItem>
        ) : (
          <S.NavItem onClick={modalStore.openAuthModalModal}>
            <S.NavItemInner>
              <S.NavItemIcon icon="user" />
              <div>Войти</div>
            </S.NavItemInner>
          </S.NavItem>
        )}
      </S.NavContainer>
    </S.Nav>
  );
});

export default NavEnd;
