import axios from 'axios';

const API_BASE_URL =
  process.env.REACT_APP_CURRENT_ENV === 'staging'
    ? process.env.REACT_APP_API_DEV_URL
    : process.env.REACT_APP_API_BASE_URL;

const username = 'vsesmart-test';
const password = 'O33Q!_io';
const base = btoa(`${username}:${password}`);

const api = axios.create({
  baseURL: `${API_BASE_URL}/api/`,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json',
    AccessControlAllowOrigin: 'origin',
  },
});

api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      config.headers.Authorization = `Basic ${base} Bearer ${JSON.parse(accessToken)}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        console.error('Ошибка авторизации, требуется повторный вход');
      } else if (error.response.status === 404) {
        console.warn(`Ресурс не найден: ${error.config.url}`);
        return Promise.resolve(null);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
