import { fetchCatalogCategoriesApi } from 'entities/Catalog/services/catalogApi';
import { CategoryType, ChildCategory } from 'entities/Catalog/types';
import { makeAutoObservable, runInAction } from 'mobx';

import { RootStore } from 'stores/RootStore';

class CatalogStore {
  categoryData: CategoryType | null = null;
  childCategory: ChildCategory[] | null = null;
  currentParams: string = '';
  isLoading = false;
  error: Error | null = null;

  constructor(private rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  async fetchCatalogCategories(path?: string) {
    this.isLoading = true;
    const trimmedUrl = path ? '/' + path.replace(/\/$/, '') : '';
    this.currentParams = path ? trimmedUrl : '';

    try {
      const response = await fetchCatalogCategoriesApi(trimmedUrl);
      runInAction(() => {
        this.categoryData = response.data.data;
        this.childCategory = response.data.data.child_categories;
        this.isLoading = false;
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('Неизвестная ошибка');
        this.isLoading = false;
      });
    }
  }

  get errorMessage() {
    return this.error ? this.error.message : null;
  }
}

export default CatalogStore;
