import { toast } from 'react-toastify';
import {
  addProductToCompareApi,
  deleteProductFromCompareApi,
  fetchProductsFromCompareApi,
  fetchProductsFromCompareMiniApi,
} from 'entities/Compare/services/compareApi';
import { makeAutoObservable, runInAction } from 'mobx';
import { CategoryData, ProductTypeByCompare } from 'modules/Comparison/types/types';

import { ProductType } from 'shared/types/ProductType';

import { RootStore } from '../RootStore';

class CompareStore {
  comparisonListData: CategoryData[] | null = null;
  activeCategory: number = 0;
  totalElements: number = 0;
  error: Error | null = null;
  isLoading: boolean = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setActiveCategory = (activeIndex: number) => {
    runInAction(() => {
      this.activeCategory = activeIndex;
    });
  };

  isFirstProduct = (id: number) => {
    const data = this.comparisonListData;
    if (!data || data.length === 0) {
      return true;
    }
    const elements = data.reduce((acc: ProductTypeByCompare[], value: CategoryData) => {
      return [...acc, ...value.elements];
    }, [] as ProductTypeByCompare[]);

    return !elements.some((item) => item.id === id);
  };

  fetchComparisonList = async () => {
    try {
      this.isLoading = true;
      const compareIds = localStorage.getItem('compare') || '';
      const response = await fetchProductsFromCompareApi(
        this.rootStore.authStore.isAuth ? null : JSON.parse(compareIds)
      );

      runInAction(() => {
        const { data } = response.data;
        if (!data[this.activeCategory]) {
          this.setActiveCategory(data.length - 1);
        }
        this.comparisonListData = data;
        this.setTotalElements(data);
        this.error = null;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('Неизвестная ошибка');
        this.isLoading = false;
      });
    }
  };
  fetchComparisonListMini = async () => {
    try {
      const compareIds = localStorage.getItem('compare') || '';
      const response = await fetchProductsFromCompareMiniApi(
        this.rootStore.authStore.isAuth ? null : JSON.parse(compareIds)
      );

      runInAction(() => {
        const { data } = response.data;
        this.totalElements = data.amount;
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('Неизвестная ошибка');
      });
    }
  };

  addProductToCompare = async (productCard: ProductType): Promise<boolean> => {
    let isAddToCompare = false;
    if (this.rootStore.authStore.isAuth) {
      isAddToCompare = await this.addProductToCompareInAuth(productCard);
    } else {
      if (productCard.cost_params?.in_stock === 0) {
        toast.error('Товара нет в наличии');
        isAddToCompare = false;
      } else {
        const compareIds = localStorage.getItem('compare') || '';
        const parse = compareIds ? JSON.parse(compareIds) : [];
        this.toServerFormat([...parse, productCard.id]);
        runInAction(() => {
          this.fetchComparisonListMini();
        });

        isAddToCompare = true;
      }
    }
    return isAddToCompare;
  };

  addProductToCompareInAuth = async (productCard: ProductType): Promise<boolean> => {
    let isAddToCompare = false;
    this.isLoading = true;
    try {
      const response = await addProductToCompareApi(productCard.id);
      const data = response.data;
      if (data.error) {
        isAddToCompare = false;
        this.isLoading = false;
        toast.error(data.error);
        this.error = new Error(data.error);
        return false;
      }
      runInAction(() => {
        this.fetchComparisonListMini();
        isAddToCompare = true;
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
      this.error = error instanceof Error ? error : new Error('An unexpected error occurred');
      isAddToCompare = false;
    }

    return isAddToCompare;
  };

  // isFirstProduct = (id: number) => {
  //   return !this.compareIds.some((item) => item.id === id);
  // };

  deleteProductFromCompare = (productCard: ProductType) => {
    if (this.rootStore.authStore.isAuth) {
      this.deleteProductFromCompareInAuth(productCard);
    } else {
      const compareIds = localStorage.getItem('compare');
      if (compareIds) {
        const filterData = JSON.parse(compareIds).filter((item: number) => item !== productCard.id);
        runInAction(() => {
          this.toServerFormat(filterData);
          this.fetchComparisonList();
        });
      }
    }
  };

  deleteProductFromCompareInAuth = async (productCard: ProductType) => {
    try {
      this.isLoading = true;
      const response = await deleteProductFromCompareApi(productCard.id);
      const data = response.data;
      if (data.error) {
        this.isLoading = false;
        this.error = new Error(data.error);
        return;
      }
      runInAction(() => {
        this.isLoading = false;
        this.fetchComparisonList();
      });
    } catch (error) {
      this.isLoading = false;
      this.error = error instanceof Error ? error : new Error('An unexpected error occurred');
    }
  };

  toServerFormat = (productsData: ProductType[]) => {
    localStorage.setItem('compare', JSON.stringify(productsData));
  };

  setTotalElements = (data: CategoryData[]) => {
    const total = data.reduce((acc: number, value: CategoryData) => {
      return (acc += value.total_elements);
    }, 0);
    runInAction(() => {
      this.totalElements = total;
    });
  };

  get errorMessage(): string | null {
    return this.error ? this.error.message : null;
  }
}

export default CompareStore;
