import { action, makeObservable, observable, runInAction } from 'mobx';

import { IFiltersType, ProductType } from 'shared/types/ProductType';

import CheckboxStore from 'stores/common/CheckboxStore';
import { RootStore } from 'stores/RootStore';

export interface ProductTypeFromCartStore extends ProductType {
  quantity?: number;
  onChangeQuantity?: (quantity: number) => void;
}

const IFilterCheckboxStore: object = {
  selectedValues: observable.ref,
  reset: action,
  addToFilter: action,
  removeFromFilter: action,
};

class FilterCheckboxStore {
  rootStore: RootStore;
  isLoading = false;
  error: Error | null = null;
  code: string;
  display_expanded: string;
  display_type: string;
  filter_hint: string;
  id: string;
  name: string;
  type: string;
  values: CheckboxStore[];
  selectedValues: string[];

  constructor(item: IFiltersType, rootStore: RootStore) {
    const toServerFormat = rootStore.filterCatalogStore.toServerFormatState[item.code];
    this.rootStore = rootStore;
    this.code = item.code;
    this.display_expanded = item.display_expanded;
    this.display_type = item.display_type;
    this.filter_hint = item.filter_hint;
    this.id = item.id;
    this.name = item.name;
    this.type = item.type;
    this.values = item.values.map((item) => new CheckboxStore(item, toServerFormat));
    this.selectedValues = toServerFormat || [];
    makeObservable(this, IFilterCheckboxStore);
  }

  reset = () => {
    runInAction(() => {
      this.selectedValues = [];
      this.values.forEach((item) => item.setValue(false));
    });
  };

  addToFilter = (value: { label: string }) => {
    if (this.selectedValues && !this.selectedValues.includes(value.label)) {
      runInAction(() => {
        this.selectedValues = [...this.selectedValues, value.label];
      });
    }
  };

  removeFromFilter = (value: { label: string }) => {
    const index = this.selectedValues.indexOf(value.label);
    if (this.selectedValues && index !== -1) {
      runInAction(() => {
        this.selectedValues = this.selectedValues.filter((item: string) => item !== value.label);
      });
    }
  };
}

export default FilterCheckboxStore;
