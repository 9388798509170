import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { ButtonState } from 'shared/enum/enums';
import { calculateDiscountPercentage } from 'shared/helpers/calculateDiscountPercentage';
import { formatNumberWithSpaces } from 'shared/helpers/formatNumberWithSpaces';
import { pricingOrderState } from 'shared/helpers/pricingOrderState';

import rootStore from 'stores/RootStore';

import * as S from './styles';

interface QuickProductOrderProps {
  isFixed: boolean;
}

const QuickProductOrder: FC<QuickProductOrderProps> = observer(({ isFixed }) => {
  const { productDetailsStore, cartStore } = rootStore;
  const navigate = useNavigate();
  const productData = productDetailsStore.product;

  if (!productData) return null;

  const handleAddToCart = async () => {
    if (productDetailsStore.buttonState === ButtonState.IN_CART) {
      navigate('/cart');
    } else {
      productDetailsStore.setButtonState(ButtonState.LOADING);
      if (await cartStore.addProductToCart(productDetailsStore.product!)) {
        productDetailsStore.setButtonState(ButtonState.IN_CART);
      } else {
        productDetailsStore.setButtonState(ButtonState.IDLE);
      }
    }
  };

  const outOfStock = productDetailsStore.buttonState === ButtonState.OUT_OF_STOCK;
  const inCart = productDetailsStore.buttonState === ButtonState.IN_CART;

  const costParams = productData.cost_params;
  const cost = costParams.cost;
  const costOld = cost === costParams.old_cost ? false : costParams.old_cost;

  let buttonVariant: string;
  if (inCart) {
    buttonVariant = 'secondary';
  } else {
    buttonVariant = outOfStock ? 'disabled' : 'primary';
  }

  return (
    <S.Quick $isFixed={isFixed}>
      <S.QuickContainer>
        <S.QuickMedia>
          <S.QuickMediaImg src={productData?.detail_picture} />
        </S.QuickMedia>

        <S.QuickTitle>{productData?.name}</S.QuickTitle>

        <S.QuickPrices>
          <S.QuickPricesRow>
            <S.QuickPrice>{formatNumberWithSpaces(cost)} ₽</S.QuickPrice>
            {costOld ? (
              <S.QuickDiscount size="small" color="warn">
                -{calculateDiscountPercentage(costOld, cost)}%
              </S.QuickDiscount>
            ) : (
              ''
            )}
          </S.QuickPricesRow>

          <S.QuickPricesRow className="detail__quick-row">
            {costOld ? <S.QuickPriceOld>{formatNumberWithSpaces(costOld)} ₽</S.QuickPriceOld> : ''}

            {productData.cost_params.smarts ? (
              <S.QuickBonus>
                +{productData.cost_params.smarts}
                <S.QuickBonusIcon icon="s" />
              </S.QuickBonus>
            ) : (
              ''
            )}
          </S.QuickPricesRow>
        </S.QuickPrices>

        <S.QuickBtn
          isLoading={productDetailsStore.buttonState === ButtonState.LOADING}
          onClick={handleAddToCart}
          variant={buttonVariant}
          isIcon={!outOfStock}
          className="add-to-cart-button"
        >
          {pricingOrderState(!productData.cost_params?.in_stock, inCart)}
        </S.QuickBtn>
      </S.QuickContainer>
    </S.Quick>
  );
});

export default QuickProductOrder;
