import styled from 'styled-components';
import Button from 'ui-kit/Button';

import { pxToVw } from 'shared/helpers/pxToVw';
import { ClassNameProps } from 'shared/types/ClassNameProps';

export const FastTagList = styled.ul<ClassNameProps>`
  margin-right: ${pxToVw(-16)};
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
`;

export const FastTagListItem = styled.li<ClassNameProps>`
  margin-right: ${pxToVw(16)};
  margin-bottom: ${pxToVw(16)};

  &.skeleton-item {
    width: 27%;

    &:nth-child(odd) {
      width: 34%;
    }
  }
`;

export const FastTagInstance = styled(Button)`
  height: ${pxToVw(62)};
  padding: ${pxToVw(12)} ${pxToVw(32)};
  letter-spacing: ${pxToVw(0.2)};
  font-size: ${pxToVw(18)};

  .icon {
    margin-left: ${pxToVw(8)};
    width: ${pxToVw(24)};
    height: ${pxToVw(24)};
  }
`;
