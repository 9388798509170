import { FC } from 'react';

import * as S from './styles';
import { Size } from './types';

type AvatarProps = {
  title?: string;
  size?: Size;
};

const Avatar: FC<AvatarProps> = ({ title, size = Size.ExtraLarge }) => {
  return <S.Avatar size={size}>{title?.slice(0, 2)}</S.Avatar>;
};

export default Avatar;
