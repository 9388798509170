import { Category } from 'modules/Comparison/types/types';

import { ProductType } from 'shared/types/ProductType';

export type CardItemProps = {
  id: number;
  name: string;
  description: string;
  author: string;
  date: string;
  rate: number;
  imageUrl: string;
};

export interface AccordionItemOptionProps {
  label: string;
  count: string;
  checked?: string;
  disabled?: string;
}

export interface AccordionItemProps {
  id: number;
  name: string;
  options?: AccordionItemOptionProps[];
}

export const productsData = [
  {
    id: '0',
    link: '#',
    numStar: 5,
    numComment: 2,
    cost: 1235999,
    costOld: 999999,
    bonuses: 1890,
    isHit: true,
    isNew: true,
    isSales: false,
    textDelivery: 'завтра',
    imageUrl: '260_260_1/planshet-fplus-pro-lifetab-plus-10-1-4-64gb-graphite-3.png.webp',
    title: 'Умная колонка Яндекс Станция Макс с Алисой, с Zigbee, YNDX-00053Z, зелёный',
  },
  {
    id: '1',
    link: '#link',
    numStar: 1,
    numComment: 0,
    cost: 15290,
    costOld: 0,
    bonuses: 950,
    isHit: true,
    isNew: true,
    isSales: true,
    textDelivery: 'завтра',
    imageUrl: 'era-kupolnaya-dahua-dh-ipc-hfw3241ep-s-0280b-s2-1.png.webp?169953921511938',
    title: 'IP-камера уличная Dahua DH-IPC-HFW3241EP-S-0280B-S2',
  },
  {
    id: '2',
    link: '#',
    numStar: 5,
    numComment: 2,
    cost: 5490,
    costOld: 6490,
    bonuses: 55,
    isHit: false,
    isNew: false,
    isSales: true,
    textDelivery: 'завтра',
    imageUrl: '/pylesos-s-konteynerom-dlya-pyli-deerma-dem-tj300w-violet-1.png.webp?16794718174368',
    title: 'Пылесос с контейнером для пыли Deerma DEM-TJ300W Violet',
  },
  {
    id: '3',
    link: '#Xiaomi',
    numStar: 5,
    numComment: 99,
    cost: 167990,
    costOld: 179990,
    bonuses: 15000,
    isHit: true,
    isNew: true,
    isSales: true,
    textDelivery: 'послезавтра',
    imageUrl: '/smartfon-xiaomi-mi-mix-fold-2-12-512-gb-gold-1.png.webp?16910549529656',
    title: 'Смартфон Xiaomi Mi MIX FOLD 2 12/512 GB Gold',
  },
  {
    id: '4',
    link: '#Dyson',
    numStar: 0,
    numComment: 0,
    cost: 63990,
    costOld: 0,
    bonuses: 0,
    isHit: false,
    isNew: false,
    isSales: false,
    textDelivery: 'сегодня',
    imageUrl: '/stayler-dyson-hs05-airwrap-complete-long-1.png.webp?16993463864682',
    title: 'Стайлер Dyson HS05 Airwrap Complete Long',
  },
  {
    id: '5',
    link: '#',
    numStar: 0,
    numComment: 2,
    cost: 12990,
    costOld: 13990,
    bonuses: 1890,
    isHit: true,
    isNew: false,
    isSales: false,
    textDelivery: 'через неделю',
    imageUrl: '/fed/twuqwrczm72z217yksrnyxiq0kksqg3d/260_260_1/fen-laifen-swift-red-1.png.webp?16775759412986',
    title: 'Фен Laifen Swift Red',
  },
];

export const promotionsData = [
  {
    id: '0',
    link: '#0',
    title: 'Безопасно. Умно. Выгодно.',
    color: '#244DDF',
    text: 'любой гаджет со скидкой 3000₽ и программой защиты экрана',
    imageUrl: 'https://vsesmart.ru/local/templates/vsesmart-2.0/assets/img/example/promotions-1.png',
  },
  {
    id: '1',
    link: '#1',
    title: 'Комплекты для новичка',
    color: '#D2AA8D',
    text: 'любой гаджет со скидкой 3000₽ и программой защиты экрана',
    imageUrl: 'https://vsesmart.ru/local/templates/vsesmart-2.0/assets/img/example/promotions-2.png',
  },
  {
    id: '2',
    link: '#2',
    title: 'Чистый воздух',
    color: '#59BBED',
    text: 'любой гаджет со скидкой 3000₽ и программой защиты экрана',
    imageUrl: 'https://vsesmart.ru/local/templates/vsesmart-2.0/assets/img/example/promotions-3.png',
  },
  {
    id: '3',
    link: '#3',
    title: 'Скидка 25%',
    color: '#F86968',
    text: 'любой гаджет со скидкой 3000₽ и программой защиты экрана',
    imageUrl: 'https://vsesmart.ru/local/templates/vsesmart-2.0/assets/img/example/promotions-4.png',
  },
  {
    id: '4',
    link: '#4',
    title: 'Back to school',
    color: '#FFA43E',
    text: 'любой гаджет со скидкой 3000₽ и программой защиты экрана',
    imageUrl: 'https://vsesmart.ru/local/templates/vsesmart-2.0/assets/img/example/promotions-5.png',
  },
  {
    id: '5',
    link: '#5',
    title: 'Для двоих',
    color: '#C13368',
    text: 'любой гаджет со скидкой 3000₽ и программой защиты экрана',
    imageUrl: 'https://vsesmart.ru/local/templates/vsesmart-2.0/assets/img/example/promotions-5.png',
  },
];

export const ReviewsData: CardItemProps[] = [
  {
    id: 1,
    name: 'Робот-пылесос Ecovacs DEEBOT X1 OMNI Black (EU версия) с док-станцией',
    description:
      // eslint-disable-next-line max-len
      'Классный, бесшумный маленький помощник. Я давно хотела такой пылесос и вот наконец-то решилась. Однако, внезапно сломался.',
    author: 'Константция Константиновская',
    date: '24 сентября',
    rate: 5,
    imageUrl: 'https://front.vsesmart.ru/kiosk/assets/img/example/card__review/review-1.jpg',
  },
  {
    id: 2,
    name: 'Робот-пылесос Dreame Bot D10 Plus',
    description:
      // eslint-disable-next-line max-len
      'Отличный аппарат, работает, не шумит, убирает хорошо, радует влажная уборка, хорошая штука, управляется через телефон или яндекс Алиса, рекомендую',
    author: 'Павел Ненашев',
    date: '12 мая',
    rate: 2,
    imageUrl: 'https://front.vsesmart.ru/kiosk/assets/img/example/card__review/review-2.jpg',
  },
  {
    id: 3,
    name: 'Робот-пылесос Dreame Bot D10 Plus',
    description:
      // eslint-disable-next-line max-len
      'Отличный аппарат, работает, не шумит, убирает хорошо, радует влажная уборка, хорошая штука, управляется через телефон или яндекс Алиса, рекомендую',
    author: 'Павел Ненашев',
    date: '12 мая',
    rate: 4,
    imageUrl: 'https://front.vsesmart.ru/kiosk/assets/img/example/card__review/review-2.jpg',
  },
  {
    id: 4,
    name: 'Робот-пылесос Dreame Bot D10 Plus',
    description:
      // eslint-disable-next-line max-len
      'Отличный аппарат, работает, не шумит, убирает хорошо, радует влажная уборка, хорошая штука, управляется через телефон или яндекс Алиса, рекомендую',
    author: 'Павел Ненашев',
    date: '12 мая',
    rate: 1,
    imageUrl: 'https://front.vsesmart.ru/kiosk/assets/img/example/card__review/review-1.jpg',
  },
];

export const accordion: AccordionItemProps[] = [
  {
    id: 1,
    name: 'Категория без поиска',
    options: [
      { label: 'Категория 1', count: '100', checked: ' checked' },
      { label: 'Категория 2', count: '23', checked: '' },
      { label: 'Умные колонки', count: '23', checked: '' },
      { label: 'Категория 3', count: '100', checked: '' },
      { label: 'Категория 4', count: '23', checked: '' },
      { label: 'Категория 5', count: '0', checked: '', disabled: ' disabled' },
      { label: 'Категория 6', count: '5', checked: '' },
      { label: 'Категория 1', count: '100', checked: ' checked' },
      { label: 'Категория 2', count: '23', checked: '' },
      { label: 'Умные колонки', count: '23', checked: '' },
      { label: 'Категория 3', count: '100', checked: '' },
      { label: 'Категория 4', count: '23', checked: '' },
      { label: 'Категория 5', count: '0', checked: '', disabled: ' disabled' },
      { label: 'Категория 6', count: '5', checked: '' },
    ],
  },
  {
    id: 2,
    name: 'Категория c поиском',
    options: [
      { label: 'дисковый/E-ABS', count: '23', checked: '' },
      { label: 'дисковый/электрический', count: '23', checked: '' },
      { label: 'механический дисковый', count: '23', checked: '' },
      { label: 'механический дисковый тормоз, дисковый тормоз заднего колеса', count: '23', checked: '' },
      { label: 'ножной', count: '23', checked: '' },
      { label: 'передний дисковый тормоз и задний тормоз E-ABS', count: '23', checked: '' },
      { label: 'передний электрический/задний дисковый', count: '23', checked: '' },
      { label: 'ручной', count: '23', checked: '' },
      { label: 'ручной, ножной', count: '23', checked: '' },
    ],
  },
  {
    id: 3,
    name: 'Категория радиокнопок',
    options: [
      { label: 'Категория 2', count: '23', checked: '' },
      { label: 'Умные колонки', count: '23', checked: '' },
    ],
  },
  {
    id: 4,
    name: 'Теглайны',
  },
];

export const dropdownOptions = [
  { label: 'Сначала популярные', value: 'popular' },
  { label: 'Новинки', value: 'new' },
  { label: 'Дешевле', value: 'cost' },
  { label: 'Дороже', value: 'cost' },
  { label: 'Хиты', value: 'hit' },
  { label: 'Акции', value: 'sell' },
];

export const products: ProductType[] = [
  {
    id: 1,
    name: 'Весы электронные Xiaomi Mi Smart Scale Mi Smart Scale 2',
    preview_picture:
      'https://vsesmart.ru/upload/resize_cache/iblock/71f/40091ux3kzvp7qdyp0vmk8vyg1nsz1nh/260_260_1/umnaya-kolonka' +
      '-s-golosovym-pomoshchnikom-yandeks-stantsiya-layt-fioletovaya-3.png',
    url: '#url',
    cost_params: {
      old_cost: 49990,
      smarts: 0,
      cost: 32990,
      discount: 20,
      in_stock: 2,
      in_basket: true,
    },
  },
  {
    id: 2,
    name: 'Умный напольный светильник (торшер) Philips Hue Gradient Signe',
    preview_picture:
      'https://vsesmart.ru/upload/resize_cache/iblock/71f/40091ux3kzvp7qdyp0vmk8vyg1nsz1nh/260_260_1/umnaya-kolonka' +
      '-s-golosovym-pomoshchnikom-yandeks-stantsiya-layt-fioletovaya-3.png',
    url: '#url',
    cost_params: {
      old_cost: 49990,
      smarts: 0,
      cost: 32990,
      discount: 25,
      in_stock: 2,
      in_basket: true,
    },
  },
  {
    id: 3,
    name: 'Умный напольный светильник (торшер) Philips Hue Gradient Signe',
    preview_picture:
      'https://vsesmart.ru/upload/resize_cache/iblock/71f/40091ux3kzvp7qdyp0vmk8vyg1nsz1nh/260_260_1/umnaya-kolonka' +
      '-s-golosovym-pomoshchnikom-yandeks-stantsiya-layt-fioletovaya-3.png',
    url: '#url',
    cost_params: {
      old_cost: 49990,
      smarts: 0,
      cost: 32990,
      discount: 25,
      in_stock: 2,
      in_basket: true,
    },
  },
  {
    id: 4,
    name: 'Умный напольный светильник (торшер) Philips Hue Gradient Signe',
    preview_picture:
      'https://vsesmart.ru/upload/resize_cache/iblock/71f/40091ux3kzvp7qdyp0vmk8vyg1nsz1nh/260_260_1/umnaya-kolonka' +
      '-s-golosovym-pomoshchnikom-yandeks-stantsiya-layt-fioletovaya-3.png',
    url: '#url',
    cost_params: {
      old_cost: 49990,
      smarts: 0,
      cost: 32990,
      discount: 25,
      in_stock: 2,
      in_basket: true,
    },
  },
  {
    id: 5,
    name: 'Умный напольный светильник (торшер) Philips Hue Gradient Signe',
    preview_picture:
      'https://vsesmart.ru/upload/resize_cache/iblock/71f/40091ux3kzvp7qdyp0vmk8vyg1nsz1nh/260_260_1/umnaya-kolonka' +
      '-s-golosovym-pomoshchnikom-yandeks-stantsiya-layt-fioletovaya-3.png',
    url: '#url',
    cost_params: {
      old_cost: 49990,
      smarts: 0,
      cost: 32990,
      discount: 25,
      in_stock: 2,
      in_basket: true,
    },
  },
];

export const categories: Category[] = [
  { name: 'Роботы пылесосы', count: 10 },
  { name: 'Колонки', count: 15 },
  { name: 'Наушники', count: 8 },
  { name: 'Телевизоры', count: 20 },
  { name: 'Смартфоны', count: 30 },
  { name: 'Ноутбуки', count: 25 },
  { name: 'Планшеты', count: 12 },
  { name: 'Камеры', count: 18 },
];
