import shortLogo from 'assets/img/logo/logo-prime.svg';
import { observer } from 'mobx-react-lite';
import { RoutesLinks } from 'RoutesLinks';
import Badge from 'ui-kit/Badge';
import Sprite from 'ui-kit/Sprite';

import rootStore from 'stores/RootStore';

import HeaderAccount from './components/HeaderAccount';
import HeaderBasket from './components/HeaderBasket';
import SearchHeader from './components/SearchHeader';
import * as S from './styles';

const Header = observer(() => {
  const { modalStore, favoritesStore, compareStore } = rootStore;

  const toggleMenu = () => {
    if (modalStore.isCategoryMenuOpen) {
      modalStore.closeCategoryMenu();
    } else {
      modalStore.openCategoryMenu();
    }
  };

  return (
    <S.Header>
      <S.HeaderInner>
        <S.HeaderLogo to={RoutesLinks.HOME}>
          <S.HeaderLogoImg src={shortLogo} alt="" />
        </S.HeaderLogo>

        <S.ToggleCategory variant="primary" type="button" onClick={toggleMenu}>
          <S.ToggleCategoryDecor className={modalStore.isCategoryMenuOpen ? 'active' : ''} />
        </S.ToggleCategory>

        <SearchHeader />

        <HeaderAccount />

        <S.HeaderState to={RoutesLinks.COMPARE} className="active">
          <Sprite icon="compare" className="icon" />
          <Badge color="warn">{compareStore.totalElements}</Badge>
        </S.HeaderState>

        <S.HeaderState to={RoutesLinks.FAVORITE} className="active">
          <Sprite icon="favorites" className="icon" />
          <Badge color="warn">{favoritesStore.favoritesData.length}</Badge>
        </S.HeaderState>
        <HeaderBasket />
      </S.HeaderInner>
    </S.Header>
  );
});

export default Header;
