import styled from 'styled-components';
import Sprite from 'ui-kit/Sprite';

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 55;
  display: flex;
`;

export const ModalBackdrop = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalContent = styled.div<{ size: string }>`
  width: ${({ size }: { size: string }) => {
    const sizeMap: { [key: string]: string } = {
      small: '300px',
      medium: '480px',
      large: '600px',
    };
    return sizeMap[size] || '480px';
  }};
  padding: 40px;
  margin: auto;
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: 0 0 20px rgba(132, 132, 132, 0.2);
  position: relative;
  z-index: 1;
`;

export const ModalClose = styled.button`
  margin-top: -8px;
  margin-right: -8px;
  padding: 8px;
  border: 0;
  background-color: transparent;
`;

export const ModalCloseIcon = styled(Sprite)`
  width: 24px;
  height: 24px;
  transition: fill 0.2s ease-in-out;
  pointer-events: none;
  fill: var(--text-tertiary);
`;

export const ModalTitle = styled.h2``;

export const Block = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
