import { action, makeObservable, observable, runInAction } from 'mobx';

import { IFiltersTypeWithRange, ProductType } from 'shared/types/ProductType';

import { RootStore } from 'stores/RootStore';

export interface ProductTypeFromCartStore extends ProductType {
  quantity?: number;
  onChangeQuantity?: (quantity: number) => void;
}

const IFilterRangeStore: object = {
  values: observable,
  selectedValues: observable,
  changeToRange: action.bound,
};

class FilterRangeStore {
  rootStore: RootStore;
  isLoading = false;
  error: Error | null = null;
  code: string;
  display_expanded: string;
  display_type: string;
  filter_hint: string;
  id: string;
  name: string;
  type: string;
  selectedValues: string[];
  values: string[];

  constructor(item: IFiltersTypeWithRange, rootStore: RootStore) {
    if (!item.values.selected_range) {
      this.selectedValues = [];
    } else if (item.values.selected_range.every((val) => Number(val) === 0)) {
      this.selectedValues = [];
    } else {
      this.selectedValues = item.values.selected_range;
    }
    this.rootStore = rootStore;
    this.values = item.values.default_range;
    this.code = item.code;
    this.display_expanded = item.display_expanded;
    this.display_type = item.display_type;
    this.filter_hint = item.filter_hint;
    this.id = item.id;
    this.name = item.name;
    this.type = item.type;

    makeObservable(this, IFilterRangeStore);
  }

  reset = () => {
    runInAction(() => {
      this.selectedValues = [];
    });
  };

  changeToRange = (values: string[]) => {
    runInAction(() => {
      this.selectedValues = [...values];
      this.rootStore.filterCatalogStore.currentPage = 1;
    });
  };
}

export default FilterRangeStore;
