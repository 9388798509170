import { Params } from 'react-router-dom';

import rootStore from 'stores/RootStore';

export const saleLoader = async ({ params }: { params: Params<string> }) => {
  const saleUrl = params.saleUrl;

  if (!saleUrl) return null;

  const { saleStore } = rootStore;
  await saleStore.loadSale(saleUrl);

  return saleUrl;
};
