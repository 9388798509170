import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

export const Container = styled.div`
  padding-left: ${pxToVw(60)};
  padding-right: ${pxToVw(60)};
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;
