import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { observer } from 'mobx-react-lite';

import { propertyCodes } from 'shared/helpers/propertyCodes';

import rootStore from 'stores/RootStore';

import * as S from './styles';

const Heading: FC = observer(() => {
  const { productDetailsStore, baseProductPropertiesStore } = rootStore;
  const productData = productDetailsStore.product;
  const brandData = baseProductPropertiesStore.getPropertyData(propertyCodes.brand);

  return (
    <S.ProductHeading>
      <Helmet>
        <title>{productData?.name}</title>
      </Helmet>
      <S.Title>{productData?.name}</S.Title>
      {brandData?.brand?.file && (
        <S.Brand>
          <S.BrandBox to={brandData.url || ''}>
            <S.BrandImg src={brandData.brand?.file} alt="" />
          </S.BrandBox>
        </S.Brand>
      )}
    </S.ProductHeading>
  );
});

export default Heading;
