import { Helmet } from 'react-helmet-async';
import { observer } from 'mobx-react-lite';
import Loader from 'ui-kit/Loader';

import Breadcrumbs from 'components/Breadcrumb';
import CatalogFiltersSection from 'components/CatalogFiltersSection';
import Container from 'components/Container';

import rootStore from 'stores/RootStore';

import * as S from './styles';

const SingleBrand = observer(() => {
  const { brandStore, filterCatalogStore } = rootStore;

  return (
    <Container>
      <Helmet>
        <title>Бренд {brandStore.dataBrand?.brand.name}</title>
      </Helmet>

      <Breadcrumbs />

      <S.Title>Бренд {brandStore.dataBrand?.brand.name}</S.Title>

      <CatalogFiltersSection />

      <S.Box>
        <S.Media src={brandStore.dataBrand?.brand.image} alt="" />
      </S.Box>

      <h3 className="mb-4">Главные бренды</h3>
      {filterCatalogStore.isLoading && <Loader />}
    </Container>
  );
});

export default SingleBrand;
