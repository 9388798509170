import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import MiniCartBlock from 'modules/Header/components/MiniCartBlock';
import { RoutesLinks } from 'RoutesLinks';
import Badge from 'ui-kit/Badge';
import Sprite from 'ui-kit/Sprite';

import rootStore from 'stores/RootStore';

import * as S from './styles';

const HeaderBasket = observer(() => {
  const { cartStore } = rootStore;
  const navigate = useNavigate();

  const handleNavigateToCart = () => {
    navigate(RoutesLinks.CART);
  };

  return (
    <S.MainBlock>
      <S.HeaderBasket onClick={handleNavigateToCart} variant="primary">
        <Sprite icon="basket" className="icon" />
        <Badge color="warn">{cartStore.productsData.length}</Badge>
      </S.HeaderBasket>
      {cartStore.isOpenMiniCart && <MiniCartBlock />}
    </S.MainBlock>
  );
});

export default HeaderBasket;
