import { fetchPopularProductApi } from 'entities/Product/services/productApi';
import { makeAutoObservable, runInAction } from 'mobx';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';

import { ProductType } from 'shared/types/ProductType';

import { RootStore } from 'stores/RootStore';

class PopularProductStore {
  kiosk = localStorage.getItem('kiosk') || 'default';
  data: ProductType[] | null = null;
  dataState: IPromiseBasedObservable<ProductType[]> | null = null;
  error: Error | null = null;

  constructor(private rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  async loadData() {
    try {
      const response = await fetchPopularProductApi(this.kiosk);
      runInAction(() => {
        this.data = response.data.data.map((product) => {
          return {
            ...product,
            preview_properties: product.preview_properties,
          };
        });
        this.dataState = fromPromise(Promise.resolve(this.data));
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('Неизвестная ошибка');
        throw error;
      });
    }
  }

  get errorMessage() {
    return this.error ? this.error.message : null;
  }

  get isLoading() {
    return this.dataState?.state === 'pending';
  }

  get isError() {
    return this.dataState?.state === 'rejected';
  }
}

export default PopularProductStore;
