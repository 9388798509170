import { FC } from 'react';
import Sprite from 'ui-kit/Sprite';

import * as S from './styles';

interface SliderArrowsProps {
  goPrev: () => void;
  goNext: () => void;
  prevClass: string;
  nextClass: string;
}

const SliderArrows: FC<SliderArrowsProps> = ({ goPrev, goNext, prevClass, nextClass }) => (
  <>
    <S.Control onClick={goPrev} className={`prev ${prevClass}`} variant="transparent">
      <Sprite icon="arrow-circle" className="carousel__arrow-icon" />
    </S.Control>
    <S.Control onClick={goNext} className={`next ${nextClass}`} variant="transparent">
      <Sprite icon="arrow-circle" className="carousel__arrow-icon" />
    </S.Control>
  </>
);

export default SliderArrows;
