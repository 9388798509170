import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import noPhoto from 'assets/img/no-photos.png';
import Badge from 'ui-kit/Badge';

import CardStickerItem from 'components/CardStickerItem';

import { ButtonState } from 'shared/enum/enums';
import { formatNumberWithSpaces } from 'shared/helpers/formatNumberWithSpaces';
import { extractPreviewProperties } from 'shared/helpers/productHelpers';
import { ProductType } from 'shared/types/ProductType';

import rootStore from 'stores/RootStore';

import * as S from './styles';

type CompareProductCardProps = {
  card: ProductType;
};

const CompareProductCard: FC<CompareProductCardProps> = ({ card }) => {
  const [buttonState, setButtonState] = useState(ButtonState.IDLE);
  const [favoritesState, setFavoritesState] = useState(false);

  const extractedProperties = extractPreviewProperties(card);
  const { cartStore, favoritesStore } = rootStore;
  const navigate = useNavigate();
  const { name: title, preview_picture: imageUrl, url, cost_params, id } = card;

  const costOld = cost_params?.cost === cost_params?.old_cost ? false : cost_params?.old_cost;
  const urlProduct = `${url}`;
  const outOfStock = buttonState === ButtonState.OUT_OF_STOCK;
  const inCart = buttonState === ButtonState.IN_CART;

  useEffect(() => {
    if (!favoritesStore.isFirstProduct(id)) {
      setFavoritesState(true);
    }
    if (!cost_params?.in_stock) {
      setButtonState(ButtonState.OUT_OF_STOCK);
    } else if (!cartStore.isFirstProduct(id)) {
      setButtonState(ButtonState.IN_CART);
    }
  }, [cartStore, id, cost_params?.in_stock, favoritesStore]);

  const handleAddToCart = async () => {
    if (inCart) {
      navigate('/cart');
    } else if (outOfStock) {
      navigate(urlProduct);
    } else {
      setButtonState(ButtonState.LOADING);
      if (await cartStore.addProductToCart(card)) {
        setButtonState(ButtonState.IN_CART);
      } else {
        setButtonState(ButtonState.IDLE);
      }
    }
  };

  const handleAddToFavorites = () => {
    if (favoritesState) {
      favoritesStore.deleteProductFromFavorite(card);
      setFavoritesState(false);
    } else if (!favoritesState) {
      favoritesStore.addProductToFavorite(card);
      setFavoritesState(true);
    }
  };

  const buttonVariant = outOfStock || inCart ? 'secondary' : 'primary';

  return (
    <S.Card>
      <S.CardMedia to={`${urlProduct}`}>
        <S.CardImg loading="lazy" src={imageUrl ? imageUrl : noPhoto} alt={title} />
      </S.CardMedia>

      <S.CardMeta className="list-unstyled">
        <S.CardMetaItem onClick={handleAddToFavorites} className="toggle">
          <S.CardMetaIcon icon={favoritesState ? 'favorites' : 'favorite-stroke'} />
        </S.CardMetaItem>
      </S.CardMeta>

      <S.CardContent>
        <div>
          <S.CardTitle to={urlProduct}>{title}</S.CardTitle>
        </div>

        <S.CardBottom>
          <S.CardPrice>
            {cost_params?.cost && <S.CardCost>{formatNumberWithSpaces(cost_params?.cost)} ₽</S.CardCost>}

            {costOld ? <S.CardCostOLd>{formatNumberWithSpaces(costOld)} ₽</S.CardCostOLd> : ''}
          </S.CardPrice>
          <S.CardBasket
            isLoading={buttonState === ButtonState.LOADING}
            className="add-to-cart-button"
            onClick={handleAddToCart}
            variant={buttonVariant}
            isIcon={true}
          >
            {inCart && ' В корзине'}
            {outOfStock && 'Подробнее'}
          </S.CardBasket>
        </S.CardBottom>
      </S.CardContent>

      <S.CardSticker>
        {cost_params?.discount
          ? cost_params?.discount > 5 && (
              <CardStickerItem>
                <Badge color="warn">Распродажа</Badge>
              </CardStickerItem>
            )
          : ''}

        {extractedProperties.isNew && (
          <CardStickerItem>
            <Badge color="new">Новинка</Badge>
          </CardStickerItem>
        )}
      </S.CardSticker>
    </S.Card>
  );
};

export default CompareProductCard;
