import { FC } from 'react';

import { SectionHeaderProps } from 'shared/types/SectionHeaderProps';

import * as S from './styles';

const SectionHeader: FC<SectionHeaderProps> = ({
  pageHeadingTitle,
  showAll = false,
  secondaryBtn,
  badgeCount,
  link,
  pageHeadingAllText = 'Посмотреть всё',
  className,
}) => (
  <S.Heading className={className}>
    <S.Title>{pageHeadingTitle}</S.Title>
    {showAll && (
      <S.BtnLink to={link} variant="light">
        {pageHeadingAllText}
      </S.BtnLink>
    )}
    {secondaryBtn && (
      <S.BtnLink to={link} variant="secondary">
        {pageHeadingAllText}
      </S.BtnLink>
    )}
    {badgeCount !== undefined && badgeCount > 0 && (
      <S.HeadingBadge size="large" color="warn">
        {badgeCount}
      </S.HeadingBadge>
    )}
  </S.Heading>
);

export default SectionHeader;
