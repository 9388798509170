import { lazy } from 'react';
import { Helmet } from 'react-helmet-async';
import decor from 'assets/img/media/not-fount-robot.png';
import { RoutesLinks } from 'RoutesLinks';

import Container from 'components/Container';

import * as S from './styles';

const LazyShowcaseCarouselDefault = lazy(() => import('components/ShowcaseCarouselDefault'));
const LazySearchBar = lazy(() => import('components/SearchBar'));
const LazyRecommendedProducts = lazy(() => import('components/RecommendedProducts'));

const NotFound = () => {
  return (
    <S.NotFound>
      <Helmet>
        <title>Страница не найдена - 404 ошибка</title>
      </Helmet>

      <S.NotFoundContainer>
        <S.Block>
          <S.Title>Похоже что-то пошло не&nbsp;так</S.Title>

          <S.Text>
            К&nbsp;сожалению, страница, не&nbsp;найдена (ошибка 404).
            <br />
            Вы&nbsp;можете перейти на&nbsp;<S.Links to={RoutesLinks.HOME}>главную страницу</S.Links>
            <br />
            или&nbsp; в&nbsp;<S.Links to={RoutesLinks.CATALOG}>каталог товаров</S.Links>.
          </S.Text>
        </S.Block>

        <S.Decor src={decor} alt="404 ошибка сайта" />
      </S.NotFoundContainer>

      <Container className="pb-5">
        <LazySearchBar />
        <LazyRecommendedProducts />
        <LazyShowcaseCarouselDefault />
      </Container>
    </S.NotFound>
  );
};

export default NotFound;
