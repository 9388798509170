import { fetchBrandApi } from 'entities/Brands/services/brandsApi';
import { IBrandDetail } from 'entities/Brands/types';
import { makeAutoObservable, runInAction } from 'mobx';

import { RootStore } from 'stores/RootStore';

class BrandStore {
  dataBrand: IBrandDetail | null = null;
  brandState: 'pending' | 'fulfilled' | 'rejected' | null = null;
  error: Error | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  async fetchBrandPage(brandUrl: string) {
    this.brandState = 'pending';
    try {
      const response = await fetchBrandApi(brandUrl);
      runInAction(() => {
        this.dataBrand = response.data.data;
        this.brandState = 'fulfilled';
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('Неизвестная ошибка');
        this.brandState = 'rejected';
      });
    }
  }

  get isLoading() {
    return this.brandState === 'pending';
  }
}

export default BrandStore;
