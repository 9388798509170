import { makeAutoObservable, runInAction } from 'mobx';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';

import api from 'stores/Api';

import { RootStore } from './RootStore';

interface BaseCategory {
  id: number;
  name: string;
  code: string;
  image?: string;
  url: string;
  user_fields?: UserFields;
}

interface UserFields {
  icon: string;
  icon2: string;
}

export interface RecursiveCategory extends BaseCategory {
  children_recursive: RecursiveCategory[]; // Поддержка многоуровневой рекурсии
}

export interface MainCategory extends BaseCategory {
  child_categories: RecursiveCategory[];
}

interface ApiResponse {
  data: MainCategory[];
}

class MenuStore {
  menuData: MainCategory[] = [];
  menuDataState: IPromiseBasedObservable<MainCategory[]> | null = null;
  error: Error | null = null;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  async loadData() {
    try {
      const response = await api.get<ApiResponse>('menu');
      runInAction(() => {
        this.menuData = response.data.data;
        this.menuDataState = fromPromise(Promise.resolve(this.menuData));
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('Неизвестная ошибка');
        throw error;
      });
    }
  }

  get errorMessage() {
    return this.error ? this.error.message : null;
  }

  get isLoading() {
    return this.menuDataState?.state === 'pending';
  }

  get isError() {
    return this.menuDataState?.state === 'rejected';
  }
}

export default MenuStore;
