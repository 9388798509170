import { Helmet } from 'react-helmet-async';
import { observer } from 'mobx-react-lite';
import Loader from 'ui-kit/Loader';

import CatalogFiltersSection from 'components/CatalogFiltersSection';
import Container from 'components/Container';

import rootStore from 'stores/RootStore';

import * as S from './styles';

const SearchPage = observer(() => {
  const { filterCatalogStore } = rootStore;

  return (
    <Container>
      <Helmet>
        <title>Поиск</title>
      </Helmet>

      {filterCatalogStore.catalogData.length >= 1 ? (
        <S.Title>
          По запросу <S.bold>{filterCatalogStore.query}</S.bold> найдено:
        </S.Title>
      ) : (
        <S.Title>
          По запросу <S.bold>{filterCatalogStore.query}</S.bold> ничего не найдено
        </S.Title>
      )}
      <CatalogFiltersSection />

      <h3 className="mb-4">Поиск</h3>
      {filterCatalogStore.isLoading && <Loader />}
    </Container>
  );
});
export default SearchPage;
