/* eslint-disable unused-imports/no-unused-vars */
import { FC, useState } from 'react';
import noPhoto from 'assets/img/no-photos.png';
import { CartProductItemProps } from 'entities/Cart/types';
import { observer } from 'mobx-react-lite';
import Badge from 'ui-kit/Badge';
import InputNumber from 'ui-kit/InputNumber';
import Modal from 'ui-kit/Modal';

import CardStickerItem from 'components/CardStickerItem';

import { formatNumberWithSpaces } from 'shared/helpers/formatNumberWithSpaces';
import { extractPreviewProperties } from 'shared/helpers/productHelpers';

import rootStore from 'stores/RootStore';

import ModalDeleteProduct from '../ModalDeleteProduct';
import * as S from './styles';

const CartProductItem: FC<CartProductItemProps> = observer(({ data }) => {
  const {
    id,
    name: title,
    preview_picture: imageUrl,
    url,
    cost_params,

    quantity,
    favoritesState,
    onIncreaseQuantity,
    onReduceQuantity,
    onChangeFavoritesState,
    isLoading,
  } = data;
  const { cartStore, favoritesStore } = rootStore;
  const extractedProperties = extractPreviewProperties(data);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const urlProduct = `${url}`;
  const costOld = cost_params?.cost === cost_params?.old_cost ? false : cost_params?.old_cost;

  const handleIncrease = (value: number) => {
    if (value <= 0) {
      setIsOpenModal(true);
    } else {
      onIncreaseQuantity(value);
    }
  };

  const handleReduce = (value: number) => {
    if (value <= 0) {
      setIsOpenModal(true);
    } else {
      onReduceQuantity(value);
    }
  };

  const handleAddToFavorites = () => {
    if (favoritesState) {
      favoritesStore.deleteProductFromFavorite(data);
      onChangeFavoritesState(false);
    } else {
      favoritesStore.addProductToFavorite(data);
      onChangeFavoritesState(true);
    }
  };

  return (
    <S.Card>
      <S.CardMedia to={`${urlProduct}`}>
        <S.CardImg loading="lazy" src={imageUrl ? imageUrl : noPhoto} alt="" />
      </S.CardMedia>

      <S.CardMeta>
        <S.CardTitle to={urlProduct}>{title}</S.CardTitle>
        <S.InputNumberBlock>
          <InputNumber
            maxValue={cost_params?.in_stock || 0}
            value={quantity}
            onIncrease={handleIncrease}
            onReduce={handleReduce}
            isLoading={isLoading}
          />
          <S.RemainingItemsCounter>Осталось: {cost_params?.in_stock}</S.RemainingItemsCounter>
        </S.InputNumberBlock>
      </S.CardMeta>

      <S.CardPrice>
        <S.PriceBlock>
          <S.Price>
            {cost_params?.cost ? formatNumberWithSpaces(cost_params?.cost) : ''} ₽
            {costOld ? <Badge color="warn">{cost_params?.discount}%</Badge> : ''}
          </S.Price>
          <S.PriceOld>{costOld ? `${formatNumberWithSpaces(costOld)} ₽` : ''}</S.PriceOld>
        </S.PriceBlock>

        <S.IconBlock>
          <S.CardMetaItem onClick={handleAddToFavorites} className="toggle">
            <S.CardMetaIcon icon={favoritesState ? 'favorites' : 'favorite-stroke'} className="icon" />
          </S.CardMetaItem>
          <S.CardMetaItem onClick={() => setIsOpenModal(true)} className="toggle">
            <S.CardMetaIcon icon="trash" className="icon" />
          </S.CardMetaItem>
        </S.IconBlock>
      </S.CardPrice>

      <S.CardSticker>
        {cost_params?.discount
          ? cost_params?.discount > 5 && (
              <CardStickerItem>
                <Badge color="warn">Распродажа</Badge>
              </CardStickerItem>
            )
          : ''}

        {extractedProperties.isNew && (
          <CardStickerItem>
            <Badge color="new">Новинка</Badge>
          </CardStickerItem>
        )}
        <Modal size="large" title="Удалить товар" isOpen={isOpenModal} onClose={() => setIsOpenModal(!isOpenModal)}>
          <ModalDeleteProduct
            onClose={() => setIsOpenModal(!isOpenModal)}
            onClick={() => cartStore.deleteProductFromCart(data)}
          />
        </Modal>
      </S.CardSticker>
    </S.Card>
  );
});

export default CartProductItem;
