import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';
import { ClassNameProps } from 'shared/types/ClassNameProps';

export const Section = styled.section<ClassNameProps>`
  margin-bottom: ${pxToVw(32)};
  position: relative;

  .swiper-slide {
    height: initial;
  }
`;

export const Carousel = styled.div<ClassNameProps>`
  position: relative;
`;
