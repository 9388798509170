import styled, { keyframes } from 'styled-components';
import Button from 'ui-kit/Button';

import { pxToVw } from 'shared/helpers/pxToVw';

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Block = styled.div`
  position: absolute;
  right: ${pxToVw(0)};
  top: ${pxToVw(80)};
  animation: ${fadeInAnimation} 0.6s ease forwards;
`;

export const MiniCartBlock = styled.div`
  width: ${pxToVw(300)};
  max-height: ${pxToVw(280)};
  overflow: hidden;
`;

export const MainBlock = styled.div`
  position: relative;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  height: ${pxToVw(40)};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: ${pxToVw(12)};
`;
