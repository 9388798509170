import { FC, useRef, useState } from 'react';
import ButtonClose from 'ui-kit/ButtonClose';

import * as S from './styles';

export interface IGoodsQuantity {
  className?: string;
  value: string;
  drop: boolean;
  setShowMore: (value: boolean) => void;
}

const Goods: FC<IGoodsQuantity> = ({ className, value, drop, setShowMore }) => {
  const [dropped, setDropped] = useState(drop);
  const block = useRef<HTMLDivElement>(null);
  const dropdown = useRef<HTMLDivElement>(null);

  const onClose = () => {
    setDropped(false);
    setTimeout(() => {
      setShowMore(!drop);
    }, 300);
  };

  return (
    <>
      <S.Block className={className} ref={block}>
        <S.Drop ref={dropdown} isdropped={dropped}>
          <ButtonClose onClick={onClose} />
          <S.Text>{value}</S.Text>
        </S.Drop>
      </S.Block>
    </>
  );
};

export default Goods;
