import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

export const BreadcrumbsContainer = styled.nav`
  margin-top: ${pxToVw(-8)};
  padding-bottom: ${pxToVw(20)};
`;

export const BreadcrumbLink = styled(Link)`
  font-size: ${pxToVw(16)};
  color: var(--text-secondary);
  text-decoration: none;

  &:last-child {
    color: #181818;
    font-weight: 700;
  }
`;

export const Separator = styled.span`
  margin: 0 ${pxToVw(8)};
`;
