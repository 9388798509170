import styled from 'styled-components';
import Input from 'ui-kit/Input';

import { pxToVw } from 'shared/helpers/pxToVw';

import ProductStickers from './ProductStickers';

export const StyledInput = styled(Input)`
  margin: ${pxToVw(12)} 0;
  width: 100%;
  height: ${pxToVw(36)};
  padding: ${pxToVw(8)} ${pxToVw(14)};
  border-radius: ${pxToVw(8)};
  font-size: ${pxToVw(14)};
  line-height: ${pxToVw(18)};
  color: var(--body-color);
  background-color: var(--white);
  border: ${pxToVw(1)} solid var(--primary);
`;

export const StyledProductStickers = styled(ProductStickers)`
  margin-top: ${pxToVw(10)};
`;

export const SectionBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${pxToVw(24)};
`;

export const Title = styled.div`
  color: var(--primary);
  font-weight: 700;
  margin-bottom: ${pxToVw(8)};
  font-size: ${pxToVw(18)};
  line-height: 1.2;
`;
