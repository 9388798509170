import { useEffect } from 'react';
import _ from 'lodash';

const useScrollPagination = (callback: () => void) => {
  useEffect(() => {
    const handleScroll = _.debounce(() => {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement || document.body;
      if (scrollTop + clientHeight >= 0.9 * scrollHeight) {
        callback();
      }
    }, 300);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [callback]);
};

export default useScrollPagination;
