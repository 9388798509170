import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

export const LoginRegisterContainer = styled.div`
  background-color: var(--white);
  border-radius: ${pxToVw(16)};
  padding: ${pxToVw(24)};
  margin-bottom: ${pxToVw(32)};
`;
