import { fetchProductPropertiesApi } from 'entities/Product/services/productApi';
import { action, makeObservable, observable, runInAction, toJS } from 'mobx';

import { propertyCodes } from 'shared/helpers/propertyCodes';
import { ProductProperty } from 'shared/types/ProductDetailsType';

import { RootStore } from 'stores/RootStore';

class BaseProductPropertiesStore {
  propertyData: { [key: string]: ProductProperty } | null = null;
  isLoading = false;
  error: Error | null = null;

  constructor(protected rootStore: RootStore) {
    makeObservable(this, {
      propertyData: observable,
      isLoading: observable,
      error: observable,
      postProductData: action,
    });
  }

  async postProductData(productId: string, type: string = 'multi') {
    this.isLoading = true;
    this.error = null;

    try {
      const response = await fetchProductPropertiesApi(productId, type);

      runInAction(() => {
        this.propertyData = response.data.data;
        this.isLoading = false;
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('Ошибка запроса');
        this.isLoading = false;
      });
    }
  }

  getPropertyData(code: string) {
    return this.propertyData?.[code] ?? null;
  }

  getGalleryData() {
    const galleryDataValue = toJS(this.getPropertyData(propertyCodes.galleryPhoto)?.value);
    if (Array.isArray(galleryDataValue) && galleryDataValue.length > 0) {
      return galleryDataValue.filter(Boolean);
    } else {
      const fallbackImage = this.rootStore.productDetailsStore.getFallbackImage();
      return [fallbackImage];
    }
  }
}

export default BaseProductPropertiesStore;
