import { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Avatar from 'ui-kit/Avatar';
import { Size } from 'ui-kit/Avatar/types';
import Sprite from 'ui-kit/Sprite';

import rootStore from 'stores/RootStore';

import UserControl from '../UserControl';
import * as S from './styles';

type HeaderAccountProps = {
  variant?: 'button';
};

const HeaderAccount: FC<HeaderAccountProps> = observer(({ variant }) => {
  const { authStore, modalStore } = rootStore;
  const isLoggedIn = authStore.status === 'Success';

  const handleUserControlToggle = () => {
    if (modalStore.isUserControlOpen) {
      modalStore.closeUserControl();
    } else {
      modalStore.openUserControl();
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        event.target &&
        modalStore.isUserControlOpen &&
        !(event.target as Element).closest('.user-control-container') &&
        !(event.target as Element).closest('.user-control')
      ) {
        modalStore.closeUserControl();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [modalStore.isUserControlOpen, modalStore]);

  if (variant === 'button') {
    return (
      <>
        <S.StyledButton variant="transparent" onClick={modalStore.openAuthModalModal}>
          Авторизоваться
        </S.StyledButton>
      </>
    );
  }

  return (
    <>
      {isLoggedIn ? (
        <S.HeaderAccount>
          <S.Block onClick={handleUserControlToggle} className="user-control-container">
            <Avatar title={authStore.userInfo?.name} size={Size.Small} />
          </S.Block>
          {modalStore.isUserControlOpen && <UserControl />}
        </S.HeaderAccount>
      ) : (
        <S.HeaderAccount onClick={modalStore.openAuthModalModal}>
          <S.HeaderAccountAvatar>
            <S.Block className="c-user__avatar c-user__avatar--sm">
              <Sprite icon="user-auth" className="c-user__avatar-icon" />
            </S.Block>
          </S.HeaderAccountAvatar>
        </S.HeaderAccount>
      )}
    </>
  );
});

export default HeaderAccount;
