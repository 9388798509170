import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

export const Item = styled.dl`
  margin-bottom: 0;
  padding-top: ${pxToVw(6)};
  padding-bottom: ${pxToVw(6)};
  display: flex;
  align-items: flex-end;
  line-height: 1.2;
  color: var(--body-color);
`;

export const Label = styled.dt`
  position: relative;
  width: 50%;
  font-weight: 400;

  &::before {
    position: absolute;
    bottom: 0.2em;
    left: 0;
    display: block;
    width: 100%;
    border-bottom: ${pxToVw(1)} dotted var(--stroke);
    content: '';
  }
`;

export const LabelText = styled.span`
  position: relative;
  display: inline;
  align-items: flex-start;
  padding-right: ${pxToVw(8)};
  background-color: var(--body-bg);
`;

export const Value = styled.dd`
  position: relative;
  width: 50%;
  margin-bottom: 0;
  padding-left: ${pxToVw(8)};
  font-weight: 700;
`;
