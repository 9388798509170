import { makeAutoObservable, runInAction } from 'mobx';

import { ApiResponse, ServiceElement } from 'shared/types/ServiceTypes';

import api from 'stores/Api';
import { RootStore } from 'stores/RootStore';

class ServicesProductStore {
  services: ServiceElement[] = [];
  loading: boolean = false;
  error: string | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  async postProductServicesData(productId: string) {
    this.loading = true;
    this.error = null;

    try {
      const response = await api.post<ApiResponse>('element_services', {
        id: productId,
      });
      runInAction(() => {
        this.services = response.data.data;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        if (error instanceof Error) {
          this.error = error.message;
        } else {
          this.error = String(error);
        }
        this.loading = false;
      });
    }
  }
}

export default ServicesProductStore;
