import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

export const Characteristics = styled.div`
  margin-bottom: ${pxToVw(40)};
`;

export const Title = styled.h3`
  margin-bottom: 28px;
  font-size: ${pxToVw(24)};
  line-height: 1.3;
`;

export const List = styled.div`
  margin-bottom: ${pxToVw(16)};
`;
