import { FC } from 'react';

import { IProperty } from 'stores/SaleStore';

import * as S from './styles';

interface DataTypes {
  data: IProperty;
}

const Steps: FC<DataTypes> = ({ data }) => {
  return (
    <S.Steps>
      <h4>Шаги</h4>

      <S.StepsList className="list-unstyled">
        {data?.value ? data?.value.toReversed().map((element, index) => <S.Item key={index}>{element}</S.Item>) : ''}
      </S.StepsList>
    </S.Steps>
  );
};

export default Steps;
