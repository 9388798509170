import { FC, lazy, Suspense } from 'react';
import { observer } from 'mobx-react-lite';
import Loader from 'ui-kit/Loader';
import Modal from 'ui-kit/Modal';

import NavEnd from 'components/NavEnd';

import rootStore from 'stores/RootStore';

const LazyAuthComponent = lazy(() => import('modules/Authorization'));
const LazyCategoryMenu = lazy(() => import('components/CategoryMenu'));

const Modals: FC = observer(() => {
  const { modalStore } = rootStore;

  return (
    <Suspense fallback={<Loader />}>
      <Modal isOpen={modalStore.isAuthModalOpen} onClose={modalStore.closeAuthModalModal} closeButton={false}>
        <LazyAuthComponent />
      </Modal>

      {modalStore.isCategoryMenuOpen && <LazyCategoryMenu onClose={modalStore.closeCategoryMenu} />}

      <NavEnd />
    </Suspense>
  );
});

export default Modals;
