import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { CatalogPage, MainCatalogPage } from 'modules/Catalog';
import { RoutesLinks } from 'RoutesLinks';

const Catalog: FC = () => {
  const location = useLocation();
  const path = location.pathname;

  switch (path) {
    case RoutesLinks.CATALOG:
      return <MainCatalogPage />;
    default:
      return !path ? null : <CatalogPage />;
  }
};

export default Catalog;
