import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

export const Item = styled.dl<{ overflow: boolean }>`
  height: ${pxToVw(80)};
  margin-bottom: 0;
  padding-left: ${pxToVw(12)};
  padding-right: ${pxToVw(12)};
  display: flex;
  align-items: flex-start;
  line-height: 1.2;
  color: var(--body-color);
  overflow: ${({ overflow }: { overflow: boolean }) => (overflow ? 'visible' : 'hidden')};
`;

export const Label = styled.dt`
  position: relative;
  width: 50%;
  font-weight: 400;

  &::before {
    position: absolute;
    bottom: 0.2em;
    left: 0;
    display: block;
    width: 100%;
    border-bottom: ${pxToVw(1)} dotted var(--stroke);
    content: '';
  }
`;

export const LabelText = styled.span`
  position: relative;
  display: inline;
  align-items: flex-start;
  padding-right: ${pxToVw(8)};
`;

export const Value = styled.dd`
  position: relative;
  width: 50%;
  margin-bottom: 0;
  padding-left: ${pxToVw(8)};
  font-weight: 700;
`;

export const GoodsContainer = styled.div`
  position: relative;
  padding-top: 1px;
  flex: 0 0 auto;
`;
