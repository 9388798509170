import BaseProductPropertiesStore from 'stores/products/BaseProductPropertiesStore';
import { RootStore } from 'stores/RootStore';

class TopProductPropertiesStore extends BaseProductPropertiesStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }

  async postTopProductFeaturesData(productId: string) {
    await super.postProductData(productId, 'top');
  }
}

export default TopProductPropertiesStore;
