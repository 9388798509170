import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { commonImageStyles } from 'shared/helpers/generalStyles';
import { pxToVw } from 'shared/helpers/pxToVw';

export const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Block = styled.div`
  background-color: var(--white);
  border-radius: ${pxToVw(16)};
  padding: ${pxToVw(24)};
  margin-bottom: ${pxToVw(32)};
`;

export const ContentItemLink = styled(Link)`
  display: flex;
  align-items: center;
  padding-top: ${pxToVw(4)};
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
`;

export const ContentName = styled.div`
  font-size: ${pxToVw(12)};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  -webkit-box-orient: vertical;
`;

export const ImageBlock = styled.div`
  margin-right: ${pxToVw(8)};
  height: ${pxToVw(40)};
  width: ${pxToVw(40)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentImage = styled.img`
  ${commonImageStyles}
`;

export const StyledDrop = styled.div`
  width: 100%;
  min-height: ${pxToVw(80)};
  padding: ${pxToVw(8)} ${pxToVw(8)};
  background: white;
  border-radius: ${pxToVw(16)};
  margin: ${pxToVw(1)};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: ${fadeInAnimation} 0.3s ease forwards;
  display: flex;
  align-items: center;
`;
