import { fetchPopularApi } from 'entities/Product/services/productApi';
import { makeAutoObservable, runInAction } from 'mobx';

import { ProductType } from 'shared/types/ProductType';

import { RootStore } from 'stores/RootStore';

class PopularInThisCategoryStore {
  popularData: ProductType[] | null = null;
  error: Error | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  async fetchPopular(path?: string) {
    const trimmedUrl = path ? path.replace(/^\/|\/$/g, '') : '';

    try {
      const response = await fetchPopularApi(trimmedUrl);
      runInAction(() => {
        this.popularData = response.data.data;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('An unexpected error occurred');
      });
    }
  }
}

export default PopularInThisCategoryStore;
