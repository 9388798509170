import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Loader from 'ui-kit/Loader';

import Skeleton from 'components/FastTag/Skeleton';

import { apiStatuses } from 'shared/helpers/vars';
import { FastTagElement } from 'shared/types/FastTagTypes';

import rootStore from 'stores/RootStore';

import * as S from './styles';

const FastTag = observer(() => {
  const { fastTagStore } = rootStore;
  const { PENDING, REJECTED } = apiStatuses;
  const navigate = useNavigate();

  useEffect(() => {
    fastTagStore.loadData();
  }, [fastTagStore]);

  if (!fastTagStore.elements) return <Loader position="static" />;

  if (fastTagStore.elements.state === PENDING) return <Skeleton count={4} />;

  if (fastTagStore.elements.state === REJECTED) return <div>Error FastTag</div>;

  const elements = (fastTagStore.elements.value as FastTagElement[]) || [];

  return (
    <S.FastTagList className="list-unstyled">
      {elements.map((instance) => (
        <S.FastTagListItem key={instance.id}>
          <S.FastTagInstance onClick={() => navigate(instance.url)} variant="secondary" size="lg">
            {instance.name}
            <img src={instance.preview_picture} className="icon" alt="" />
          </S.FastTagInstance>
        </S.FastTagListItem>
      ))}
    </S.FastTagList>
  );
});

export default FastTag;
