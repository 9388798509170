import styled from 'styled-components';
import Sprite from 'ui-kit/Sprite';

import { pxToVw } from 'shared/helpers/pxToVw';

export const Box = styled.div`
  margin-top: ${pxToVw(26)};
  position: relative;
`;

export const Title = styled.h4`
  display: flex;
  align-items: center;
  margin-bottom: ${pxToVw(14)};
  color: var(--text-secondary);
  font-weight: 700;
  font-size: ${pxToVw(18)};
  line-height: ${pxToVw(24)};
`;

export const Icon = styled(Sprite)`
  width: ${pxToVw(24)};
  height: ${pxToVw(24)};
  margin-right: ${pxToVw(10)};
  fill: var(--warn);
`;
