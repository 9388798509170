import { useLayoutEffect, useRef } from 'react';

/* Получения последнего значения, который был изменен */

export const useLatest = <T>(value: T) => {
  const ref = useRef<T | null>(null);

  useLayoutEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
};
