export const RoutesLinks = {
  HOME: '/',
  BRANDS: '/brands/',
  CART: '/cart',
  SEARCH: '/search/',
  CATALOG: '/catalog/',
  PROMOTION: '/sale/',
  NEW: '/new',
  POPULAR: '/popular',
  COMPARE: '/compare',
  FAVORITE: '/favorite',
} as const;
