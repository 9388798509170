import { FC, ReactNode } from 'react';
import Badge from 'ui-kit/Badge';

import { calculateDiscountPercentage } from 'shared/helpers/calculateDiscountPercentage';
import { formatNumberWithSpaces } from 'shared/helpers/formatNumberWithSpaces';
import { pluralize } from 'shared/helpers/pluralize';
import { totalProps } from 'shared/types/ProductType';

import * as S from './styles';

type TotalProps = {
  total: totalProps;
  children: ReactNode;
  className?: string;
};

const Total: FC<TotalProps> = ({ total, children, className }) => {
  const percentResult = formatNumberWithSpaces(Math.ceil(total.cost));

  return (
    <S.TotalContainer className={className}>
      <S.TotalBlock>
        <S.BoldTitle>Итого:</S.BoldTitle>
        <S.Title>{pluralize(total.quantity || 0, ['товар', 'товара', 'товаров'])}</S.Title>
      </S.TotalBlock>

      <S.PriceBlock>
        <S.Price>{percentResult} ₽</S.Price>
        {total.costOld && <Badge color="warn">{calculateDiscountPercentage(total.costOld, total.cost) || 0}%</Badge>}
      </S.PriceBlock>

      <S.PriceBlock>
        <S.PriceOld>{formatNumberWithSpaces(total.costOld)} ₽</S.PriceOld>
      </S.PriceBlock>

      <S.SaleBlock>
        <S.PriceTitle>С учетом скидки</S.PriceTitle>
        <S.BorderDash />
        <S.Title>{percentResult}</S.Title>
      </S.SaleBlock>
      {children}
    </S.TotalContainer>
  );
};

export default Total;
