import { useEffect, useState } from 'react';

import rootStore from 'stores/RootStore';

const useInactivityRedirect = (timeout = 60000 /* 1 минута */) => {
  const { modalStore } = rootStore;
  const [redirected, setRedirected] = useState(false);

  useEffect(() => {
    const handleActivity = () => {
      modalStore.setLastActivity(Date.now());
      modalStore.closeInactivityModal();
      setRedirected(false);
    };

    const checkInactivity = () => {
      const currentTime = Date.now();
      if (currentTime - modalStore.lastActivity > timeout && !redirected) {
        modalStore.openInactivityModal();
        setRedirected(true);
      }
    };

    const activityTimer = setInterval(checkInactivity, 30000);

    window.addEventListener('touchmove', handleActivity);

    return () => {
      clearInterval(activityTimer);
      window.removeEventListener('touchmove', handleActivity);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalStore.lastActivity, timeout, redirected]);
};

export default useInactivityRedirect;
