import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

import { Size } from './types';

export type AvatarProps = {
  size: Size;
};

const getSize = (size: Size): string => {
  switch (size) {
    case Size.ExtraSmall:
      return pxToVw(24);
    case Size.Small:
      return pxToVw(32);
    case Size.Medium:
      return pxToVw(36);
    case Size.Large:
      return pxToVw(48);
    default:
      return pxToVw(56); // default ExtraLarge
  }
};

const getFontSize = (size: Size): string => {
  switch (size) {
    case Size.ExtraSmall:
      return pxToVw(12);
    case Size.Small:
      return pxToVw(16);
    case Size.Medium:
      return pxToVw(16);
    case Size.Large:
      return pxToVw(24);
    default:
      return pxToVw(24); // default ExtraLarge
  }
};

export const Avatar = styled.div<AvatarProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }: AvatarProps) => getSize(size)};
  height: ${({ size }: AvatarProps) => getSize(size)};
  font-size: ${({ size }: AvatarProps) => getFontSize(size)};
  border-radius: 50%;
  background-color: var(--primary);
  color: var(--white);
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  flex-shrink: 0;

  &:before {
    background-color: var(--warn);
    border-color: var(--white);
    width: 25%;
    height: 25%;
    top: ${pxToVw(1)};
    right: ${pxToVw(1)};
    position: absolute;
    border-radius: inherit;
    border: 2px solid var(--white);
    content: '';
  }
`;
