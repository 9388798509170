import { makeAutoObservable } from 'mobx';

import { RootStore } from './RootStore';

class ModalStore {
  isAuthModalOpen = false;
  isUserControlOpen = false;
  isCategoryMenuOpen = false;
  isInactivityModalOpen = false;
  lastActivity = Date.now();

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  openAuthModalModal = () => {
    this.isAuthModalOpen = true;
  };

  closeAuthModalModal = () => {
    this.isAuthModalOpen = false;
  };

  openUserControl = () => {
    this.isUserControlOpen = true;
  };

  closeUserControl = () => {
    this.isUserControlOpen = false;
  };

  openCategoryMenu = () => {
    this.isCategoryMenuOpen = true;
  };

  closeCategoryMenu = () => {
    this.isCategoryMenuOpen = false;
  };

  openInactivityModal = () => {
    this.isInactivityModalOpen = true;
  };

  closeInactivityModal = () => {
    this.isInactivityModalOpen = false;
  };

  setLastActivity = (time: number) => {
    this.lastActivity = time;
  };
}

export default ModalStore;
