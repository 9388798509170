import { fetchRecommendedProductApi } from 'entities/Product/services/productApi';
import { makeAutoObservable, runInAction } from 'mobx';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';

import { PreviewPropertyType, ProductType } from 'shared/types/ProductType';

import { RootStore } from 'stores/RootStore';

class RecommendedProductStore {
  kiosk = localStorage.getItem('kiosk') || 'default';
  data: ProductType[] | null = null;
  dataState: IPromiseBasedObservable<ProductType[]> | null = null;
  error: Error | null = null;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  async loadData() {
    try {
      const response = await fetchRecommendedProductApi(this.kiosk);
      runInAction(() => {
        this.data = response.data.data;
        this.dataState = fromPromise(Promise.resolve(this.data));
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('Неизвестная ошибка');
        throw error;
      });
    }
  }

  getRating(product: ProductType) {
    return product.preview_properties ? this.extractProperty(product.preview_properties, 'RATING') : null;
  }

  getCountReviews(product: ProductType) {
    return product.preview_properties ? this.extractProperty(product.preview_properties, 'COUNT_REVIEWS') : null;
  }

  private extractProperty(
    properties:
      | PreviewPropertyType[]
      | {
          [key: string]: PreviewPropertyType;
        },
    code: string
  ): string | number | string[] | null {
    if (Array.isArray(properties)) {
      const property = properties.find((p) => p.code === code);
      return property ? property.value : null;
    } else {
      const property = Object.values(properties).find((p) => p.code === code);
      return property ? property.value : null;
    }
  }

  get errorMessage() {
    return this.error ? this.error.message : null;
  }

  get isLoading() {
    return this.dataState?.state === 'pending';
  }

  get isError() {
    return this.dataState?.state === 'rejected';
  }
}

export default RecommendedProductStore;
