import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';
import { truncateMultiLine } from 'shared/helpers/truncateMultiLine';

export const bold = styled.span`
  font-weight: 700;
`;

export const Title = styled.h1`
  max-width: 75%;
  margin-bottom: ${pxToVw(16)};
  font-size: ${pxToVw(14)};
  line-height: ${pxToVw(32)};
  ${truncateMultiLine(3)}
`;
