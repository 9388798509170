import { IBreadcrumbsData, IBreadcrumbsResponse } from 'entities/Breadcrumbs/types';
import { makeAutoObservable, runInAction } from 'mobx';

import api from './Api';
import { RootStore } from './RootStore';

class BreadcrumbsStore {
  breadcrumbs: IBreadcrumbsData[] = [];
  error: Error | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  async fetchBreadcrumbs(url: string) {
    try {
      const response = await api.post<IBreadcrumbsResponse>('bread_crumbs', { url });

      runInAction(() => {
        this.breadcrumbs = response.data.data;
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('Неизвестная ошибка');
      });
    }
  }
}

export default BreadcrumbsStore;
