import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import Loader from 'ui-kit/Loader';

import Container from 'components/Container';
import FastTag from 'components/FastTag';

const LazyPopularProducts = lazy(() => import('components/PopularProducts'));
const LazySearchBar = lazy(() => import('components/SearchBar'));
const LazyTopBrands = lazy(() => import('components/TopBrands'));
const LazyProductsNew = lazy(() => import('components/ProductsNew'));
const LazyPromotionsShowcase = lazy(() => import('components/PromotionsShowcase'));
const LazyProductCategories = lazy(() => import('modules/Main/components/ProductCategories'));
const LazyShowcaseCarouselDefault = lazy(() => import('components/ShowcaseCarouselDefault'));
const LazyRecommendedProducts = lazy(() => import('components/RecommendedProducts'));
const LazyViewedProducts = lazy(() => import('components/ViewedProducts'));

const Main = () => {
  return (
    <Container>
      <Helmet>
        <title>Всёсмарт - интернет-магазин товаров для умного дома</title>
      </Helmet>

      <FastTag />

      <LazyPopularProducts />

      <Suspense fallback={<Loader />}>
        <LazySearchBar />

        <LazyTopBrands />

        <LazyProductsNew />

        <LazyPromotionsShowcase />

        <LazyProductCategories />

        <LazyShowcaseCarouselDefault />

        <LazyRecommendedProducts />

        <LazyViewedProducts />
      </Suspense>
    </Container>
  );
};

export default Main;
