import { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ProductPropertyItem from 'ui-kit/ProductPropertyItem';
import ToggleComponent from 'ui-kit/ToggleComponent';

import { propertyCodes } from 'shared/helpers/propertyCodes';

import rootStore from 'stores/RootStore';

import * as S from './styles';

export const excludedProperties = [
  propertyCodes.countReviews,
  propertyCodes.countRating,
  propertyCodes.galleryPhoto,
  propertyCodes.stickers,
  propertyCodes.colors,
  propertyCodes.newProduct,
  propertyCodes.cml2Taxes,
  propertyCodes.cml2Traits,
  propertyCodes.starayaTsena,
  propertyCodes.cml2BarCode,
  propertyCodes.inStock,
  propertyCodes.SERVICE_SECTION,
  propertyCodes.CML2_BASE_UNIT,
  propertyCodes.DOCUMENTS,
  propertyCodes.filterColor,
  propertyCodes.new,
  propertyCodes.AVAILABLE_REGION_CENTRAL,
  propertyCodes.avitoAdvantage,
  propertyCodes.AVAILABLE_REGION_0000073738,
  propertyCodes.AVAILABLE_REGION_0000103664,
  propertyCodes.AVAILABLE_REGION_0000445112,
  propertyCodes.AVAILABLE_REGION_0000293598,
  propertyCodes.AVAILABLE_REGION_0000812044,
  propertyCodes.AVAILABLE_REGION_0000386590,
  propertyCodes.AVAILABLE_REGION_0000600317,
  propertyCodes.AVAILABLE_REGION_0000650509,
  propertyCodes.AVAILABLE_REGION_0000394020,
  propertyCodes.AVAILABLE_REGION_0000896058,
  propertyCodes.AVAILABLE_REGION_0000670178,
  propertyCodes.AVAILABLE_REGION_0000949228,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renderPropertyValue = (property: any) => {
  if (property.list?.value) {
    return property.list.value;
  } else if (property.brand) {
    return property.brand.name;
  } else if (Array.isArray(property.value) || typeof property.value === 'object') {
    return property.value.join('; ');
  } else if (typeof property.value === 'string') {
    return property.value;
  } else {
    return '';
  }
};

const AllProductFeatures: FC = observer(() => {
  const { baseProductPropertiesStore } = rootStore;
  const propertyData = baseProductPropertiesStore.propertyData;
  const [showAll, setShowAll] = useState(false);
  const quantityShowFeatures = 16;

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  if (!propertyData) return null;

  // Фильтрация исключенных свойств и преобразование в массив для удобства работы
  const filteredProperties = Object.entries(propertyData).filter(
    ([, property]) => !excludedProperties.includes(property.code)
  );

  const displayedProperties = showAll ? filteredProperties : filteredProperties.slice(0, quantityShowFeatures);

  return (
    <S.Characteristics id="characteristics">
      <S.Title>Характеристики</S.Title>
      <S.List>
        {displayedProperties.map(([, property]) => (
          <ProductPropertyItem key={property.id} name={property.name} value={renderPropertyValue(property)} />
        ))}
      </S.List>
      {filteredProperties.length > quantityShowFeatures ? (
        <ToggleComponent
          isVisible={showAll}
          onClick={toggleShowAll}
          text={showAll ? 'Свернуть' : 'Все характеристики'}
          iconName="arrow2"
        />
      ) : (
        ''
      )}
    </S.Characteristics>
  );
});

export default AllProductFeatures;
