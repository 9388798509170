import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ProductPage } from 'modules/Product';

import NotFound from 'pages/NotFound';

import rootStore from 'stores/RootStore';

type ProductParams = {
  productUrl: string;
};

const Product: FC = observer(() => {
  const { productDetailsStore } = rootStore;
  const { productUrl } = useParams<ProductParams>();

  if (
    !productDetailsStore.product ||
    Object.keys(productDetailsStore.product).length === 0 ||
    productDetailsStore.error
  ) {
    return <NotFound />;
  }

  return !productUrl ? null : <ProductPage />;
});

export default Product;
