import { pxToVw } from 'shared/helpers/pxToVw';
import { FormCheckProps } from 'shared/types/FormCheckTypes';

export const calculatePaddings = ({ $inline, $reverse }: FormCheckProps) => {
  const top = !$inline && !$reverse ? pxToVw(8) : '0';
  const right = $inline && $reverse ? pxToVw(8) : '0';
  const left = $inline && !$reverse ? pxToVw(8) : '0';
  const bottom = !$inline && $reverse ? pxToVw(8) : '0';

  return `${top} ${right} ${bottom} ${left}`;
};
