import React, { FC, Suspense } from 'react';
import { Link, useRouteError } from 'react-router-dom';
import { RoutesLinks } from 'RoutesLinks';
import Button from 'ui-kit/Button';

import Container from 'components/Container';

interface ErrorInfo {
  statusText?: string;
  status?: number;
  message?: string;
}

const ErrorBoundary: FC = () => {
  const error = useRouteError() as ErrorInfo;

  const reloadPage = () => {
    location.reload();
  };

  console.error(error);

  return (
    <Suspense fallback="">
      <Container>
        <h1>Ошибка</h1>

        <p>
          <pre>
            <code>{JSON.stringify(error, null, 2)}</code>
          </pre>
        </p>

        <Button onClick={reloadPage} className="ps-4 pe-4">
          Обновить страницу
        </Button>
        <Link to={RoutesLinks.HOME} className="ps-3 l-link">
          Вернуться на главную страницу
        </Link>
      </Container>
    </Suspense>
  );
};

export default ErrorBoundary;
