import * as S from './styles';

interface ProductPropertyItemProps {
  name: string;
  value: string;
}

const ProductPropertyItem = ({ name, value }: ProductPropertyItemProps) => {
  return (
    <S.Item>
      <S.Label>
        <S.LabelText>{name}</S.LabelText>
      </S.Label>
      <S.Value>{value}</S.Value>
    </S.Item>
  );
};

export default ProductPropertyItem;
