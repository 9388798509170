import { forwardRef, InputHTMLAttributes } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  type?: 'text' | 'tel' | 'email' | 'number' | 'search';
  autoComplete?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ type, className, placeholder, autoComplete, ...props }, ref) => {
    return (
      <input
        ref={ref}
        type={type || 'text'}
        className={className}
        placeholder={placeholder}
        autoComplete={autoComplete || 'off'}
        {...props}
      />
    );
  }
);

export default Input;
