import { FC, HTMLAttributes } from 'react';

import * as S from './styles';

export interface BadgeProps extends HTMLAttributes<HTMLElement> {
  color?: 'warn' | 'primary' | 'new' | 'hit';
  size?: 'large' | 'small';
}

const Badge: FC<BadgeProps & HTMLAttributes<HTMLElement>> = ({ children, color, size, ...rest }) => {
  return (
    <S.BadgeEl color={color} size={size} {...rest}>
      {children}
    </S.BadgeEl>
  );
};

export default Badge;
