import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import Breadcrumbs from 'components/Breadcrumb';
import Container from 'components/Container';

import rootStore from 'stores/RootStore';

import SubCategory from '../components/SubCategory';
import * as S from './styles';

export const MainCatalogPage: FC = observer(() => {
  const { menuStore } = rootStore;

  return (
    <Container>
      <Breadcrumbs />

      <S.MenuTabs>
        {menuStore.menuData.map((category) => {
          if (!category.child_categories.length) return null;

          return (
            <S.MenuTabPanel key={category.id}>
              <S.Title to={category.url}>
                <S.MenuIcon style={{ backgroundImage: `url('${category.user_fields?.icon}')` }} /> {category.name}
              </S.Title>

              <S.Boxes>
                {category.child_categories.map((subCategory) => (
                  <SubCategory key={subCategory.id} subCategory={subCategory} />
                ))}
              </S.Boxes>
            </S.MenuTabPanel>
          );
        })}
      </S.MenuTabs>
    </Container>
  );
});
