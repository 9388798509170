import { Helmet } from 'react-helmet-async';
import { ComprasionPage } from 'modules/Comparison';

import Breadcrumbs from 'components/Breadcrumb';
import Container from 'components/Container';

const Compare = () => {
  return (
    <Container>
      <Helmet>
        <title>Сравнение товаров</title>
      </Helmet>

      <Breadcrumbs />

      <ComprasionPage />
    </Container>
  );
};

export default Compare;
