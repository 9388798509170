import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

export const Block = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${pxToVw(32)};
  margin-bottom: ${pxToVw(32)};
`;

export const CartContainer = styled.div`
  width: 100%;
  max-width: 70%;
  flex-grow: 1;
`;
