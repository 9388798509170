import { setQuantityApi } from 'entities/Cart/services/cartApi';
import { makeAutoObservable, runInAction } from 'mobx';

import { CostParamsType, PreviewPropertyType, ProductType } from 'shared/types/ProductType';

import { RootStore } from 'stores/RootStore';

export interface ProductTypeFromCartStore extends ProductType {
  quantity?: number;
  favoritesState?: boolean;
  onIncreaseQuantity?: (quantity: number) => void;
  onReduceQuantity?: (quantity: number) => void;
  onChangeFavoritesState?: (favoritesState: boolean) => void;
}

class CartProductItemStore {
  rootStore;
  isLoading = false;
  error: Error | null = null;
  id: number;
  name: string;
  quantity: number;
  favoritesState: boolean;
  url: string;
  preview_picture?: string;
  cost_params?: CostParamsType;
  preview_properties?: PreviewPropertyType[];

  constructor(item: ProductTypeFromCartStore, rootStore: RootStore) {
    this.rootStore = rootStore;
    this.id = item.id;
    this.name = item.name;
    this.quantity = item.quantity ? item.quantity : 1;
    this.favoritesState = !rootStore.favoritesStore.isFirstProduct(item.id);
    this.url = item.url;
    this.preview_picture = item.preview_picture;
    this.cost_params = item.cost_params;
    this.preview_properties = item.preview_properties;

    makeAutoObservable(this);
  }

  onChangeFavoritesState = (favoritesState: boolean) => {
    runInAction(() => {
      this.favoritesState = favoritesState;
    });
  };

  onIncreaseQuantity = (quantity: number) => {
    if (this.rootStore.authStore.isAuth) {
      this.setQuantity('increase', quantity);
    } else {
      runInAction(() => {
        this.quantity = quantity;
        this.rootStore.cartStore.getTotalFromCart(this.rootStore.cartStore.productsData);
        this.rootStore.cartStore.formattedProductQuantity(this, quantity);
      });
    }
  };

  onReduceQuantity = (quantity: number) => {
    if (this.rootStore.authStore.isAuth) {
      this.setQuantity('reduce', quantity);
    } else {
      runInAction(() => {
        this.quantity = quantity;
        this.rootStore.cartStore.getTotalFromCart(this.rootStore.cartStore.productsData);
        this.rootStore.cartStore.formattedProductQuantity(this, quantity);
      });
    }
  };

  setQuantity = async (method: 'increase' | 'reduce', quantity: number) => {
    try {
      this.setIsLoading(true);
      const response = await setQuantityApi(method, String(this.id));
      const data = response.data.data;
      if (data.error) {
        this.error = new Error(data.error);
        return;
      }
      runInAction(() => {
        this.quantity = quantity;
        this.rootStore.cartStore.getTotalFromCart(this.rootStore.cartStore.productsData);
        this.setIsLoading(false);
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('An unexpected error occurred');
        this.setIsLoading(false);
      });
    }
  };

  setIsLoading = (value: boolean) => {
    runInAction(() => {
      this.isLoading = value;
    });
  };
}

export default CartProductItemStore;
