import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import noPhoto from 'assets/img/no-photos.png';

import { ButtonState } from 'shared/enum/enums';
import { formatNumberWithSpaces } from 'shared/helpers/formatNumberWithSpaces';
import { ServiceElement } from 'shared/types/ServiceTypes';

import rootStore from 'stores/RootStore';

import * as S from './styles';

const CardHorizontal = ({ data }: { data: ServiceElement }) => {
  const { name: title, preview_picture: imageUrl, url, cost_params, id } = data;
  const [buttonState, setButtonState] = useState(ButtonState.IDLE);
  const { cartStore } = rootStore;
  const navigate = useNavigate();

  const costOld = cost_params?.cost === cost_params?.old_cost ? false : cost_params?.old_cost;
  const urlProduct = `${url}${data.id}`;
  const outOfStock = buttonState === ButtonState.OUT_OF_STOCK;
  const inCart = buttonState === ButtonState.IN_CART;

  useEffect(() => {
    if (!cost_params?.in_stock) {
      setButtonState(ButtonState.OUT_OF_STOCK);
    } else if (!cartStore.isFirstProduct(id)) {
      setButtonState(ButtonState.IN_CART);
    }
  }, [cartStore, id, cost_params?.in_stock]);

  const handleAddToCart = async () => {
    if (inCart) {
      navigate('/cart');
    } else if (outOfStock) {
      navigate(urlProduct);
    } else {
      setButtonState(ButtonState.LOADING);
      if (await cartStore.addProductToCart(data)) {
        setButtonState(ButtonState.IN_CART);
      } else {
        setButtonState(ButtonState.IDLE);
      }
    }
  };

  const buttonVariant = outOfStock || inCart ? 'secondary' : 'primary';

  return (
    <S.Card>
      <S.Media to={urlProduct}>
        <S.Img loading="lazy" src={imageUrl ? imageUrl : noPhoto} alt="" />
      </S.Media>

      <S.Content>
        <div className="mb-auto">
          <S.Title to={urlProduct}>{title}</S.Title>
        </div>

        <S.Bottom>
          <S.Price>
            {cost_params?.cost ? <S.Cost>{formatNumberWithSpaces(cost_params?.cost)} ₽</S.Cost> : ''}
            {costOld ? <S.CostOLd>{formatNumberWithSpaces(costOld)} ₽</S.CostOLd> : ''}
          </S.Price>

          <S.BtnCart
            isLoading={buttonState === ButtonState.LOADING}
            className="add-to-cart-button"
            onClick={handleAddToCart}
            variant={buttonVariant}
            isIcon={true}
          ></S.BtnCart>
        </S.Bottom>
      </S.Content>
    </S.Card>
  );
};

export default CardHorizontal;
