import api from 'stores/Api';

import { ReviewQuestionResponse } from '../types';

export const fetchReviewsApi = async (productId: string, page: number) => {
  const response = await api.post<ReviewQuestionResponse>('reviews', {
    id: productId,
    page,
  });
  return response;
};

export const fetchQuestionsApi = async (productId: string) => {
  const response = await api.post<ReviewQuestionResponse>('questions', {
    id: productId,
  });
  return response;
};

export const fetchReviewsMediaApi = async (productId: string) => {
  const response = await api.post<{ data: string[] }>('reviews_media', {
    id: productId,
  });
  return response;
};
