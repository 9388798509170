import { action, makeObservable, observable, runInAction } from 'mobx';

import { FilterValuesProps } from 'shared/types/ProductType';

const ICheckboxStore: object = {
  value: observable,
  label: observable,
  count: observable,
  active: observable,
  active_count: observable,
  setValue: action,
};

class CheckboxStore {
  value: boolean = false;
  label: string;
  count: number;
  active: boolean;
  active_count: number;

  constructor(item: FilterValuesProps, toServerFormat: string[]) {
    this.value = toServerFormat?.includes(item.label) || false;
    this.label = item.label;
    this.count = item.count;
    this.active_count = item.active_count;
    this.active = item.active;
    makeObservable(this, ICheckboxStore);
  }

  setValue = (value: boolean) => {
    runInAction(() => {
      this.value = value;
    });
  };
}

export default CheckboxStore;
