import { FC, useState } from 'react';

import * as S from './styles';

interface Option {
  label: string;
  value: string;
}

interface DropdownProps {
  options: Option[];
  defaultOption?: string;
  onSelect: (selectedValue: string, label: string) => void;
}

const Dropdown: FC<DropdownProps> = ({ options, onSelect, defaultOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultOption);

  const handleSelect = (value: string, label: string) => {
    onSelect(value, label);
    setSelectedValue(label);
    setIsOpen(false);
  };

  return (
    <S.DropdownContainer>
      <S.Dropdown onClick={() => setIsOpen(!isOpen)}>
        {selectedValue} <S.StyledArrowIcon $isOpen={isOpen} />
      </S.Dropdown>
      {isOpen && (
        <S.DropdownList>
          {options.map((option, index) => (
            <S.OptionItem key={`${option.value}-${index}`} onClick={() => handleSelect(option.value, option.label)}>
              {option.label}
            </S.OptionItem>
          ))}
        </S.DropdownList>
      )}
    </S.DropdownContainer>
  );
};

export default Dropdown;
