import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Loader from 'ui-kit/Loader';

import rootStore from 'stores/RootStore';

import * as S from './styles';

const DetailColors = observer(() => {
  const { productDetailsStore, productTradeOfferStore } = rootStore;
  const productId = productDetailsStore.productId;

  useEffect(() => {
    if (productId) {
      productTradeOfferStore.fetchProducts(productId);
    }
  }, [productId, productTradeOfferStore]);

  if (productTradeOfferStore.isLoading) return <Loader />;

  if (!productTradeOfferStore.colors) return null;

  return productTradeOfferStore.colors.length > 0 ? (
    <S.Wrap>
      <S.Block className="fw-bold mb-4">Цвет</S.Block>
      <S.Block className="d-flex flex-wrap">
        {productTradeOfferStore.colors.map((property, index) => (
          <S.ColorItem
            key={index}
            dangerouslySetInnerHTML={{ __html: property.color[0].inline_svg }}
            className={productTradeOfferStore.isColorActive(property) ? 'active' : ''}
            to={`${property.url}`}
          />
        ))}
      </S.Block>
    </S.Wrap>
  ) : null;
});

export default DetailColors;
