import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

export const BoxesLink = styled(Link)`
  display: inline-flex;
  color: var(--text-secondary);
`;

export const BoxesList = styled.ul`
  padding-right: ${pxToVw(8)};
  margin-bottom: 0;
`;

export const BoxesTitle = styled.li`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: ${pxToVw(18)};
  line-height: ${pxToVw(24)};
  margin-bottom: ${pxToVw(8)};
`;
