import styled from 'styled-components';
import Button from 'ui-kit/Button';

import { pxToVw } from 'shared/helpers/pxToVw';

export const Text = styled.div`
  margin-top: ${pxToVw(8)};
  margin-bottom: ${pxToVw(8)};
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${pxToVw(8)};
  margin-bottom: ${pxToVw(8)};
  height: ${pxToVw(40)};
`;
