import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { A11y, Navigation } from 'swiper/modules';
import { Swiper, SwiperRef } from 'swiper/react';
import Loader from 'ui-kit/Loader';

import SliderArrows from 'components/SliderArrows';

import rootStore from 'stores/RootStore';

import * as S from './styles';

const NavBar = observer(() => {
  const { navBarStore } = rootStore;
  const swiperRef = useRef<SwiperRef>(null);
  const prevProducts = `prev-products-NavBar`;
  const nextProducts = `next-products-NavBar`;

  useEffect(() => {
    navBarStore.loadData();
  }, [navBarStore]);

  if (navBarStore.isLoading) return <Loader position="static" />;

  if (navBarStore.isError) {
    console.log('NavBar', navBarStore.errorMessage);
    return null;
  }

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <S.NavBar>
      <S.NavBarInner>
        <Swiper
          modules={[Navigation, A11y]}
          spaceBetween={32}
          slidesPerView={'auto'}
          navigation={{
            prevEl: `.${prevProducts}`,
            nextEl: `.${nextProducts}`,
          }}
          ref={swiperRef}
        >
          {navBarStore.data?.map((item, index) => (
            <S.NavBarItem key={item.url} className="nav-bar__item">
              <S.NavBarLink to={item.url}>
                {index === 0 && <S.NavBarLinkIcon icon="star" className="nav-bar__link-icon" />}
                {item.label}
              </S.NavBarLink>
            </S.NavBarItem>
          ))}
        </Swiper>

        <SliderArrows goPrev={goPrev} goNext={goNext} prevClass={prevProducts} nextClass={nextProducts} />
      </S.NavBarInner>
    </S.NavBar>
  );
});

export default NavBar;
