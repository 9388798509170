import { FC, useEffect, useState } from 'react';
import { ProductTypeByCompare } from 'modules/Comparison/types/types';
import { excludedProperties, renderPropertyValue } from 'modules/Product/components/AllProductFeatures';

import rootStore from 'stores/RootStore';

import CompareProductCard from '../CompareProductCard';
import CompareProductProperty from '../CompareProductProperty';
import * as S from './styles';

type CompareProductItemProps = {
  product: ProductTypeByCompare;
};

const CompareProductItem: FC<CompareProductItemProps> = ({ product }) => {
  const [compareState, setCompareState] = useState(false);
  const { compareStore } = rootStore;

  useEffect(() => {
    if (!compareStore.isFirstProduct(product.id)) {
      setCompareState(true);
    }
  }, [compareStore, product.id]);

  const handleRemoveToCompare = () => {
    if (compareState) {
      compareStore.deleteProductFromCompare(product);
      setCompareState(false);
    }
  };

  // Фильтрация исключенных свойств и преобразование в массив для удобства работы
  const filteredProperties = product.properties.filter((property) => !excludedProperties.includes(property.code));

  return (
    <S.CompareContainer>
      <CompareProductCard card={product} />
      <S.ProductPropertyContainer>
        <S.CardMeta className="list-unstyled">
          <S.CardMetaItem className="toggle" onClick={handleRemoveToCompare}>
            <S.CardMetaIconCompare icon="trash" className={compareState ? '' : 'icon'} />
          </S.CardMetaItem>
        </S.CardMeta>
        {filteredProperties.map((property, index) => (
          <CompareProductProperty
            key={`${property.id}_${index}`}
            name={property.name}
            value={renderPropertyValue(property)}
            code={property.code}
          />
        ))}
      </S.ProductPropertyContainer>
    </S.CompareContainer>
  );
};

export default CompareProductItem;
