import { FC } from 'react';

import rootStore from 'stores/RootStore';

import * as S from './styles';

interface AuthHeaderProps {
  title: string;
  closeButton?: boolean;
}

const AuthHeader: FC<AuthHeaderProps> = ({ title, closeButton = true }) => {
  const { modalStore } = rootStore;
  return (
    <S.ModalHeader>
      <S.ModalTitle>{title}</S.ModalTitle>
      {closeButton && (
        <S.ModalClose onClick={modalStore.closeAuthModalModal}>
          <S.ModalCloseIcon icon="close" />
        </S.ModalClose>
      )}
    </S.ModalHeader>
  );
};

export default AuthHeader;
