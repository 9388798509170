import { FC, lazy, Suspense, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Loader from 'ui-kit/Loader';

import Breadcrumbs from 'components/Breadcrumb';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';

import rootStore from 'stores/RootStore';

import DetailColors from '../components/DetailColors';
import Heading from '../components/Heading';
import ProductCarousel from '../components/ProductCarousel';
import ProductServices from '../components/ProductServices';
import QuickProductOrder from '../components/QuickProductOrder';
import * as S from './styles';

const LazyRecommendedProducts = lazy(() => import('components/RecommendedProducts'));
const LazyPopularProducts = lazy(() => import('components/PopularProducts'));
const LazyMeta = lazy(() => import('../components/Meta'));
const LazyAllProductFeatures = lazy(() => import('../components/AllProductFeatures'));
const LazyProductDescription = lazy(() => import('../components/ProductDescription'));
const LazyPricing = lazy(() => import('../components/Pricing'));
const LazyProductStickers = lazy(() => import('../components/ProductStickers'));
const LazyMediaCustomersCarousel = lazy(() => import('../components/MediaCustomersCarousel'));
const LazyViewedProducts = lazy(() => import('components/ViewedProducts'));
const LazyProductsNew = lazy(() => import('components/ProductsNew'));
const LazyPromotionsShowcase = lazy(() => import('components/PromotionsShowcase'));
const LazyProductCategories = lazy(() => import('modules/Main/components/ProductCategories'));
const LazyShowcaseCarouselDefault = lazy(() => import('components/ShowcaseCarouselDefault'));
const LazyTopProductFeatures = lazy(() => import('../components/TopProductFeatures'));
const LazyReviewsQuestions = lazy(() => import('modules/ReviewsQuestions'));

export const ProductPage: FC = observer(() => {
  const { baseProductPropertiesStore, productDetailsStore, productFeedbackStore } = rootStore;
  const productId = productDetailsStore.productId;
  const [isSidebarFixed, setIsSidebarFixed] = useState(false);

  useEffect(() => {
    if (productId !== null) {
      baseProductPropertiesStore.postProductData(productId);
      productFeedbackStore.fetchReviews(productId);
    }

    const handleScroll = () => {
      const shouldBeFixed = window.scrollY > 700;
      setIsSidebarFixed(shouldBeFixed);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [productFeedbackStore, productId, baseProductPropertiesStore]);

  return (
    <S.Wrapper>
      <Breadcrumbs />
      <Heading />
      <LazyMeta />
      <S.Content>
        <S.Info>
          <ProductCarousel />
          <LazyMediaCustomersCarousel />
          <LazyProductStickers />
          <LazyTopProductFeatures />
          <DetailColors />
          <LazyProductDescription />
          <LazyAllProductFeatures />
        </S.Info>

        <S.Side>
          <LazyPricing />
          <ProductServices />
        </S.Side>
      </S.Content>

      <Suspense fallback={<Loader />}>
        <ErrorBoundaryWrapper componentName="ReviewsQuestions">
          <LazyReviewsQuestions />
        </ErrorBoundaryWrapper>
        <LazyRecommendedProducts />
        <LazyViewedProducts />
        <LazyPopularProducts />
        <LazyProductsNew />
        <LazyPromotionsShowcase />
        <LazyProductCategories />
        <LazyShowcaseCarouselDefault />
        <QuickProductOrder isFixed={isSidebarFixed} />
      </Suspense>
    </S.Wrapper>
  );
});
