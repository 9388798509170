import { FC } from 'react';

import { PromotionTypes } from 'stores/PromotionsStore';

import * as S from './styles';

export type PromotionsCardProps = {
  data: PromotionTypes;
  bgColor: string;
};

const PromotionsCard: FC<PromotionsCardProps> = ({ data, bgColor }) => {
  return (
    <S.PromotionsCard to={data.url} style={{ backgroundColor: `#${bgColor}` }}>
      <S.PromotionsCardMedia>
        <S.PromotionsCardImg loading="lazy" src={data.preview_picture} alt="" />
      </S.PromotionsCardMedia>

      <S.PromotionsCardContent>
        <S.PromotionsCardTitle>{data.name}</S.PromotionsCardTitle>
      </S.PromotionsCardContent>
    </S.PromotionsCard>
  );
};

export default PromotionsCard;
