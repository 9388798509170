import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

export const MenuTabs = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const MenuTabPanel = styled.div`
  border-top-right-radius: ${pxToVw(16)};
  padding-top: ${pxToVw(24)};
  padding-bottom: ${pxToVw(24)};
`;

export const Boxes = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${pxToVw(24)} ${pxToVw(12)};
`;

export const Title = styled(Link)`
  display: inline-flex;
  align-items: center;
  margin-bottom: ${pxToVw(24)};
  font-size: ${pxToVw(20)};
  line-height: 1;
  color: inherit;
  font-weight: 500;
  font-family: var(--font-family-loos);
`;

export const MenuIcon = styled.div`
  width: ${pxToVw(24)};
  height: ${pxToVw(24)};
  margin-right: ${pxToVw(16)};
  background-repeat: no-repeat;
  background-size: contain;
  filter: grayscale(100%);
  pointer-events: none;
`;
