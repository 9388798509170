import styled from 'styled-components';

import Container from 'components/Container';

import { pxToVw } from 'shared/helpers/pxToVw';

export const Content = styled.div`
  display: flex;
`;

export const Info = styled.div`
  width: ${pxToVw(632)};
`;

export const Side = styled.aside`
  width: ${pxToVw(328)};
  padding-left: ${pxToVw(24)};
`;

export const Wrapper = styled(Container)`
  padding-bottom: ${pxToVw(150)};
`;
