import styled from 'styled-components';

import { pxToVw } from 'shared//helpers/pxToVw';

export const Card = styled.div`
  width: 20%;
  flex: 0 0 20%;
  padding-left: ${pxToVw(8)};
  padding-right: ${pxToVw(8)};
  margin-bottom: ${pxToVw(16)};
`;
