import { FC, useEffect, useRef } from 'react';

import { ModalProps } from './Models';
import * as S from './styles';

const Modal: FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  title,
  size = 'medium',
  canClose = true,
  className,
  overlayClassName,
  onAfterOpen,
  onBeforeClose,
  closeButton = true,
  animation,
  customStyles,
  focusFirstElement,
  ariaAttributes,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      if (onAfterOpen) onAfterOpen();

      if (focusFirstElement && modalRef.current) {
        const focusableElement = modalRef.current.querySelector(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        ) as HTMLElement;
        focusableElement?.focus();
      }
    }

    return () => {
      if (onBeforeClose) onBeforeClose();
    };
  }, [isOpen, onAfterOpen, onBeforeClose, focusFirstElement]);

  if (!isOpen) return null;
  const animationClass = animation ? `modal-${animation}` : '';

  const handleClose = () => {
    if (onBeforeClose) onBeforeClose();
    onClose();
  };

  const handleCanClose = () => {
    if (canClose) {
      if (onBeforeClose) onBeforeClose();
      onClose();
    }
  };

  const closeBtnContent = closeButton ? (
    <S.ModalClose onClick={handleClose}>
      <S.ModalCloseIcon icon="close" />
    </S.ModalClose>
  ) : (
    closeButton
  );

  return (
    <S.ModalWrapper>
      <S.ModalContent size={size} className={`${className} ${animationClass}`} {...ariaAttributes}>
        <S.Block>
          {title && <S.ModalTitle>{title}</S.ModalTitle>}
          {closeBtnContent}
        </S.Block>
        {children}
      </S.ModalContent>
      <S.ModalBackdrop
        className={`${overlayClassName ? overlayClassName : ''}`}
        onClick={handleCanClose}
        style={customStyles}
      />
    </S.ModalWrapper>
  );
};

export default Modal;
