import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';
import { truncateMultiLine } from 'shared/helpers/truncateMultiLine';

export const Title = styled.h1`
  max-width: 75%;
  margin-bottom: ${pxToVw(16)};
  font-size: ${pxToVw(24)};
  line-height: ${pxToVw(32)};
  ${truncateMultiLine(3)}
`;

export const Box = styled.div`
  margin-bottom: ${pxToVw(32)};
`;

export const Media = styled.img`
  border-radius: ${pxToVw(8)};
`;
