import { action, configure, makeObservable, observable, runInAction } from 'mobx';

import { ISearchType } from 'shared/types/SearchType';

import api from './Api';
import { RootStore } from './RootStore';

configure({
  useProxies: 'never',
});

const ISearchPreviewStore: object = {
  isOpenedSearch: observable,
  isLoading: observable,
  catalogList: observable,
  query: observable,

  setLoadingStatus: action,
  fetchSearchResults: action,
  updateQuery: action,
  setIsOpened: action,
};

class SearchBarStore {
  isOpenedSearch = false;
  isLoading = false;
  catalogList: ISearchType | null = null;
  query = '';

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, ISearchPreviewStore);
  }

  setLoadingStatus(status: boolean) {
    this.isLoading = status;
  }
  async fetchSearchResults() {
    try {
      if (!this.query || this.isLoading) {
        return;
      }
      this.setLoadingStatus(true);

      const result = await api.post('search', { search: this.query });

      if (!result.status || typeof result.data === 'string') {
        throw new Error('Некорректный ответ от сервера');
      }

      runInAction(() => {
        this.setLoadingStatus(false);
        this.catalogList = result.data.data;
      });
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
      this.setLoadingStatus(false);
      throw error;
    }
  }

  setIsOpened(value: boolean) {
    this.isOpenedSearch = value;
  }

  updateQuery(query: string) {
    this.query = query;
  }
}

export default SearchBarStore;
