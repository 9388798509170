import { FC, Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import rootStore from 'stores/RootStore';

import * as S from './styles';

const Breadcrumbs: FC = observer(() => {
  const { breadcrumbsStore } = rootStore;
  const location = useLocation().pathname;

  useEffect(() => {
    breadcrumbsStore.fetchBreadcrumbs(location);
  }, [breadcrumbsStore, location]);

  if (breadcrumbsStore.error) return;

  return (
    <S.BreadcrumbsContainer aria-label="breadcrumb">
      {breadcrumbsStore.breadcrumbs.map((item, index) => (
        <Fragment key={index}>
          <S.BreadcrumbLink to={item.url}>{item.name}</S.BreadcrumbLink>
          {index < breadcrumbsStore.breadcrumbs.length - 1 && <S.Separator>/</S.Separator>}
        </Fragment>
      ))}
    </S.BreadcrumbsContainer>
  );
});

export default Breadcrumbs;
