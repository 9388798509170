import { FC, useRef } from 'react';
import { ChildCategory } from 'entities/Catalog/types';
import { A11y, Navigation } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import Sprite from 'ui-kit/Sprite';

import ShowcaseCard from 'components/ShowcaseCard';
import SliderArrows from 'components/SliderArrows';

import * as S from './styles';

type ShowcaseCarouselProps = {
  promotionsData: ChildCategory[];
  showAll?: boolean;
};

const ShowcaseCarousel: FC<ShowcaseCarouselProps> = ({ promotionsData, showAll = true }) => {
  const swiperRef = useRef<SwiperRef>(null);
  const prevProducts = `prev-products-Showcase`;
  const nextProducts = `next-products-Showcase`;

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <S.Showcase>
      <Swiper
        modules={[Navigation, A11y]}
        spaceBetween={32}
        slidesPerView={3}
        navigation={{
          prevEl: `.${prevProducts}`,
          nextEl: `.${nextProducts}`,
        }}
        ref={swiperRef}
      >
        {promotionsData.map((instance, index) => (
          <SwiperSlide key={instance.id}>
            <ShowcaseCard data={instance} index={index} />
          </SwiperSlide>
        ))}
      </Swiper>
      <SliderArrows goPrev={goPrev} goNext={goNext} prevClass={prevProducts} nextClass={nextProducts} />
      {showAll && (
        <S.ShowcaseBtn to="#all" className="btn btn-primary">
          Все категории
          <Sprite icon="arrow" className="icon" />
        </S.ShowcaseBtn>
      )}
    </S.Showcase>
  );
};

export default ShowcaseCarousel;
