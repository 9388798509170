import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { RoutesLinks } from 'RoutesLinks';

import { useOnClickOutside } from 'shared/hooks/useOnClickOutside';

import rootStore from 'stores/RootStore';

import MiniCartItem from '../MiniCartItem';
import * as S from './styles';

const MiniCartBlock: FC = observer(() => {
  const { cartStore } = rootStore;
  const miniCartRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [attached, setAttached] = useState(false);

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    const isAddToCartButton = (event.target as HTMLElement).classList.contains('add-to-cart-button');
    if (miniCartRef.current && !miniCartRef.current.contains(event.target as Node) && !isAddToCartButton) {
      cartStore.setIsOpenedMiniCart(false);
    }
  };

  useOnClickOutside(miniCartRef, handleClickOutside, attached);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setAttached(true);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleNavigateToCart = () => {
    cartStore.setIsOpenedMiniCart(false);
    navigate(RoutesLinks.CART);
  };

  return (
    <S.Block ref={miniCartRef}>
      <S.MiniCartBlock>
        {cartStore.productsData.map((item) => (
          <MiniCartItem onClose={() => cartStore.setIsOpenedMiniCart(false)} key={item.id} item={item} />
        ))}
      </S.MiniCartBlock>
      <S.StyledButton onClick={handleNavigateToCart}>В корзину</S.StyledButton>
    </S.Block>
  );
});

export default MiniCartBlock;
