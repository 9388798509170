import { RefObject, useEffect } from 'react';

import { useLatest } from 'shared/hooks/useLatest';

/* Реагирует на клик или касание за пределами элемента и вызывает колбэк. */

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: MouseEvent | TouchEvent) => void,
  attached = true
) => {
  const latestHandler = useLatest(handler);

  useEffect(() => {
    if (!attached) return;

    const listener = (event: MouseEvent | TouchEvent) => {
      // Проверяем, что событие произошло за пределами элемента
      if (!ref.current || ref.current.contains(event.target as Node)) return;

      latestHandler.current?.(event);
    };

    // Подписываемся на события клика и касания
    document.addEventListener('click', listener);
    document.addEventListener('touchend', listener);

    // Функция для очистки подписок
    return () => {
      document.removeEventListener('click', listener);
      document.removeEventListener('touchend', listener);
    };
  }, [ref, latestHandler, attached]);
};
