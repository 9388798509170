import styled from 'styled-components';
import Button from 'ui-kit/Button';
import Sprite from 'ui-kit/Sprite';

import Total from 'components/Total';

import { pxToVw } from 'shared/helpers/pxToVw';

export const StyledTotal = styled(Total)`
  margin-left: ${pxToVw(24)};
`;

export const TotalBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${pxToVw(14)};
`;

export const BoldTitle = styled.div`
  font-size: ${pxToVw(14)};
  color: var(--text-secondary);
  font-weight: 700;
`;

export const Btn = styled(Button)`
  width: 100%;
  height: ${pxToVw(40)};
  padding: ${pxToVw(12)};
`;

export const WalletIcon = styled(Sprite)`
  width: ${pxToVw(21)};
  height: ${pxToVw(19)};
  margin-right: ${pxToVw(4)};
  fill: var(--white);
`;

export const BlueTitle = styled.div`
  font-size: ${pxToVw(12)};
  color: var(--text-secondary);
  margin-left: ${pxToVw(8)};
  white-space: nowrap;
  color: var(--primary);
  font-weight: 700;
`;
