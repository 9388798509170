import { ReactComponent as UserIcon } from 'assets/img/achievements/achievements-1-male.svg';
import styled from 'styled-components';
import Badge from 'ui-kit/Badge';

import { pxToVw } from 'shared/helpers/pxToVw';

export const UserProfileInfoBlock = styled.div`
  display: flex;
  background-color: var(--white);
  border-radius: ${pxToVw(16)};
  padding: ${pxToVw(24)};
  margin-bottom: ${pxToVw(32)};
`;

export const ImageBlock = styled.div`
  width: 50%;
  min-height: 100%;
`;

export const Image = styled(UserIcon)`
  width: 60%;
  height: 100%;
  margin: auto;
`;

export const ContentBlock = styled.div``;

export const AvatarWrapper = styled.div`
  display: flex;
`;

export const InfoBlock = styled.div`
  margin-left: ${pxToVw(12)};
`;

export const Title = styled.div`
  font-size: ${pxToVw(12)};
  font-weight: 700;
  color: var(--text-tertiary);
`;

export const BadgeWrapper = styled.div`
  margin-top: ${pxToVw(12)};
  margin-bottom: ${pxToVw(12)};
`;

export const StyledBadge = styled(Badge)`
  margin: ${pxToVw(1)};
`;
