import { FC } from 'react';

import * as S from './styles';

type ModalDeleteProduct = {
  onClick: () => void;
  onClose: () => void;
};

const ModalDeleteProduct: FC<ModalDeleteProduct> = ({ onClick, onClose }) => {
  const handleClick = () => {
    onClick();
    onClose();
  };

  return (
    <>
      <S.Text>Вы точно хотите удалить товар? Отменить данное действие будет невозможно.</S.Text>
      <S.StyledButton onClick={handleClick}>Удалить</S.StyledButton>
      <S.StyledButton onClick={onClose} variant="secondary">
        Отмена
      </S.StyledButton>
    </>
  );
};

export default ModalDeleteProduct;
