import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

export const TotalContainer = styled.div`
  display: block;
  position: sticky;
  top: ${pxToVw(100)};
  width: 30%;
  height: fit-content;
  padding: ${pxToVw(12)};
  background-color: var(--white);
  border-radius: ${pxToVw(16)};
  flex-shrink: 0;
`;

export const TotalBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${pxToVw(14)};
`;

export const BoldTitle = styled.div`
  font-size: ${pxToVw(14)};
  color: var(--text-secondary);
  font-weight: 700;
`;

export const Title = styled.div`
  font-size: ${pxToVw(12)};
  color: var(--text-secondary);
  margin-left: ${pxToVw(8)};
  white-space: nowrap;
`;

export const PriceBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Price = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: ${pxToVw(24)};
  line-height: 1;
  margin-right: auto;
  font-weight: 500;
  font-family: var(--font-family-loos);
`;

export const PriceOld = styled.del`
  font-weight: 700;
  color: var(--text-tertiary);
  font-family: var(--font-family-loos);
`;

export const BorderDash = styled.div`
  width: 100%;
  border-bottom: 2px dashed var(--stroke);
  margin: ${pxToVw(6)};
`;

export const PriceTitle = styled.div`
  font-size: ${pxToVw(12)};
  color: var(--text-secondary);
  white-space: nowrap;
`;

export const SaleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${pxToVw(14)};
`;
