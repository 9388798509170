import lamp from 'assets/img/decor/lamp-2.png';
import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

interface LoaderProps {
  size?: string;
  $position?: string;
  className?: string;
  $centered?: boolean;
}

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9; // Ниже, чем лоадер
  background-color: var(--text-primary);
  opacity: 0.5;
`;

export const LoaderContainer = styled.div<LoaderProps>`
  display: inline-flex;
  transform: translate(-50%, -50%);
  width: ${(props: LoaderProps) => (props.size === 'small' ? pxToVw(30) : pxToVw(70))};
  height: ${(props: LoaderProps) => (props.size === 'small' ? pxToVw(30) : pxToVw(70))};
  position: ${(props: LoaderProps) => (props.$centered ? 'fixed' : props.$position)};
  top: ${(props: LoaderProps) => (props.$centered ? '50%' : null)};
  left: ${(props: LoaderProps) => (props.$centered ? '50%' : null)};
  z-index: 10;
`;

export const LoaderDecor = styled.div<LoaderProps>`
  position: relative;
  display: inline-flex;
  width: ${pxToVw(116)};
  height: ${pxToVw(166)};
  margin: auto;
  background: url(${lamp}) no-repeat center;

  &::before {
    position: absolute;
    top: ${pxToVw(15)};
    left: 50%;
    width: ${pxToVw(93)};
    height: ${pxToVw(93)};
    background-color: rgb(255 0 0 / 71%);
    border-radius: 50%;
    box-shadow: 0 0 30px rgb(255 0 0 / 71%);
    transform: translateX(-50%);
    opacity: 0.4;
    filter: blur(20px);
    animation: bulb 5s cubic-bezier(0.4, 0, 1, 0.65) infinite;
    content: '';
  }
`;
