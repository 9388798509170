import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

export type IsDroppedProps = { isDropped?: boolean; isdropped?: boolean };

export const Block = styled.div`
  display: inline-block;
  vertical-align: middle;
  position: relative;
`;

export const Drop = styled.div<IsDroppedProps>`
  width: ${pxToVw(200)};
  padding: ${pxToVw(8)};
  background: var(--white);
  border-radius: 4px;
  position: absolute;
  z-index: 10;
  top: calc(100% - ${pxToVw(40)});
  left: ${pxToVw(-90)};
  box-shadow: 4px 4px 24px ${rgba('#747CA9', 0.16)};
  visibility: hidden;
  opacity: 0;
  transition: opacity 200ms ease, transform 200ms ease, visibility 200ms ease;
  word-wrap: break-word;

  ${(props: { isdropped: boolean }) =>
    props.isdropped &&
    css`
      visibility: visible;
      opacity: 1;
      transform: translateY(0) translateX(-50%);
    `}
  }
`;

export const Text = styled.div`
  padding: ${pxToVw(4)};
  font-weight: 700;
`;
