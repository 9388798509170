import { makeAutoObservable, runInAction } from 'mobx';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';

import api from 'stores/Api';

import { RootStore } from '../RootStore';

export interface BrandType {
  id: number;
  code: string;
  url: string;
  name: string;
  image: string;
}

export interface BrandDataType {
  data: BrandType[];
}

class TopBrandsStore {
  kiosk = localStorage.getItem('kiosk') || 'default';
  data: BrandType[] | null = null;
  dataState: IPromiseBasedObservable<BrandType[] | null> | null = null;
  error: Error | null = null;

  constructor(private rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  async loadData() {
    try {
      const response = await api.get<BrandDataType>(`brands?kiosk=${this.kiosk}`);
      runInAction(() => {
        this.data = response.data.data;
        this.dataState = fromPromise(Promise.resolve(this.data));
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('Неизвестная ошибка');
        this.dataState = fromPromise(Promise.reject(null));
      });
    }
  }

  get errorMessage() {
    return this.error ? this.error.message : null;
  }

  get isLoading() {
    return this.dataState?.state === 'pending';
  }

  get isError() {
    return this.dataState?.state === 'rejected';
  }
}

export default TopBrandsStore;
