import { FC, useEffect, useRef, useState } from 'react';
import { GetHandleProps, GetTrackProps, Handles, Rail, SliderItem, Tracks } from 'react-compound-slider';

import CheckboxStore from 'stores/common/CheckboxStore';

import * as S from './styles';

function Track({
  source,
  target,
  getTrackProps,
}: {
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
}) {
  return (
    <S.Track
      style={{
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  );
}

function Handle({ handle: { id, percent }, getHandleProps }: { handle: SliderItem; getHandleProps: GetHandleProps }) {
  return (
    <S.Handler
      style={{
        left: `${percent}%`,
      }}
      {...getHandleProps(id)}
    />
  );
}

type FilterRangeProps = {
  className?: string;
  values: CheckboxStore[];
  selectedValues: string[];
  name: string;
  track?: boolean;
  onChange: (values: number[]) => void;
};

export const FilterRange: FC<FilterRangeProps> = ({
  onChange,
  className,
  values,
  selectedValues,
  name,
  track = true,
}) => {
  const step = 1;
  const [localValues, setLocalValues] = useState<number[]>([]);
  const [domain, setDomain] = useState<{ min: number; max: number }>({ min: 0, max: 0 });
  const sliderRef = useRef<HTMLDivElement>(null);

  const handleChange = (e: number[]) => {
    onChange(e);
  };

  useEffect(() => {
    if (values && values.length >= 2) {
      const min = Number(values[0]);
      const max = Number(values[1]);
      setDomain({ min, max });

      if (selectedValues.length === 2) {
        setLocalValues([Number(selectedValues[0]), Number(selectedValues[1])]);
      } else {
        setLocalValues([min, max]);
      }
    }
  }, [values, selectedValues]);

  const onUpdate = ([min, max]: number[]) => {
    setLocalValues([min, max]);
  };

  if (!values || values.length < 2) {
    return null;
  }

  return (
    <S.Block className={className} ref={sliderRef}>
      <S.Title>{name}</S.Title>
      <S.Inputs>
        <S.Input value={localValues[0]} readOnly type="text" />

        <S.Input value={localValues[1]} readOnly type="text" />
      </S.Inputs>

      {track && (
        <S.StyledSlider
          domain={[domain.min, domain.max]}
          values={localValues}
          step={step}
          mode={3}
          onSlideEnd={handleChange}
          onUpdate={onUpdate}
        >
          <Rail>{({ getRailProps }) => <S.Rail {...getRailProps()} />}</Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div>
                {handles.map((handle) => (
                  <Handle key={handle.id} handle={handle} getHandleProps={getHandleProps} />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                ))}
              </div>
            )}
          </Tracks>
        </S.StyledSlider>
      )}
    </S.Block>
  );
};
