import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';
import Sprite from 'ui-kit/Sprite';

import { Container } from 'components/Container/styles';

import { hexToRgb } from 'shared/helpers/hexToRgb';
import { pxToVw } from 'shared/helpers/pxToVw';
import { colors } from 'shared/helpers/vars';
import { ClassNameProps } from 'shared/types/ClassNameProps';

export const NavBar = styled(Container)`
  margin-top: ${pxToVw(-28)};
  margin-bottom: ${pxToVw(36)};

  .prev,
  .next {
    background-color: rgba(${hexToRgb(colors.bgLight)}, 0.7);
  }
`;

export const NavBarInner = styled.div<ClassNameProps>`
  position: relative;
`;

export const NavBarItem = styled(SwiperSlide)`
  width: auto;
`;

export const NavBarLink = styled(Link)`
  padding-top: ${pxToVw(10)};
  padding-bottom: ${pxToVw(10)};
  color: var(--body-color);
  font-weight: 700;
  display: flex;
  align-items: center;
`;

export const NavBarLinkIcon = styled(Sprite)`
  width: ${pxToVw(24)};
  height: ${pxToVw(24)};
  margin-right: ${pxToVw(8)};
  fill: var(--warn);
`;
