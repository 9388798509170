import api from 'stores/Api';

export const getFavoritesApi = async () => {
  const res = await api.get(`favourites`);
  return res;
};

export const addProductToFavoritesApi = async (id: string) => {
  const res = await api.post(`favourites/add`, { id });
  return res;
};

export const deleteProductFromFavoritesApi = async (id: string) => {
  const res = await api.post(`favourites/remove`, { id });
  return res;
};
