import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { RoutesLinks } from 'RoutesLinks';
import Loader from 'ui-kit/Loader';

import rootStore from 'stores/RootStore';

import CartProductItem from '../CartProductItem';
import * as S from './styles';

const CartProductsContainer = observer(() => {
  const { cartStore } = rootStore;
  const { authStore } = rootStore;

  return (
    <S.CartProductsContainer>
      {cartStore.isLoading && <Loader centered />}
      <Helmet>
        <title>{cartStore.productsData.length ? 'Оформите заказ' : 'Корзина пуста'}</title>
      </Helmet>

      {!cartStore.productsData.length && (
        <>
          {!authStore.isAuth && (
            <p>Войдите в аккаунт, чтобы просмотреть товары, добавленные в&nbsp;корзину при прошлом визите.</p>
          )}
          <p>
            На{' '}
            <Link to={RoutesLinks.HOME} className="l-link">
              главной&nbsp;странице
            </Link>{' '}
            представлены акции и&nbsp;специальные предложения, которые помогут подобрать нужные товары.
          </p>
        </>
      )}

      {cartStore.productsData.map((item) => (
        <CartProductItem key={item.id} data={item} />
      ))}
    </S.CartProductsContainer>
  );
});

export default CartProductsContainer;
