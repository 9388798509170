import { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import SideBox from 'modules/Product/components/SideBox';
import Loader from 'ui-kit/Loader';

import CardHorizontal from 'components/CardHorizontal';

import rootStore from 'stores/RootStore';

const ProductServices: FC = observer(() => {
  const { productDetailsStore, servicesProductStore } = rootStore;
  const productId = productDetailsStore.productId;

  useEffect(() => {
    if (productId) {
      servicesProductStore.postProductServicesData(productId);
    }
  }, [productId, servicesProductStore]);

  if (servicesProductStore.loading) return <Loader position="static" />;

  if (!servicesProductStore.services.length) return null;

  return (
    <SideBox title="Услуги" icon="settings">
      {servicesProductStore.services.map((service) => (
        <CardHorizontal key={service.id} data={service} />
      ))}
    </SideBox>
  );
});

export default ProductServices;
