import { FC } from 'react';

import ProductCard from 'components/ProductCard';

import * as S from 'shared/helpers/generalStyles';
import { ProductType } from 'shared/types/ProductType';

type GoodsTypes = {
  data: ProductType[];
};

const Goods: FC<GoodsTypes> = ({ data }) => {
  return (
    <div className="d-flex flex-wrap mt-5">
      <h2 className="w-100 mb-4">Акционные товары</h2>

      <S.Row>
        {data.map((instance) => (
          <S.Card key={instance.id}>
            <ProductCard data={instance} />
          </S.Card>
        ))}
      </S.Row>
    </div>
  );
};

export default Goods;
