import { FC } from 'react';

import { IProperty } from 'stores/SaleStore';

import * as S from './styles';

interface DataTypes {
  data: IProperty;
}

const WhereItWork: FC<DataTypes> = ({ data }) => {
  return (
    <>
      Где действует:
      <ul className="list-unstyled">
        {data?.list
          ? data?.list.map((element, index) => (
              <S.Item key={index}>
                <S.ItemIcon icon="check" className="icon" />
                {element.value}
              </S.Item>
            ))
          : ''}
      </ul>
    </>
  );
};

export default WhereItWork;
