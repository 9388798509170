import { FC } from 'react';
import { IUserProfile } from 'entities/Authorization/types';
import Avatar from 'ui-kit/Avatar';
import SectionHeader from 'ui-kit/SectionHeader';

import { formatPhoneNumber } from 'shared/helpers/formatPhoneNumber';

import * as S from './styles';

type UserProfileInfoProps = {
  userInfo: IUserProfile | null;
};

const UserProfileInfo: FC<UserProfileInfoProps> = ({ userInfo }) => {
  if (!userInfo) {
    return null;
  }
  return (
    <S.UserProfileInfoBlock>
      <S.ImageBlock>
        <S.Image />
      </S.ImageBlock>
      <S.ContentBlock>
        <SectionHeader showAll={false} pageHeadingTitle="Привет!" />

        <S.AvatarWrapper>
          <Avatar title={userInfo.name} />
          <S.InfoBlock>
            <S.Title>{userInfo.personal_phone ? formatPhoneNumber(userInfo.personal_phone) : ''}</S.Title>
            <S.Title>{userInfo.email}</S.Title>
          </S.InfoBlock>
        </S.AvatarWrapper>

        <S.BadgeWrapper>
          <S.StyledBadge color="primary" size="small">
            Мега
          </S.StyledBadge>
          <S.StyledBadge color="primary" size="small">
            Царь технологий
          </S.StyledBadge>
        </S.BadgeWrapper>

        <S.Title>- Возможность оплатить до 30% смарт-рублями</S.Title>
        <S.Title>- Обмен и возврат товара доступен 20 дней</S.Title>
      </S.ContentBlock>
    </S.UserProfileInfoBlock>
  );
};

export default UserProfileInfo;
