import { Slider } from 'react-compound-slider';
import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

export const Block = styled.div`
  margin: ${pxToVw(8)};
`;

export const Inputs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${pxToVw(16)};
  margin-bottom: ${pxToVw(22)};
`;

export const Input = styled.input`
  width: 100%;
  border-radius: ${pxToVw(8)};
  height: ${pxToVw(52)};
  padding: ${pxToVw(16)};
  border: ${pxToVw(1)} solid var(--blue-hover);
  color: var(--text-tertiary);
  font-size: ${pxToVw(16)};
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StyledSlider = styled(Slider as any)`
  position: relative;
  width: calc(100% - ${pxToVw(8)});
  height: ${pxToVw(12)};
  margin-top: ${pxToVw(12)};
`;

export const Track = styled.div`
  position: absolute;
  height: 100%;
  z-index: 1;
  cursor: pointer;
  border-radius: ${pxToVw(2)};
  background-color: var(--primary);
`;

export const Title = styled.div`
  color: var(--primary);
  font-weight: 700;
  margin-bottom: ${pxToVw(8)};
  font-size: ${pxToVw(18)};
  line-height: 1.2;
`;

export const Handler = styled.div`
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  border: ${pxToVw(2)} solid var(--primary);
  background-color: var(--white);
  color: #111827;
  position: absolute;
  top: ${pxToVw(-7)};
  right: ${pxToVw(-17)};
  height: ${pxToVw(25)};
  width: ${pxToVw(25)};
  margin-left: ${pxToVw(-6)};
  margin-top: 0;
  z-index: 2;
  transition: border-color 0.3s;

  &:hover {
    border-color: #2563eb;
  }
`;

export const Rail = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: ${pxToVw(2)};
  background-color: #ededed;
  box-shadow: none;
`;
