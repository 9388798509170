import { fetchMainProductApi } from 'entities/Product/services/productApi';
import { makeAutoObservable, runInAction } from 'mobx';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';

import { CostParamsType, PreviewPropertyType } from 'shared/types/ProductType';

import { RootStore } from 'stores/RootStore';

export interface ElementsType {
  id: number;
  name: string;
  preview_picture: string;
  url: string;
  cost_params: CostParamsType;
  preview_properties?: PreviewPropertyType[];
}

export interface ProductCategoriesType {
  id: number;
  name: string;
  code: string;
  url: string;
  image: string;
  preview_picture: string;
  elements: ElementsType[];
}

export interface ProductCategoriesResponseType {
  data: ProductCategoriesType;
}

class ProductCategoriesStore {
  kiosk = localStorage.getItem('kiosk') || 'default';
  data: ProductCategoriesType | null = null;
  dataState: IPromiseBasedObservable<ProductCategoriesType> | null = null;
  error: Error | null = null;

  constructor(private rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  async loadData() {
    try {
      const response = await fetchMainProductApi(this.kiosk);
      runInAction(() => {
        this.data = response.data.data;
        this.dataState = fromPromise(Promise.resolve(this.data));
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('Неизвестная ошибка');
        throw error;
      });
    }
  }

  get errorMessage() {
    return this.error ? this.error.message : null;
  }

  get isLoading() {
    return this.dataState?.state === 'pending';
  }

  get isError() {
    return this.dataState?.state === 'rejected';
  }
}

export default ProductCategoriesStore;
