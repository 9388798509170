import { Params } from 'react-router-dom';

import rootStore from 'stores/RootStore';

export const productLoader = async ({ params }: { params: Params<string> }) => {
  const productUrl = params.productUrl;

  if (!productUrl) return;

  const { productDetailsStore } = rootStore;

  await productDetailsStore.loadData(productUrl);

  return { productUrl };
};
