import { toast } from 'react-toastify';
import {
  addProductToCartApi,
  deleteProductFromCartApi,
  fetchProductsFromCartApi,
} from 'entities/Cart/services/cartApi';
import { ProductTypeFromCart } from 'entities/Cart/types';
import { makeAutoObservable, runInAction } from 'mobx';

import { IFormattedProductFromOffers, ProductType, totalProps } from 'shared/types/ProductType';

import { RootStore } from 'stores/RootStore';

import CartProductItemStore from './CartProductItemStore';

class CartStore {
  productsData: ProductTypeFromCart[] = [];
  total: totalProps = { cost: 0, costOld: 0, quantity: 0 };
  isOpenMiniCart = false;
  isLoading = false;
  error: Error | null = null;
  isOpenThankYouModal = false;

  constructor(private readonly rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  fetchProductsFromCart = async () => {
    if (this.rootStore.authStore.isAuth) {
      this.fetchProductsFromCartInAuth();
    } else {
      try {
        const offers: string = JSON.parse(localStorage.getItem('cart') || '');
        const response = await fetchProductsFromCartApi(offers);
        const data = response.data.data;
        if (data.error) {
          this.error = new Error(data.error);
          return;
        }
        runInAction(() => {
          this.productsData = data.products.map(
            (item: ProductTypeFromCart) => new CartProductItemStore(item, this.rootStore)
          );
          this.getTotalFromCart(data.products);
        });
      } catch (error) {
        this.error = error instanceof Error ? error : new Error('An unexpected error occurred');
      }
    }
  };

  fetchProductsFromCartInAuth = async () => {
    try {
      this.isLoading = true;
      const response = await fetchProductsFromCartApi();
      const data = response.data.data;
      if (data.error) {
        this.error = new Error(data.error);
        this.isLoading = false;
        return;
      }
      runInAction(() => {
        this.productsData = data.products.map(
          (item: ProductTypeFromCart) => new CartProductItemStore(item, this.rootStore)
        );
        this.getTotalFromCart(data.products);
        this.isLoading = false;
      });
    } catch (error) {
      this.error = error instanceof Error ? error : new Error('An unexpected error occurred');
      this.isLoading = false;
    }
  };

  addProductToCart = async (productCard: ProductType): Promise<boolean> => {
    let isAddToCart = false;
    if (!this.isFirstProduct(productCard.id)) {
      toast.error('Товар уже добавлен в корзину');
      return isAddToCart;
    }
    if (this.rootStore.authStore.isAuth) {
      isAddToCart = await this.addProductToCartInAuth(productCard);
    } else {
      if (productCard.cost_params?.in_stock === 0) {
        toast.error('Товара нет в наличии');
        isAddToCart = false;
      } else {
        isAddToCart = true;
        this.setIsOpenedMiniCart(true);
        this.productsData.push(new CartProductItemStore(productCard, this.rootStore));
        this.getTotalFromCart(this.productsData);
        this.formattedProductsFromOffers(this.productsData);
      }
    }

    return isAddToCart;
  };

  addProductToCartInAuth = async (productCard: ProductType): Promise<boolean> => {
    let isAddToCart = false;
    this.isLoading = true;
    try {
      const response = await addProductToCartApi(String(productCard.id));
      const data = response.data.data;
      if (data.error) {
        isAddToCart = false;
        this.isLoading = false;
        toast.error(data.error);
        this.error = new Error(data.error);
        return false;
      }
      runInAction(() => {
        isAddToCart = true;
        this.isLoading = false;
        this.setIsOpenedMiniCart(true);
        this.productsData.push(new CartProductItemStore(productCard, this.rootStore));
      });
    } catch (error) {
      this.isLoading = false;
      this.error = error instanceof Error ? error : new Error('An unexpected error occurred');
      isAddToCart = false;
    }

    return isAddToCart;
  };

  isFirstProduct = (id: number) => {
    return !this.productsData.some((item) => item.id === id);
  };

  deleteProductFromCart = (productCard: ProductTypeFromCart) => {
    if (this.rootStore.authStore.isAuth) {
      this.deleteProductFromCartInAuth(productCard);
    } else {
      const filterData = this.productsData.filter((item: ProductType) => item.id !== productCard.id);
      runInAction(() => {
        this.productsData = filterData;
        this.getTotalFromCart(filterData);
        this.formattedProductsFromOffers(filterData);
      });
    }
  };

  deleteProductFromCartInAuth = async (productCard: ProductTypeFromCart) => {
    try {
      this.isLoading = true;
      const response = await deleteProductFromCartApi(String(productCard.id));
      const data = response.data.data;
      if (data.error) {
        this.isLoading = false;
        this.error = new Error(data.error);
        return;
      }
      runInAction(() => {
        this.isLoading = false;
        this.productsData = data.products.map(
          (item: ProductTypeFromCart) => new CartProductItemStore(item, this.rootStore)
        );
        this.getTotalFromCart(data.products);
      });
    } catch (error) {
      this.isLoading = false;
      this.error = error instanceof Error ? error : new Error('An unexpected error occurred');
    }
  };

  getTotalFromCart = (productsData: ProductTypeFromCart[]) => {
    const total = productsData.reduce(
      (acc: totalProps, value) => {
        acc['quantity'] += value.quantity;
        acc['cost'] += (value.cost_params?.cost || 0) * value.quantity;
        acc['costOld'] += (value.cost_params?.old_cost || 0) * value.quantity;
        return acc;
      },
      { cost: 0, costOld: 0, quantity: 0 }
    );

    runInAction(() => {
      this.total = total;
    });
  };

  setIsOpenedMiniCart = (isOpen: boolean) => {
    this.isOpenMiniCart = isOpen;
  };

  formattedProductsFromOffers = (productsData: ProductTypeFromCart[]) => {
    const formatted = productsData.reduce((acc, value, index) => {
      return { ...acc, [index]: { id: value.id, quantity: value.quantity } };
    }, {});
    localStorage.setItem('cart', JSON.stringify(formatted));
  };

  formattedProductQuantity = (productCard: ProductTypeFromCart, quantity: number) => {
    const offers: IFormattedProductFromOffers[] = Object.values(JSON.parse(localStorage.getItem('cart') || ''));

    offers.forEach((item: IFormattedProductFromOffers) => {
      if (item.id === productCard.id) {
        item.quantity = quantity;
      }
    });

    localStorage.setItem('cart', JSON.stringify(offers));
  };

  openThankYouModal = () => {
    this.isOpenThankYouModal = true;
  };

  closeThankYouModal = () => {
    this.isOpenThankYouModal = false;
  };
}

export default CartStore;
