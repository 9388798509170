import styled from 'styled-components';
import Sprite from 'ui-kit/Sprite';

import { pxToVw } from 'shared/helpers/pxToVw';

export const Item = styled.li`
  margin-top: ${pxToVw(8)};
  padding-left: ${pxToVw(32)};
  color: var(--text-secondary);
  position: relative;
  font-weight: 700;
`;

export const ItemIcon = styled(Sprite)`
  width: ${pxToVw(16)};
  height: ${pxToVw(16)};
  fill: var(--text-secondary);
  position: absolute;
  left: 0;
  top: 2px;
`;
