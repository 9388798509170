import { FC, ReactNode } from 'react';

import * as S from './styles';

type CardStickerItemProps = { children: ReactNode };

const CardStickerItem: FC<CardStickerItemProps> = ({ children }) => {
  return <S.CardStickerItem>{children}</S.CardStickerItem>;
};

export default CardStickerItem;
