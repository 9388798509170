import styled from 'styled-components';
import Button from 'ui-kit/Button';

import { pxToVw } from 'shared/helpers/pxToVw';

export const HeaderBasket = styled(Button)`
  width: ${pxToVw(87)};
  height: ${pxToVw(52)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  .icon {
    width: ${pxToVw(24)};
    height: ${pxToVw(24)};
    fill: var(--white);
  }
`;

export const Block = styled.div`
  position: absolute;
  right: ${pxToVw(60)};
  top: ${pxToVw(80)};
`;

export const MiniCartBlock = styled.div`
  width: ${pxToVw(180)};
  max-height: ${pxToVw(200)};
  overflow-x: hidden;
`;

export const MainBlock = styled.div`
  position: relative;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  font-size: ${pxToVw(12)};
`;
