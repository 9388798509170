import styled, { keyframes } from 'styled-components';
import Button from 'ui-kit/Button';
import Input from 'ui-kit/Input';
import Sprite from 'ui-kit/Sprite';

import { pxToVw } from 'shared/helpers/pxToVw';

const buttonClickAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(0.9); }
  100% { transform: scale(1); }
`;

export const BasketCardAmount = styled.div`
  display: flex;
`;

export const StyledInputNumber = styled(Input)`
  width: ${pxToVw(48)};
  height: ${pxToVw(32)};
  margin-right: ${pxToVw(1)};
  margin-left: ${pxToVw(1)};
  padding-right: ${pxToVw(2)};
  padding-left: ${pxToVw(2)};
  font-weight: 700;
  border-radius: 14px;
  text-align: center;
  color: var(--text-secondary);
  background-color: var(--color-field-bg);
  border: none;
`;

export const StyledAddButton = styled(Button)<{ color: string }>`
  display: flex;
  width: ${pxToVw(32)};
  height: ${pxToVw(32)};
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background: ${(props: { color: string }) => (props.color === 'blue' ? 'var(--primary)' : 'var(--color-field-bg)')};

  &:active {
    animation: ${buttonClickAnimation} 0.3s ease;
  }
`;

export const MinusIcon = styled(Sprite)<{ color: string; icon: string }>`
  width: ${pxToVw(21)};
  height: ${pxToVw(19)};
  fill: ${(props: { color: string }) => (props.color === 'white' ? props.color : 'var(--text-disabled)')};
`;

export const PlusIcon = styled(Sprite)<{ icon: string }>`
  width: ${pxToVw(21)};
  height: ${pxToVw(19)};
  fill: ${(props: { color: string }) => (props.color === 'white' ? props.color : 'var(--text-disabled)')};
`;
