import { FC } from 'react';

import * as S from './styles';

type InputNumberProps = {
  value: number;
  maxValue: number;
  isLoading: boolean;
  onIncrease: (value: number) => void;
  onReduce: (value: number) => void;
};

const InputNumber: FC<InputNumberProps> = ({ value, maxValue, onIncrease, onReduce, isLoading }) => {
  const handleIncreaseQuantity = () => {
    if (maxValue === 0 || maxValue === value) {
      return;
    }
    onIncrease(value + 1);
  };

  const handleDecreaseQuantity = () => {
    if (value <= 0) {
      return;
    }
    onReduce(value - 1);
  };

  return (
    <S.BasketCardAmount>
      <S.StyledAddButton disabled={isLoading} onClick={handleDecreaseQuantity} color={value > 1 ? 'blue' : ''}>
        <S.MinusIcon icon="minus" color={value > 1 ? 'white' : ''} />
      </S.StyledAddButton>
      <S.StyledInputNumber value={value} readOnly />
      <S.StyledAddButton disabled={isLoading} color={value === maxValue ? '' : 'blue'} onClick={handleIncreaseQuantity}>
        <S.PlusIcon icon="plus" color={value === maxValue ? '' : 'white'} />
      </S.StyledAddButton>
    </S.BasketCardAmount>
  );
};

export default InputNumber;
