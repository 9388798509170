import styled from 'styled-components';
import Sprite from 'ui-kit/Sprite';

import { pxToVw } from 'shared/helpers/pxToVw';

export const Toggle = styled.div`
  width: 100%;
  color: var(--primary);
  display: inline-flex;
  align-items: center;
  font-weight: 700;
`;

export const Icon = styled(Sprite)<{ isVisible: boolean }>`
  width: ${pxToVw(16)};
  height: ${pxToVw(16)};
  margin-left: ${pxToVw(8)};
  fill: var(--primary);
  transform: rotate(${(props: { isVisible: boolean }) => (props.isVisible ? '-' : '')}90deg);
`;
