import styled from 'styled-components';
import Button from 'ui-kit/Button';

import { pxToVw } from 'shared/helpers/pxToVw';
import { ClassNameProps } from 'shared/types/ClassNameProps';

export const HeaderAccount = styled.div<ClassNameProps>`
  width: ${pxToVw(32)};
  margin-right: ${pxToVw(16)};
  margin-left: ${pxToVw(16)};
  flex-shrink: 0;
  position: relative;
`;

export const HeaderAccountAvatar = styled.div<ClassNameProps>`
  padding: 0;
  display: flex;

  &::after {
    opacity: 0;
  }
`;

export const StyledButton = styled(Button)`
  padding-left: ${pxToVw(16)};
  padding-right: ${pxToVw(16)};
  margin-left: ${pxToVw(8)};
  background: transparent;
  border-color: #f0c74f;
`;

export const Block = styled.div``;
