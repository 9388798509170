import styled from 'styled-components';
import Sprite from 'ui-kit/Sprite';

import { pxToVw } from 'shared/helpers/pxToVw';

export const ThankYouModal = styled.div`
  padding-top: ${pxToVw(32)};
  padding-bottom: ${pxToVw(32)};
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  color: var(--white);
  background-color: var(--primary);
  // display: none;
`;

export const ThankYouImg = styled.img`
  margin-bottom: ${pxToVw(32)};
`;

export const ThankYouText = styled.p`
  color: inherit;
  margin: 0 auto;
  font-size: ${pxToVw(20)};
`;

export const ThankYouClose = styled.div`
  width: ${pxToVw(42)};
  height: ${pxToVw(42)};
  background-color: var(--white);
  font-size: ${pxToVw(20)};
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
`;

export const ThankYouCloseIcon = styled(Sprite)`
  width: ${pxToVw(24)};
  height: ${pxToVw(24)};
  margin: auto;
  fill: var(--primary);
`;
