import { FC, FormEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';

import rootStore from 'stores/RootStore';

import * as S from '../../styles';
import AuthHeader from '../AuthHeader';

interface CodeSentProps {
  closeButton?: boolean;
}

const Login: FC<CodeSentProps> = observer(({ closeButton = true }) => {
  const { authStore } = rootStore;
  const [value, setValue] = useState<string | undefined>();

  const handlePhoneChange = async (numberPhone: string): Promise<void> => {
    const e164Number = '+7' + numberPhone.replace(/\D/g, '').slice(1).trim();
    setValue(e164Number);

    if (e164Number.length === 12) {
      const phone = e164Number.slice(1);

      try {
        await authStore.login(phone);
        toast.success(`На номер ${e164Number} отправлен смс код`);
        authStore.handleCodeSent(phone);
      } catch (error) {
        console.error('Ошибка при отправке номера телефона', error);
        toast.error(`Ошибка с номером ${value}: ${error}`);
      }
    }
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    toast.error(`Вы не указали номер телефона`);
    return false;
  };

  return (
    <>
      <AuthHeader closeButton={closeButton} title="Вход или регистрация" />

      <form onSubmit={onSubmit}>
        <S.ModalInput
          name="phone"
          mask="+{7} 000 000-00-00"
          autoComplete="off"
          lazy={false}
          unmask={true}
          onAccept={(value: string) => handlePhoneChange(value)}
          overwrite={true}
          placeholder="+7 ___ ___-__-__"
        />
      </form>
    </>
  );
});

export default Login;
