import fetchCatalogElementsApi from 'entities/FilterCatalog/services/catalogElementsApi';
import { isEqual } from 'lodash';
import { makeAutoObservable, reaction, runInAction } from 'mobx';

import { PaginationData } from 'shared/types/PaginationData';
import {
  BaseFilter,
  IFiltersStoreType,
  IFiltersType,
  IFiltersTypeWithRange,
  ProductType,
} from 'shared/types/ProductType';

import { RootStore } from 'stores/RootStore';

import FilterCheckboxStore from './Filters/FilterCheckboxStore';
import FilterRangeStore from './Filters/FilterRangeStore';

export type SortByProps = {
  sort: string;
  label: string;
  direction: 'ASC' | 'DESC';
};

export type toServerFormatState = {
  [key: string]: string[];
};

class FilterCatalogStore {
  catalogData: ProductType[] = [];
  currentPage: number = 1;
  pagination: PaginationData | null = null;
  filtersData: IFiltersStoreType[] = [];
  sortBy: SortByProps = { sort: 'popular', direction: 'ASC', label: 'Сначала популярные' };
  error: Error | null = null;
  isLoading: boolean = false;
  path: string = '';
  query: string = '';
  toServerFormatState: toServerFormatState = {};
  isUpdating: boolean = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);

    reaction(
      () => this.sortBy,
      () => {
        this.fetchProductsWithFilter(this.path);
      }
    );

    reaction(
      () => this.toServerFormat,
      (newState, prevState) => {
        if (!isEqual(newState, prevState) && !this.isUpdating) {
          this.isUpdating = true;
          this.currentPage = 1;
          runInAction(() => {
            this.toServerFormatState = newState;
          });
          this.fetchProductsWithFilter(this.path).finally(() => {
            this.isUpdating = false;
          });
        }
      }
    );
  }

  resetFilters = () => {
    if (this.filtersData) {
      this.filtersData.forEach((item: IFiltersStoreType) => {
        item.reset();
      });
    }
  };

  fetchProductsWithFilter = async (path: string) => {
    if (!path) {
      console.error('Path is required');
      return;
    }

    const secondSlashIndex = path.indexOf('/', path.indexOf('/') + 1);
    if (secondSlashIndex === -1) {
      console.error('Invalid path format');
      return;
    }

    const trimmedUrl = path.substring(secondSlashIndex).replace(/\/$/, '');
    this.setIsLoading(true);

    try {
      const response = await fetchCatalogElementsApi(
        path,
        trimmedUrl,
        this.sortBy.sort,
        this.sortBy.direction,
        this.toServerFormat,
        this.currentPage
      );

      const data = response.data.data;

      if (!data.elements) {
        throw new Error('No elements found in response data');
      }

      runInAction(() => {
        this.pagination = response.data.meta;
        this.catalogData =
          this.currentPage > 1 && this.catalogData ? [...this.catalogData, ...data.elements] : data.elements;
        if (data.filter) {
          this.filtersData = data.filter.map((item: IFiltersType) => this.formattedFilterWithDisplayType(item));
        }
        this.setIsLoading(false);
      });
    } catch (error) {
      runInAction(() => {
        this.setIsLoading(false);
        this.error = error instanceof Error ? error : new Error('An unexpected error occurred');
      });
      console.error('Error fetching products:', error);
    }
  };

  setSortBy = (params: SortByProps) => {
    runInAction(() => {
      this.currentPage = 1;
      this.sortBy = { ...params };
    });
  };

  setIsLoading = (value: boolean) => {
    runInAction(() => {
      this.isLoading = value;
    });
  };

  setCurrentPage = (value: number) => {
    if (this.isLoading) {
      return;
    }
    if (this.pagination && value > this.pagination.last_page) {
      return;
    }
    runInAction(() => {
      this.currentPage = value;
      this.fetchProductsWithFilter(this.path);
    });
  };

  updatePath(path: string, query: string) {
    runInAction(() => {
      this.setIsLoading(true);
      this.filtersData = [];
      this.catalogData = [];
      this.path = path;
      this.currentPage = 1;
      this.query = query;

      this.fetchProductsWithFilter(path);
    });
  }

  formattedFilterWithDisplayType = (item: BaseFilter) => {
    switch (item.display_type) {
      case 'B':
        return new FilterRangeStore(item as IFiltersTypeWithRange, this.rootStore);
      case 'F':
      case 'K':
        return new FilterCheckboxStore(item as IFiltersType, this.rootStore);
      default:
        return new FilterCheckboxStore(item as IFiltersType, this.rootStore);
    }
  };

  get toServerFormat() {
    const filters = this.filtersData?.reduce((acc: { [key: string]: string[] }, filter: IFiltersStoreType) => {
      if (filter.selectedValues.length <= 0) {
        return acc;
      }
      return {
        ...acc,
        [filter.code]: filter.selectedValues,
      };
    }, {});

    return filters || {};
  }
}

export default FilterCatalogStore;
