import { toJS } from 'mobx';

import { ExtractedPropertiesType, PreviewPropertyType, ProductType } from 'shared/types/ProductType';

export const extractPreviewProperties = (data: ProductType): ExtractedPropertiesType => {
  const properties: ExtractedPropertiesType = {
    countReviews: 0,
    rating: 0,
    isNew: false,
  };

  if (data.preview_properties) {
    if (Array.isArray(toJS(data.preview_properties))) {
      toJS(data.preview_properties).forEach((property: PreviewPropertyType) => {
        if (property.code === 'RATING') properties.rating = property.value_enum;
        switch (property.code) {
          case 'COUNT_REVIEWS':
            properties.countReviews = property.value_enum;
            break;
          case 'RATING':
            properties.rating = property.value;
            break;
          case 'NEW':
            properties.isNew = true;
            break;
          default:
        }
      });
    }
  }

  return properties;
};
