import { css } from 'styled-components';

type PlaceholderProps = {
  color?: string;
  fontSize?: string;
  opacity?: string;
  transition?: string;
};

export const hidePlaceholder = ({ color, fontSize, opacity, transition }: PlaceholderProps) => css`
  &::placeholder {
    ${color && `color: ${color};`}
    ${fontSize && `font-size: ${fontSize};`}
    ${opacity && `opacity: ${opacity};`}
    ${transition && `transition: ${transition};`}
  }
`;
