import AuthStore from './AuthStore';
import BrandsListStore from './brands/BrandsListStore';
import BrandStore from './brands/BrandStore';
import TopBrandsStore from './brands/TopBrandsStore';
import BreadcrumbsStore from './BreadcrumbsStore';
import CartStore from './cart/CartStore';
import CatalogShowcaseStore from './catalog/CatalogShowcaseStore';
import CatalogStore from './catalog/CatalogStore';
import FilterCatalogStore from './catalog/FilterCatalogStore';
import CompareStore from './compare/CompareStore';
import FastTagStore from './FastTagStore';
import FavoritesStore from './favorite/FavoriteStore';
import MenuStore from './MenuStore';
import ModalStore from './ModalStore';
import NavBarStore from './NavBarStore';
import BaseProductPropertiesStore from './products/BaseProductPropertiesStore';
import NewProductStore from './products/NewProductStore';
import PopularInThisCategoryStore from './products/PopularInThisCategoryStore';
import PopularProductStore from './products/PopularProductStore';
import ProductCategoriesStore from './products/ProductCategoriesStore';
import ProductDetailsStore from './products/ProductDetailsStore';
import ProductFeedbackStore from './products/ProductFeedbackStore';
import ProductTradeOfferStore from './products/ProductTradeOfferStore';
import RecommendedProductStore from './products/RecommendedProductStore';
import ServicesProductStore from './products/ServicesProductStore';
import TopProductPropertiesStore from './products/TopProductPropertiesStore';
import ViewedProductsStore from './products/ViewedProductsStore';
import PromotionsStore from './PromotionsStore';
import SaleStore from './SaleStore';
import SearchBarStore from './SearchBarStore';
import SearchPreviewStore from './SearchPreviewStore';

export class RootStore {
  authStore: AuthStore;
  brandsListStore: BrandsListStore;
  catalogShowcaseStore: CatalogShowcaseStore;
  catalogStore: CatalogStore;
  menuStore: MenuStore;
  cartStore: CartStore;
  favoritesStore: FavoritesStore;
  compareStore: CompareStore;
  breadcrumbsStore: BreadcrumbsStore;
  fastTagStore: FastTagStore;
  recommendedProductStore: RecommendedProductStore;
  baseProductPropertiesStore: BaseProductPropertiesStore;
  newProductStore: NewProductStore;
  navBarStore: NavBarStore;
  topBrandsStore: TopBrandsStore;
  brandStore: BrandStore;
  topProductPropertiesStore: TopProductPropertiesStore;
  filterCatalogStore: FilterCatalogStore;
  popularInThisCategoryStore: PopularInThisCategoryStore;
  popularProductStore: PopularProductStore;
  productCategoriesStore: ProductCategoriesStore;
  productDetailsStore: ProductDetailsStore;
  productFeedbackStore: ProductFeedbackStore;
  servicesProductStore: ServicesProductStore;
  productTradeOfferStore: ProductTradeOfferStore;
  searchPreviewStore: SearchPreviewStore;
  searchBarStore: SearchBarStore;
  modalStore: ModalStore;
  viewedProductsStore: ViewedProductsStore;
  promotionsStore: PromotionsStore;
  saleStore: SaleStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.brandsListStore = new BrandsListStore(this);
    this.brandStore = new BrandStore(this);
    this.catalogShowcaseStore = new CatalogShowcaseStore(this);
    this.catalogStore = new CatalogStore(this);
    this.menuStore = new MenuStore(this);
    this.cartStore = new CartStore(this);
    this.favoritesStore = new FavoritesStore(this);
    this.compareStore = new CompareStore(this);
    this.breadcrumbsStore = new BreadcrumbsStore(this);
    this.fastTagStore = new FastTagStore(this);
    this.recommendedProductStore = new RecommendedProductStore(this);
    this.baseProductPropertiesStore = new BaseProductPropertiesStore(this);
    this.newProductStore = new NewProductStore(this);
    this.navBarStore = new NavBarStore(this);
    this.topBrandsStore = new TopBrandsStore(this);
    this.topProductPropertiesStore = new TopProductPropertiesStore(this);
    this.filterCatalogStore = new FilterCatalogStore(this);
    this.popularInThisCategoryStore = new PopularInThisCategoryStore(this);
    this.popularProductStore = new PopularProductStore(this);
    this.productCategoriesStore = new ProductCategoriesStore(this);
    this.productDetailsStore = new ProductDetailsStore(this);
    this.productFeedbackStore = new ProductFeedbackStore(this);
    this.servicesProductStore = new ServicesProductStore(this);
    this.productTradeOfferStore = new ProductTradeOfferStore(this);
    this.searchPreviewStore = new SearchPreviewStore(this);
    this.searchBarStore = new SearchBarStore(this);
    this.modalStore = new ModalStore(this);
    this.viewedProductsStore = new ViewedProductsStore(this);
    this.promotionsStore = new PromotionsStore(this);
    this.saleStore = new SaleStore(this);
  }
}

const root = new RootStore();

export default root;
