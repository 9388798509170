import { FC } from 'react';

import { RecursiveCategory } from 'stores/MenuStore';

import * as S from './styles';

type ThirdLevelCategoryProps = {
  thirdLevelCategory: RecursiveCategory;
};

const ThirdLevelCategory: FC<ThirdLevelCategoryProps> = ({ thirdLevelCategory }) => {
  if (!thirdLevelCategory) {
    return null;
  }

  return (
    <S.BoxesItem key={thirdLevelCategory.id}>
      <S.BoxesLink to={thirdLevelCategory.url}>{thirdLevelCategory.name}</S.BoxesLink>
    </S.BoxesItem>
  );
};

export default ThirdLevelCategory;
