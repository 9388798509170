import { FC, MouseEventHandler } from 'react';

import * as S from './styles';

export interface IButtonClose {
  onClick: MouseEventHandler<HTMLSpanElement>;
  className?: string;
}

const ButtonClose: FC<IButtonClose> = ({ onClick, className }) => {
  return (
    <S.BtnClose className={className} onClick={onClick} aria-label="Закрыть" role="button">
      <S.StyledCloseIcn icon="close" />
    </S.BtnClose>
  );
};

export default ButtonClose;
