import { FC } from 'react';

import CaseProducts from 'components/CaseProducts';

import { ProductType } from 'shared/types/ProductType';

type GoodsTypes = {
  data: ProductType[];
  title?: string[] | undefined;
};

const SliderProducts: FC<GoodsTypes> = ({ data, title }) => {
  return (
    <div className="mt-5">
      <CaseProducts
        idSlider="1"
        pageHeadingTitle={title ? String(title) : ''}
        products={data}
        showAll={false}
        secondaryBtn={false}
      />
    </div>
  );
};

export default SliderProducts;
