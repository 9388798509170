import { Params } from 'react-router-dom';

import rootStore from 'stores/RootStore';

export const brandLoader = async ({ params }: { params: Params<string> }) => {
  const brandUrl = params.brandUrl;

  if (!brandUrl) return null;

  const { brandStore } = rootStore;
  await brandStore.fetchBrandPage(brandUrl);

  return brandUrl;
};
