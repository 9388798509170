import api from 'stores/Api';

import { AuthData, AuthResponse, IUserProfileResponse } from '../types';

export const loginApi = async (phone: string) => {
  const res = api.post<AuthResponse>('auth/login', { phone });
  return res;
};

export const approveLoginApi = async (authData: AuthData) => {
  const res = await api.post<AuthResponse>('auth/approve_login', authData);
  return res;
};

export const getUserProfileApi = async () => {
  const res = await api.post<IUserProfileResponse>('auth/user_profile');
  return res;
};

export const resendSmsApi = async (phone: string) => {
  const res = await api.post<AuthResponse>('auth/resend_sms', { phone });
  return res;
};

export const logoutApi = async (Authorization: string) => {
  const res = await api.post(
    'auth/logout',
    {},
    {
      headers: {
        Authorization,
      },
    }
  );

  return res;
};
