import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import Alert from 'ui-kit/Alert';
import Loader from 'ui-kit/Loader';

import Breadcrumbs from 'components/Breadcrumb';
import Container from 'components/Container';

import rootStore from 'stores/RootStore';

import Goods from '../../components/Goods';
import SliderProducts from '../../components/SliderProducts';
import Steps from '../../components/Steps';
import WhereItWork from '../../components/WhereItWork';
import * as S from './styles';

const DetailSale = () => {
  const { saleStore } = rootStore;
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  if (saleStore.isLoading) return <Loader />;

  const productDetailText = saleStore.dataSale?.detail_text || '';

  if (!saleStore.dataSale || Object.keys(saleStore.dataSale).length === 0) {
    return (
      <Container>
        <Alert clear={false}>
          Акция не существует либо закончилась <S.StyledButton onClick={handleGoBack}>Вернуться назад</S.StyledButton>
        </Alert>
      </Container>
    );
  }

  const legalProperty = saleStore.getPropertyByCode('LEGAL');
  const WHERE_IT_WORK = saleStore.getPropertyByCode('WHERE_IT_WORK');
  const STEPS = saleStore.getPropertyByCode('STEPS');
  const GOODS_ITEMS = saleStore.getPropertyByCode('GOODS_ITEMS');
  const elementsCenter = saleStore.getPropertyByCode('ELEMENTS_CENTER');
  const elementsCenterTitle = saleStore.getPropertyByCode('ELEMENTS_CENTER_TITLE');
  const elementsCenter2 = saleStore.getPropertyByCode('ELEMENTS_CENTER2');
  const elementsCenter2Title = saleStore.getPropertyByCode('ELEMENTS_CENTER2_TITLE');

  return (
    <Container>
      <Helmet>
        <title>{saleStore.dataSale.name}</title>
      </Helmet>

      <Breadcrumbs />

      <S.Media src={saleStore.dataSale.detail_picture} alt="" />

      <S.Container>
        <S.Content>
          <S.Title>{saleStore.dataSale.name}</S.Title>

          <div dangerouslySetInnerHTML={{ __html: productDetailText }}></div>

          {STEPS && <Steps data={STEPS} />}
        </S.Content>

        <S.Sidebar>{WHERE_IT_WORK && <WhereItWork data={WHERE_IT_WORK} />}</S.Sidebar>
      </S.Container>

      {GOODS_ITEMS && GOODS_ITEMS.element && <Goods data={GOODS_ITEMS.element} />}

      {elementsCenter && elementsCenter.element && (
        <SliderProducts data={elementsCenter.element} title={elementsCenterTitle?.value} />
      )}

      {elementsCenter2 && elementsCenter2.element && (
        <SliderProducts data={elementsCenter2.element} title={elementsCenter2Title?.value} />
      )}

      {legalProperty && <S.LegalInfo>{legalProperty.value}</S.LegalInfo>}
    </Container>
  );
};

export default DetailSale;
