import styled from 'styled-components';
import Badge from 'ui-kit/Badge';

import { pxToVw } from 'shared/helpers/pxToVw';

export const Stickers = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StickersBadge = styled(Badge)`
  margin-right: ${pxToVw(16)};
  margin-bottom: ${pxToVw(16)};
  position: relative;
`;
