import arrowGray from 'assets/img/decor/arrow-gray.svg';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

import { commonImageStyles } from 'shared/helpers/generalStyles';
import { pxToVw } from 'shared/helpers/pxToVw';

export const ProductThumbs = styled.div`
  position: relative;
  margin-left: ${pxToVw(44)};
  margin-right: ${pxToVw(44)};
`;

export const ProductThumbsCarousel = styled(Swiper)`
  margin-top: ${pxToVw(-24)};
  height: ${pxToVw(160)};
  padding-top: ${pxToVw(40)};
  padding-bottom: ${pxToVw(40)};
`;

export const ProductThumbsItem = styled(SwiperSlide)`
  padding: ${pxToVw(4)};
  display: flex;
  border-radius: ${pxToVw(16)};
  background-color: var(--white);
  position: relative;

  &::before {
    opacity: 0;
    width: ${pxToVw(24)};
    height: ${pxToVw(24)};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: url(${arrowGray}) no-repeat center;
    content: '';
    transition:
      opacity 0.3s linear,
      bottom 0.3s linear;
  }

  &.swiper-slide-thumb-active {
    box-shadow: var(--shadow-Z200);

    &::before {
      opacity: 1;
      bottom: ${pxToVw(-32)};
    }
  }
`;

export const ProductThumbsImg = styled.img`
  ${commonImageStyles}
`;
