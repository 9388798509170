import { FC, ReactNode, useEffect, useRef, useState } from 'react';

import { pxToVw } from 'shared/helpers/pxToVw';
import { DESIGN_WIDTH } from 'shared/helpers/vars';

import * as S from './styles';

interface AccordionProps {
  title: string;
  children: ReactNode;
  isShowMore?: boolean;
}

const Accordion: FC<AccordionProps> = ({ isShowMore = true, title, children }) => {
  const ACCORDION_HEIGHT = 200 / DESIGN_WIDTH;

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number | null>(null);
  const isOpenSearch = showMore && isOpen && isShowMore;
  const isShowButton = contentHeight !== null && contentHeight >= ACCORDION_HEIGHT * 100;
  const accordionContentRef = useRef<HTMLElement>(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (accordionContentRef.current) {
      const height = (accordionContentRef.current.getBoundingClientRect().height / DESIGN_WIDTH) * 100;
      setContentHeight(height);
    }
  }, [isOpen, showMore]);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <S.AccordionContainer>
      <S.AccordionHeader onClick={toggleAccordion}>
        <S.StyledArrowIcon $isOpen={isOpen} />
        {title}
      </S.AccordionHeader>
      {isOpenSearch && <S.StyledInput placeholder="Поиск" />}
      <S.AccordionContent
        ref={accordionContentRef}
        $showMore={showMore}
        $blockwidth={showMore ? `${pxToVw(300)}` : `${pxToVw(200)}`}
        $isOpen={isOpen}
      >
        {children}
      </S.AccordionContent>
      {isShowButton && isOpen && (
        <S.ShowMoreButton onClick={handleShowMore}>{showMore ? 'Скрыть' : 'Показать ещё'}</S.ShowMoreButton>
      )}
    </S.AccordionContainer>
  );
};

export default Accordion;
