import styled, { css } from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

export const Options = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: ${pxToVw(8)};
  padding-bottom: ${pxToVw(8)};

  ${({ disabled }: { disabled?: boolean }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const Label = styled.div``;

export const Count = styled.span`
  margin-left: ${pxToVw(16)};
  color: var(--text-tertiary);
  flex-grow: 1;
`;

export const ActiveCount = styled(Count)`
  color: red;
`;
