import { SearchElementType } from 'shared/types/SearchType';

import * as S from './styles';

type SearchElementsProps = {
  elements: SearchElementType[];
  onClose: () => void;
};

const SearchElements = ({ elements, onClose }: SearchElementsProps) => {
  return elements.length >= 1 ? (
    <S.Block>
      <S.Title>Товары</S.Title>
      {elements?.map((item: SearchElementType) => (
        <S.ContentItemLink key={item.id} onClick={onClose} to={`${item.url}`}>
          <S.ImageBlock>
            <S.ContentImage src={item.preview_picture} />
          </S.ImageBlock>
          <S.ContentName>{item.name}</S.ContentName>
        </S.ContentItemLink>
      ))}
    </S.Block>
  ) : null;
};

export default SearchElements;
