import { FC } from 'react';

import * as S from './styles';

type LoaderProps = {
  className?: string;
  size?: 'small' | 'large';
  position?: 'static' | 'absolute';
  centered?: boolean;
};

const Loader: FC<LoaderProps> = ({ className, size = 'large', position = 'absolute', centered = false }) => {
  return (
    <>
      {centered && <S.Overlay />}
      <S.LoaderContainer className={className} size={size} position={position} $centered={centered.toString()}>
        <S.LoaderDecor />
      </S.LoaderContainer>
    </>
  );
};

export default Loader;
