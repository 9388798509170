import { FC, useRef } from 'react';
import { Swiper as SwiperType } from 'swiper';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { SwiperRef } from 'swiper/react';

import SliderArrows from 'components/SliderArrows';

import * as S from './styles';

interface GalleryCarouselProps {
  data: string[];
  slidesPerView?: number;
  spaceBetween?: number;
  onSwiperCallback?: (swiper: SwiperType) => void;
  enableNavigation?: boolean;
}

const GalleryCarousel: FC<GalleryCarouselProps> = ({
  data,
  slidesPerView = 6,
  spaceBetween = 13,
  onSwiperCallback,
}) => {
  const swiperRef = useRef<SwiperRef>(null);
  const prevProducts = 'prev-products-thumb-main';
  const nextProducts = 'next-products-thumb-main';

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handleSwiper = (swiper: SwiperType) => {
    if (onSwiperCallback) onSwiperCallback(swiper);
  };

  return (
    <S.ProductThumbs id="ProductCarousel">
      <S.ProductThumbsCarousel
        modules={[Thumbs, Navigation, FreeMode]}
        watchSlidesProgress
        onSwiper={handleSwiper}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        ref={swiperRef}
        navigation={{
          prevEl: `.${prevProducts}`,
          nextEl: `.${nextProducts}`,
        }}
      >
        {data.map((instance, index) => (
          <S.ProductThumbsItem key={`${instance}-${index}`}>
            <S.ProductThumbsImg src={instance} alt="" />
          </S.ProductThumbsItem>
        ))}
      </S.ProductThumbsCarousel>

      <SliderArrows goPrev={goPrev} goNext={goNext} prevClass={prevProducts} nextClass={nextProducts} />
    </S.ProductThumbs>
  );
};

export default GalleryCarousel;
