import styled from 'styled-components';
import Button from 'ui-kit/Button';

import { pxToVw } from 'shared/helpers/pxToVw';

export const FilterContainer = styled.div`
  top: 0;
  left: 0;
  width: ${pxToVw(304)};
  flex-shrink: 0;
`;

export const FilterInner = styled.div`
  overflow-y: auto;
  padding-right: ${pxToVw(8)};
  padding-bottom: ${pxToVw(4)};
  width: ${pxToVw(304)};
  background: var(--body-bg);
  scrollbar-gutter: stable;
  scrollbar-color: #ededed $stroke;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &::-webkit-scrollbar-track {
    background-color: #ededed;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--stroke);
    border-radius: 16px;
  }
`;

export const FilterWrap = styled.div`
  height: calc(100vh - 84px);
  padding-top: ${pxToVw(90)};
  display: flex;
  flex-direction: column;
`;

export const ButtonReset = styled(Button)`
  margin: ${pxToVw(12)} ${pxToVw(0)};
  height: ${pxToVw(52)};
  min-width: ${pxToVw(146)};
  flex-shrink: 0;
`;
