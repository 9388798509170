import styled, { css } from 'styled-components';

import * as vars from 'shared/helpers/vars';
import { IsOpenedProps } from 'shared/types/PropsTypes';

export const HeaderSearch = styled.div`
  width: 100%;
  position: relative;
`;

export const Drop = styled.div<IsOpenedProps>`
  position: absolute;
  width: 100%;
  top: calc(100% + 8px);
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
  z-index: ${vars.zindex.search};

  ${(props: IsOpenedProps) =>
    props.$isopened === 'true' &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;
