import styled from 'styled-components';
import Sprite from 'ui-kit/Sprite';

export const BtnClose = styled.span`
  display: inline-block;
  vertical-align: middle;
  color: var(--text-disabled);
  cursor: pointer;
  transition:
    color 200ms ease,
    transform 200ms ease;
  user-select: none;
  flex: 0 0 auto;
  line-height: 0;
`;
export const StyledCloseIcn = styled(Sprite)`
  display: inline-block;
  vertical-align: top;
  width: 16px;
  height: 16px;
  flex: 0 0 auto;
`;
