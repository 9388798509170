import styled from 'styled-components';
import Button from 'ui-kit/Button';
import Input from 'ui-kit/Input';
import Sprite from 'ui-kit/Sprite';

import { pxToVw } from 'shared/helpers/pxToVw';
import { hidePlaceholder } from 'shared/utils/hidePlaceholder';

export const Block = styled.div``;

export const SearchInput = styled(Input)`
  height: ${pxToVw(52)};
  width: 100%;
  padding: ${pxToVw(15)} ${pxToVw(16)};
  font-weight: 700;
  background: var(--field-bg);
  border-radius: ${pxToVw(8)};
  border-color: transparent;
  box-shadow: none;
  color: var(--text-tertiary);
  transition:
    opacity 0.2s ease-in-out,
    border 0.3s ease-in-out,
    color 0.2s ease-in-out;

  ${hidePlaceholder({
    color: 'var(--text-disabled)',
    opacity: '1',
    transition: 'opacity 0.2s ease-in-out',
  })}
  &:focus {
    outline: 0;
    border: 2px solid var(--primary);
    ${hidePlaceholder({
      opacity: '0',
    })};
  }
`;

export const Btn = styled(Button)`
  padding: 0;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  width: ${pxToVw(52)};
  height: ${pxToVw(52)};

  &::after {
    opacity: 0;
  }
`;

export const Icon = styled(Sprite)`
  width: ${pxToVw(16)};
  height: ${pxToVw(16)};
  margin: auto;
  fill: var(--text-tertiary);
`;
