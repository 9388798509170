import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

export const Wrap = styled.div`
  margin-bottom: ${pxToVw(40)};
`;

export const Block = styled.div``;

export const ColorItem = styled(Link)`
  margin-right: ${pxToVw(8)};
  margin-bottom: ${pxToVw(16)};
  border-radius: 50%;
  display: flex;
  border: ${pxToVw(2)} solid var(--white);

  svg {
    width: ${pxToVw(24)};
    height: ${pxToVw(24)};
    border-radius: 50%;
    margin: auto;
  }

  &.active {
    box-shadow: 0 0 0 ${pxToVw(2)} var(--primary);
  }
`;
