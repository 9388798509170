import { observer } from 'mobx-react-lite';

import rootStore from 'stores/RootStore';

import ConfirmCode from './components/ConfirmCode';
import Login from './components/Login';

export type AuthComponentProps = {
  closeButton?: boolean;
};

const AuthComponent = observer(({ closeButton = true }: AuthComponentProps) => {
  const { authStore } = rootStore;

  return <>{!authStore.isCodeSent ? <Login closeButton={closeButton} /> : <ConfirmCode closeButton={true} />}</>;
});

export default AuthComponent;
