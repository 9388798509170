import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

import { commonImageStyles } from 'shared/helpers/generalStyles';
import { pxToVw } from 'shared/helpers/pxToVw';

export const ProductCarousel = styled.aside`
  margin-bottom: ${pxToVw(4)};
  position: relative;
`;

export const ProductMainCarousel = styled(Swiper)`
  border-radius: ${pxToVw(16)};
  background-color: var(--white);
`;

export const ProductMainItem = styled(SwiperSlide)`
  height: ${pxToVw(679)};
  padding: ${pxToVw(35)};
  position: relative;
`;

export const ProductMainItemBox = styled.a`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const ProductCarouselImg = styled.img`
  margin: auto;
  ${commonImageStyles}
`;

export const ProductCarouselSkeleton = styled.div`
  width: 100%;
  height: 100%;
`;
