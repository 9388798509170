import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import SearchArticles from 'components/Search/SearchArticles';
import SearchCategories from 'components/Search/SearchCategories';
import SearchElements from 'components/Search/SearchElements';

import { ISearchType } from 'shared/types/SearchType';

import * as S from './styles';

type SearchResultListProps = {
  catalogList: ISearchType | null;
  onClose: () => void;
};

const SearchResultList: FC<SearchResultListProps> = observer(({ catalogList, onClose }) => {
  if (!catalogList) return null;

  const isAllEmpty = (obj: ISearchType): boolean => {
    return Object.values(obj).every((arr) => arr.length === 0);
  };

  if (isAllEmpty(catalogList)) {
    return (
      <S.Block>
        <S.Title>Товар не найден.</S.Title>
      </S.Block>
    );
  }

  return (
    <>
      <S.Section>
        <SearchElements elements={catalogList.elements} onClose={onClose} />
      </S.Section>
      <S.Section>
        <SearchCategories categories={catalogList.categories.slice(0, 3)} onClose={onClose} />
      </S.Section>
      <S.Section>
        <SearchArticles brands={catalogList.brands} onClose={onClose} />
      </S.Section>
    </>
  );
});

export default SearchResultList;
