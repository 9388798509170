import { makeAutoObservable, runInAction } from 'mobx';

import { ProductType } from 'shared/types/ProductType';

import api from 'stores/Api';
import { RootStore } from 'stores/RootStore';

interface ResponseDetailPromotion {
  data: PromotionDataTypes;
}

interface PromotionDataTypes {
  id: number;
  name: string;
  code: string;
  url: string;
  preview_picture: string;
  detail_picture: string;
  detail_text: string;
  properties: IProperty[];
}

export interface IProperty {
  id: number;
  code: string;
  image_url?: string;
  value?: string[];
  element?: ProductType[];
  list?: IPropertyWhere[];
}

interface IPropertyWhere {
  value: string;
}

class SaleStore {
  dataSale: PromotionDataTypes | null = null;
  saleState: 'pending' | 'fulfilled' | 'rejected' | null = null;
  error: Error | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  async loadSale(saleUrl: string) {
    this.saleState = 'pending';
    try {
      const response = await api.get<ResponseDetailPromotion>(`sale/${saleUrl}`);

      runInAction(() => {
        this.dataSale = response.data.data;
        this.saleState = 'fulfilled';
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.dataSale = null;
        this.error = error instanceof Error ? error : new Error('Неизвестная ошибка');
        this.saleState = 'rejected';
      });
    }
  }

  get isLoading() {
    return this.saleState === 'pending';
  }

  getPropertyByCode(code: string): IProperty | null {
    if (this.dataSale) {
      return this.dataSale.properties.find((property) => property.code === code) || null;
    }
    return null;
  }
}

export default SaleStore;
