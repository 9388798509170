import { useEffect, useRef, useState } from 'react';
import Goods from 'ui-kit/Goods';
import ToggleComponent from 'ui-kit/ToggleComponent';

import { DESIGN_WIDTH } from 'shared/helpers/vars';

import * as S from './styles';

interface ProductPropertyItemProps {
  name: string;
  value: string;
  code: string;
}

const CompareProductProperty = ({ name, value, code }: ProductPropertyItemProps) => {
  const VALUE_HEIGHT = 60 / DESIGN_WIDTH;
  const valueRef = useRef<HTMLElement>(null);
  const [contentHeight, setContentHeight] = useState<number | null>(null);
  const [showMore, setShowMore] = useState<boolean>(false);
  const isShowButton = contentHeight !== null && contentHeight >= VALUE_HEIGHT * 100;

  useEffect(() => {
    if (valueRef.current) {
      const height = (valueRef.current.getBoundingClientRect().height / DESIGN_WIDTH) * 100;
      setContentHeight(height);
    }
  }, [showMore]);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  if (code === 'AVITO_ADVANTAGE') {
    return null;
  }

  return (
    <S.Item overflow={showMore}>
      <S.Label>
        <S.LabelText>{name}</S.LabelText>
      </S.Label>
      <S.Value ref={valueRef}>
        {isShowButton && (
          <ToggleComponent
            isVisible={isShowButton}
            onClick={handleShowMore}
            text={showMore ? 'Свернуть' : 'Показать ещё'}
            iconName="arrow2"
          />
        )}
        {showMore ? '' : value}
      </S.Value>
      {showMore && <Goods drop={showMore} setShowMore={setShowMore} value={value} />}
    </S.Item>
  );
};

export default CompareProductProperty;
