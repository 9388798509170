import { ProductDetails, ProductProperty } from 'shared/types/ProductDetailsType';
import { ApiResponse } from 'shared/types/ProductTradeOfferStoreTypes';
import { ProductResponseType } from 'shared/types/ProductType';

import api from 'stores/Api';
import { ProductCategoriesResponseType } from 'stores/products/ProductCategoriesStore';

export const fetchProductDetailsApi = async (url: string) => {
  const response = await api.get<ProductDetails>(`element/${url}`);
  return response;
};

export const fetchProductPropertiesApi = async (productId: string, type: string) => {
  const response = await api.post<{ data: { [key: string]: ProductProperty } }>('element_props', {
    id: productId,
    type: type,
  });
  return response;
};

export const fetchNewProductApi = async (kiosk: string) => {
  const response = await api.get<ProductResponseType>(`new?kiosk=${kiosk}`);
  return response;
};

export const fetchPopularProductApi = async (kiosk: string) => {
  const response = await api.get<ProductResponseType>(`popular?kiosk=${kiosk}`);
  return response;
};

export const fetchMainProductApi = async (kiosk: string) => {
  const response = await api.get<ProductCategoriesResponseType>(`main_cat?kiosk=${kiosk}`);
  return response;
};

export const fetchRecommendedProductApi = async (kiosk: string) => {
  const response = await api.get<ProductResponseType>(`recommend?kiosk=${kiosk}`);
  return response;
};

export const fetchPopularApi = async (url: string) => {
  const response = await api.get(`catalog_elements/${url}`);
  return response;
};

export const fetchTradeOfferApi = async (productId: string) => {
  const response = await api.post<ApiResponse>('trade_offer', {
    id: productId,
  });
  return response;
};

export const fetchViewedProductsApi = async (productIds: number[]) => {
  const response = await api.post(`preview_elements`, {
    ids: productIds,
  });
  return response;
};
