import { SearchBrandType } from 'shared/types/SearchType';

import * as S from './styles';

type SearchArticlesProps = {
  brands: SearchBrandType[];
  onClose: () => void;
};

const SearchArticles = ({ brands, onClose }: SearchArticlesProps) => {
  return brands.length >= 1 ? (
    <S.Block>
      <S.Title>Бренды</S.Title>
      {brands.map((item: SearchBrandType) => (
        <S.ContentItemLink key={item.name} onClick={onClose} to={`brands/${item.id}`}>
          <S.Label key={item.name}>{item.name}</S.Label>
        </S.ContentItemLink>
      ))}
    </S.Block>
  ) : null;
};

export default SearchArticles;
