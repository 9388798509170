import styled from 'styled-components';
import Button from 'ui-kit/Button';

import { pxToVw } from 'shared/helpers/pxToVw';
import { truncateMultiLine } from 'shared/helpers/truncateMultiLine';

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.div`
  width: calc(70% - 32px);
`;

export const Sidebar = styled.aside`
  width: 30%;
  margin-left: auto;
`;

export const Title = styled.h1`
  max-width: 75%;
  margin-bottom: ${pxToVw(16)};
  font-size: ${pxToVw(24)};
  line-height: ${pxToVw(32)};
  ${truncateMultiLine(3)}
`;

export const Media = styled.img`
  border-radius: ${pxToVw(8)};
  margin-bottom: ${pxToVw(24)};
`;

export const StyledButton = styled(Button)`
  margin-left: ${pxToVw(8)};
`;

export const LegalInfo = styled.div`
  padding-top: ${pxToVw(50)};
  font-size: ${pxToVw(12)};
`;
