import { FC } from 'react';

import * as S from './styles';

interface SkeletonProps {
  count: number;
}

const Skeleton: FC<SkeletonProps> = ({ count }) => {
  return (
    <S.FastTagList className="list-unstyled">
      {Array.from({ length: count }).map((_, index) => (
        <S.FastTagListItem key={index} className="skeleton-item btn btn-disabled"></S.FastTagListItem>
      ))}
    </S.FastTagList>
  );
};

export default Skeleton;
