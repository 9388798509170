import { Params } from 'react-router-dom';

import rootStore from 'stores/RootStore';

export const catalogLoader = async ({ params }: { params: Params<string> }) => {
  if (params['*'] !== '') {
    await rootStore.catalogStore.fetchCatalogCategories(params['*']);
    await rootStore.popularInThisCategoryStore.fetchPopular(params['*']);
  } else if (params['*'] === '') {
    await rootStore.menuStore.loadData();
  }

  return null;
};
