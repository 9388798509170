import { rgba } from 'polished';

// Ширина дизайна для pxToVw
export const DESIGN_WIDTH = 1080;

export const colors = {
  bgLight: '#fafafa',
  white: '#fff',
  shadow: '#747CA9',
  price: '#B7B7BD',
};

export type ApiStatusesType = {
  PENDING: string;
  REJECTED: string;
  FULFILLED: string;
};

export const apiStatuses: ApiStatusesType = {
  PENDING: 'pending',
  REJECTED: 'rejected',
  FULFILLED: 'fulfilled',
};

export const zindex = {
  items: 50,
  menu: 99,
  search: 99,
  stickyHeader: 103,
  alert: 10004,
  appBlur: 103,
  mobpanel: 101,
  side: 102,
  preheader: 103,
  sort: 500,
  filter: 500,
  navMobile: 999,
  overlay: 1000,
  scrollToTop: 104,
  disabled: 1000,
  max: 999999999,
};

export const shadow = {
  drop: `4px 4px 24px ${rgba(colors.shadow, 0.16)}`,
  dropInvert: `4px -4px 24px ${rgba(colors.shadow, 0.16)}`,
  filter: `12px 12px 24px ${rgba(colors.shadow, 0.16)}`,
  block: `0 10px 24px ${rgba(colors.shadow, 0.08)}`,
};

export const timeTransition = {
  fast: 100,
  base: 200,
  medium: 300,
  long: 500,
};

export const trans = {
  fast: `${timeTransition.fast}ms ease`,
  base: `${timeTransition.base}ms ease`,
  medium: `${timeTransition.medium}ms ease`,
  long: `${timeTransition.long}ms ease`,
};
