import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Button from 'ui-kit/Button';
import Sprite from 'ui-kit/Sprite';

import { commonImageStyles } from 'shared/helpers/generalStyles';
import { pxToVw } from 'shared/helpers/pxToVw';
import { truncateMultiLine } from 'shared/helpers/truncateMultiLine';
import { ClassNameProps } from 'shared/types/ClassNameProps';

export const Card = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: ${pxToVw(12)} ${pxToVw(24)};
  flex-direction: column;
  background-color: var(--white);
  border-radius: ${pxToVw(16)};
  color: var(--text-primary);
`;

export const CardMedia = styled(Link)`
  height: ${pxToVw(272)};
  margin-bottom: ${pxToVw(16)};
  display: flex;
`;

export const CardImg = styled.img`
  ${commonImageStyles}
`;

export const CardMeta = styled.ul<ClassNameProps>`
  margin-bottom: ${pxToVw(16)};
  display: flex;
  align-items: center;
  color: var(--text-secondary);
  font-size: ${pxToVw(14)};
  line-height: 1;
`;

export const CardMetaItem = styled.li<ClassNameProps>`
  width: 25%;

  .comment {
    margin-right: ${pxToVw(8)};
    fill: var(--icons);
  }

  &.toggle {
    height: ${pxToVw(32)};
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;

    + &.toggle {
      margin-left: ${pxToVw(2)};
    }

    .icon {
      fill: var(--text-disabled);
    }
  }

  &.active.toggle {
    fill: var(--warn);
  }
`;

export const CardMetaIcon = styled(Sprite)<ClassNameProps>`
  width: ${pxToVw(32)};
  height: ${pxToVw(32)};
  pointer-events: none;
  fill: var(--text-tertiary);
`;

export const CardMetaStar = styled(CardMetaIcon)<ClassNameProps>`
  margin-right: ${pxToVw(8)};
  fill: var(--attention);
  stroke: var(--attention);

  + .comment {
    margin-right: auto;
  }
`;

export const CardMetaLink = styled(Link)`
  height: ${pxToVw(32)};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: inherit;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const CardTitle = styled(Link)`
  display: inline-flex;
  color: inherit;
  margin-bottom: ${pxToVw(16)};
  font-size: ${pxToVw(18)};
  line-height: ${pxToVw(24)};
  height: ${pxToVw(72)};
  ${truncateMultiLine(3)}
`;

export const CardBottom = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
`;

export const CardPrice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CardCost = styled.div`
  font-size: ${pxToVw(16)};
  line-height: 1;
  margin-right: auto;
  margin-bottom: ${pxToVw(6)};
  font-weight: 700;
  font-family: var(--font-family-loos);
`;

export const CardCostOLd = styled.del`
  font-weight: 700;
  font-size: ${pxToVw(12)};
  color: var(--text-tertiary);
  font-family: var(--font-family-loos);
`;

export const CardBonuses = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  font-weight: 700;
  color: var(--primary);
  font-family: var(--font-family-loos);
  line-height: 1;
`;

export const CardBonusesIcon = styled(Sprite)`
  width: ${pxToVw(16)};
  height: ${pxToVw(16)};
  margin-left: ${pxToVw(1)};
  padding: ${pxToVw(2)};
  top: ${pxToVw(-2)};
  fill: var(--primary);
  position: relative;
`;

export const CardFooter = styled.div`
  margin-top: auto;
  padding-top: ${pxToVw(16)};
`;

export const CardFooterContent = styled.div`
  margin-bottom: ${pxToVw(16)};
  color: var(--text-tertiary);
  font-size: ${pxToVw(14)};
  line-height: ${pxToVw(18)};
`;

export const CardBasket = styled(Button)`
  height: ${pxToVw(48)};
  padding: ${pxToVw(12)} ${pxToVw(16)};
  justify-content: center;
  /*  ${({ isLoading }: { isLoading: boolean }) =>
    isLoading &&
    css`
      background-color: var(--blue-active);
    `}*/
`;

export const CardSticker = styled.div`
  position: absolute;
  top: ${pxToVw(16)};
  left: ${pxToVw(18)};
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;

export const CardMetaIconCompare = styled(CardMetaIcon)`
  width: ${pxToVw(20)};
`;
