import styled from 'styled-components';
import Button from 'ui-kit/Button';
import Sprite from 'ui-kit/Sprite';

import { pxToVw } from 'shared/helpers/pxToVw';

export const Alert = styled.div<{ $isOpenAlert: boolean }>`
  width: 100%;
  display: ${({ $isOpenAlert }: { $isOpenAlert: boolean }) => ($isOpenAlert ? 'flex' : 'none')};
  padding: ${pxToVw(24)};
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  background: #ffeaad;
`;

export const ClearIcon = styled(Sprite)<{ icon: string }>`
  cursor: pointer;
  width: ${pxToVw(21)};
  height: ${pxToVw(19)};
  fill: var(--text-disabled);
`;

export const WarningIcon = styled(Sprite)<{ icon: string }>`
  margin-right: ${pxToVw(16)};
  width: ${pxToVw(21)};
  height: ${pxToVw(19)};
  fill: #e5aa01;
`;

export const Btn = styled(Button)`
  background: none;
`;
