import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { observer } from 'mobx-react-lite';
import Loader from 'ui-kit/Loader';

import Breadcrumbs from 'components/Breadcrumb';
import Container from 'components/Container';
import PromotionsCard from 'components/PromotionsCard';
import { bgColor } from 'components/PromotionsShowcase';

import { Row } from 'shared/helpers/generalStyles';

import rootStore from 'stores/RootStore';

import { Card } from './styles';

const MainSales = observer(() => {
  const { promotionsStore } = rootStore;

  useEffect(() => {
    promotionsStore.loadData();
  }, [promotionsStore]);

  if (promotionsStore.isLoading) return <Loader position="static" />;

  if (promotionsStore.isError) {
    console.log('Error: ', promotionsStore.errorMessage);
    return null;
  }

  return (
    <Container>
      <Helmet>
        <title>Акции на товары</title>
      </Helmet>

      <Breadcrumbs />

      <h1 className="mb-5">Все акции</h1>

      <Row>
        {promotionsStore.data.map((instance, index) => (
          <Card key={instance.id}>
            <PromotionsCard data={instance} bgColor={bgColor[index % bgColor.length]} />
          </Card>
        ))}
      </Row>
    </Container>
  );
});

export default MainSales;
