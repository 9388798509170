import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';
import * as vars from 'shared/helpers/vars';

export const Block = styled.div<{ hasItems?: boolean }>`
  width: 100%;
  background: ${vars.colors.white};
  border-radius: ${pxToVw(12)};
  overflow: auto;
  padding: ${pxToVw(8)} ${pxToVw(12)};
  margin-bottom: ${pxToVw(2)};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.div`
  margin-bottom: ${pxToVw(8)};
  font-size: ${pxToVw(10)};
  color: var(--text-tertiary);
`;

export const ContentItemLink = styled(Link)`
  display: flex;
  align-items: center;
  padding-top: ${pxToVw(4)};
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
`;

export const Label = styled.div`
  margin-bottom: ${pxToVw(8)};
  font-size: ${pxToVw(12)};
  font-weight: 400;
  height: ${pxToVw(20)};
  color: var(--text-tertiary);
  user-select: none;
  flex: 0 0 auto;
  &:hover {
    font-weight: 700;
  }
`;
