import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Sprite from 'ui-kit/Sprite';

import { pxToVw } from 'shared/helpers/pxToVw';

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

export const ListItem = styled.li`
  padding-right: ${pxToVw(24)};
  margin: 0 0 ${pxToVw(68)};
  width: 25%;
`;

export const ItemLetter = styled.h3`
  margin-bottom: ${pxToVw(12)};
  color: var(--text-tertiary);
  font-size: ${pxToVw(32)};
  line-height: ${pxToVw(40)};
  text-transform: uppercase;
`;

export const ListLinks = styled.ul``;

export const LinksItem = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: ${pxToVw(20)};
  }
`;

export const ListArrow = styled(Sprite)`
  width: ${pxToVw(24)};
  height: ${pxToVw(24)};
  margin-left: ${pxToVw(8)};
  fill: var(--primary);
`;

export const ListValue = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: var(--primary);
  font-weight: 700;
  line-height: ${pxToVw(24)};
  letter-spacing: 0.02em;
`;
