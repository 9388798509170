import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Button from 'ui-kit/Button';
import Modal from 'ui-kit/Modal';

import rootStore from 'stores/RootStore';

const InactivityModal = observer(() => {
  const { modalStore, authStore } = rootStore;
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    if (modalStore.isInactivityModalOpen) {
      setCountdown(10);
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [modalStore.isInactivityModalOpen]);

  useEffect(() => {
    if (countdown === 0) {
      modalStore.closeInactivityModal();
      modalStore.setLastActivity(Date.now());
      if (authStore.isAuth) {
        authStore.logout();
        localStorage.clear();
      }
      navigate('/');
    }
  }, [authStore, countdown, modalStore, navigate]);

  const handleButtonClick = () => {
    modalStore.closeInactivityModal();
  };

  return (
    modalStore.isInactivityModalOpen && (
      <Modal closeButton={false} onClose={modalStore.closeInactivityModal} isOpen={modalStore.isInactivityModalOpen}>
        <p>Вы слишком долго бездействовали.</p>
        <p>Перенаправление через {countdown} секунд ...</p>
        <Button onClick={handleButtonClick}>Я тут</Button>
      </Modal>
    )
  );
});

export default InactivityModal;
