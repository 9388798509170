import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

export const Steps = styled.div`
  margin-top: ${pxToVw(32)};
`;

export const StepsList = styled.ol`
  counter-reset: myCounter;
  display: flex;
  flex-wrap: wrap;
  margin-left: ${pxToVw(-16)};
  margin-right: ${pxToVw(-16)};
  margin-bottom: ${pxToVw(8)};
  text-align: center;
`;

export const Item = styled.li`
  width: 33%;
  padding-left: ${pxToVw(16)};
  padding-right: ${pxToVw(16)};
  margin-bottom: ${pxToVw(32)};
  font-weight: 700;
  line-height: 1.2;

  &::before {
    font-size: ${pxToVw(24)};
    margin: 0 auto ${pxToVw(16)};
    width: ${pxToVw(70)};
    height: ${pxToVw(70)};
    border-radius: 50%;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    background-color: var(--primary);
    counter-increment: myCounter;
    content: counter(myCounter);
  }
`;
