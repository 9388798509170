import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { RoutesLinks } from 'RoutesLinks';
import Loader from 'ui-kit/Loader';

import ProductCard from 'components/ProductCard';

import rootStore from 'stores/RootStore';

import * as S from './styles';

const FavoritesProductsContainer = observer(() => {
  const { favoritesStore } = rootStore;
  const { authStore } = rootStore;

  return (
    <S.CartProductsContainer>
      {favoritesStore.isLoading && <Loader centered />}

      {!favoritesStore.favoritesData.length && (
        <>
          {!authStore.isAuth && (
            <p>Войдите в аккаунт, чтобы просмотреть товары, добавленные в&nbsp;избранное при прошлом визите.</p>
          )}
          <p>
            На{' '}
            <Link to={RoutesLinks.HOME} className="l-link">
              главной&nbsp;странице
            </Link>{' '}
            представлены акции и&nbsp;специальные предложения, которые помогут подобрать нужные товары.
          </p>
        </>
      )}

      {favoritesStore.favoritesData.map((item) => (
        <S.ProductCardContainer key={item.id}>
          <ProductCard data={item} />
        </S.ProductCardContainer>
      ))}
    </S.CartProductsContainer>
  );
});

export default FavoritesProductsContainer;
