import { FC, lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { observer } from 'mobx-react-lite';
import Loader from 'ui-kit/Loader';
import SectionHeader from 'ui-kit/SectionHeader';

import NotFound from 'pages/NotFound';

import Breadcrumbs from 'components/Breadcrumb';
import CatalogFiltersSection from 'components/CatalogFiltersSection';
import Container from 'components/Container';
import FastTag from 'components/FastTag';
import ShowcaseCarousel from 'components/ShowcaseCarousel';

import rootStore from 'stores/RootStore';

const LazySearchBar = lazy(() => import('components/SearchBar'));
const LazyTopBrands = lazy(() => import('components/TopBrands'));
const LazyPopularInThisCategory = lazy(() => import('../components/PopularInThisCategory'));

export const CatalogPage: FC = observer(() => {
  const { categoryData, childCategory, isLoading, error } = rootStore.catalogStore;

  if (isLoading) return <Loader position="static" />;

  if (categoryData?.id === undefined || error) {
    return <NotFound />;
  }

  return (
    <Container>
      <Helmet>
        <title>{categoryData.name}</title>
      </Helmet>

      <Breadcrumbs />

      <SectionHeader showAll={false} pageHeadingTitle={categoryData.name} />

      {childCategory?.length ? <ShowcaseCarousel showAll={false} promotionsData={childCategory} /> : ''}

      <Suspense fallback={<Loader position="static" />}>
        <LazySearchBar />
        <LazyPopularInThisCategory />
        <LazyTopBrands />
        {/* @todo вывести актуальную информацию когда будет эндпоинт */}
        {/* <SectionHeader showAll={false} pageHeadingTitle="Видео-обзоры на роботы-пылесосы" /> */}
        {/*      <CaseProducts
         idSlider="8"
         pageHeadingTitle="Лучшие предложения"
         link="#купили"
         products={popularInThisCategoryData}
         />
         <CaseProducts
         idSlider="9"
         pageHeadingTitle="Товары со скидками(с максимальными скидками)"
         link="#купили"
         products={popularInThisCategoryData}
         />*/}
        <FastTag />
        {/*
         <CaseProducts idSlider="10" pageHeadingTitle="До 30 000 ₽" link="#купили"
         products={popularInThisCategoryData} />
         <CaseProducts
         idSlider="10"
         pageHeadingTitle="Скидка 10% с промокодом"
         link="#купили"
         products={popularInThisCategoryData}
         />*/}
        {/* <ReviewsSection idSlider="11" reviewsData={ReviewsData} /> */}
        <CatalogFiltersSection />
      </Suspense>
    </Container>
  );
});
