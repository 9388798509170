import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';
import * as vars from 'shared/helpers/vars';

export const Section = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Block = styled.div<{ hasItems?: boolean }>`
  width: 100%;
  background: ${vars.colors.white};
  border-radius: ${pxToVw(12)};
  overflow: auto;
  padding: ${pxToVw(8)};
  margin-bottom: ${pxToVw(2)};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.div`
  margin: ${pxToVw(4)};
  font-weight: 600;
  font-size: ${pxToVw(12)};
  color: var(--text-tertiary);
`;
