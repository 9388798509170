import { forwardRef } from 'react';

import { FormCheckProps } from 'shared/types/FormCheckTypes';

import * as S from './styles';

const Checkbox = forwardRef<HTMLInputElement, FormCheckProps>(
  (
    {
      id,
      $inline = true,
      $reverse = false,
      disabled = false,
      defaultChecked = false,
      checked,
      children,
      className,
      ...props
    },
    ref
  ) => {
    return (
      <S.Checkbox className={className} $inline={$inline}>
        <S.Input
          {...props}
          type="checkbox"
          ref={ref}
          id={id}
          $reverse={$reverse}
          disabled={disabled}
          {...(checked !== undefined ? { checked } : { defaultChecked })}
        />
        <S.Label htmlFor={id} $inline={$inline} $reverse={$reverse}>
          {children}
        </S.Label>
      </S.Checkbox>
    );
  }
);

export default Checkbox;
