import {
  approveLoginApi,
  getUserProfileApi,
  loginApi,
  logoutApi,
  resendSmsApi,
} from 'entities/Authorization/services/authApi';
import { ApiError, AuthData, AuthResponse, IUserProfile } from 'entities/Authorization/types';
import { makeAutoObservable, runInAction } from 'mobx';

import { RootStore } from './RootStore';

class AuthStore {
  isAuth: boolean = false;
  status: string = '';
  userInfo: IUserProfile | null = null;
  tokens: {
    accessToken?: string;
    refreshToken?: string;
    accessExpiresIn?: number;
    refreshExpiresIn?: number;
  } = {};
  errorMessage: string | undefined = '';
  isLoggingOut: boolean = false;
  isCodeSent: boolean = false;
  phoneNumber: string = '';

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
    this.loadTokensFromStorage();
    this.checkAuthStatus();
  }

  checkAuthStatus(): void {
    const currentTime = Date.now();

    if (this.tokens.accessToken && this.tokens.accessExpiresIn && currentTime < this.tokens.accessExpiresIn) {
      this.setStatus('Success');
      this.getUserProfile();
      this.isAuth = true;
    } else {
      this.setStatus('Expired');
      this.isAuth = false;
    }
  }

  async login(authData: string): Promise<void> {
    try {
      const response = await loginApi(authData);
      runInAction(() => {
        this.setStatus(response.data.status);
      });
    } catch (error) {
      runInAction(() => {
        this.handleApiError(error);
        this.isAuth = false;
      });
    }
  }

  async approveLogin(authData: AuthData): Promise<void> {
    try {
      const response = await approveLoginApi(authData);
      runInAction(() => {
        if (!response.data.type) {
          this.setTokens(response.data);
          this.setStatus('Success');
          this.getUserProfile();
          this.isAuth = true;
        } else {
          this.setStatus('Error');
          this.isAuth = false;
          this.errorMessage = response.data.errors;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.handleApiError(error);
        this.isAuth = false;
      });
    }
  }

  async getUserProfile(): Promise<void> {
    try {
      const response = await getUserProfileApi();
      runInAction(() => {
        if (response.data.data) this.userInfo = response.data.data;
      });
    } catch (error) {
      console.error(error);
    }
  }

  setStatus(newStatus: string): void {
    this.status = newStatus;
  }

  setTokens(tokensData: AuthResponse): void {
    this.tokens.accessToken = tokensData?.access_token;
    this.tokens.refreshToken = tokensData?.refresh_token;

    const currentTime = Date.now();
    this.tokens.accessExpiresIn = currentTime + tokensData?.access_expires_in * 1000;

    this.saveTokensToStorage();
  }

  async resendSms(phone: string): Promise<void> {
    try {
      await resendSmsApi(phone);
    } catch (error) {
      runInAction(() => {
        this.handleApiError(error);
      });
    }
  }

  async logout(): Promise<void> {
    this.isLoggingOut = true;
    this.isCodeSent = false;
    try {
      await logoutApi(`Bearer ${this.tokens.accessToken}`);
      runInAction(() => {
        this.clearTokens();
        this.setStatus('LoggedOut');
        this.isAuth = false;
        this.userInfo = null;
        localStorage.removeItem('accessToken');
        localStorage.removeItem('authTokens');
      });
    } catch (error) {
      runInAction(() => {
        this.handleApiError(error);
      });
    } finally {
      runInAction(() => {
        this.isLoggingOut = false;
      });
    }
  }

  clearTokens(): void {
    this.tokens = {};
  }

  handleCodeSent(phone: string) {
    this.isCodeSent = true;
    this.phoneNumber = phone;
  }

  handleReset() {
    this.isCodeSent = false;
  }

  private saveTokensToStorage(): void {
    localStorage.setItem('authTokens', JSON.stringify(this.tokens));
    localStorage.setItem('accessToken', JSON.stringify(this.tokens.accessToken));
  }

  private loadTokensFromStorage(): void {
    const storedTokens = localStorage.getItem('authTokens');
    if (storedTokens) {
      const tokens = JSON.parse(storedTokens);
      this.tokens.accessToken = tokens.accessToken;
      this.tokens.refreshToken = tokens.refreshToken;
      this.tokens.accessExpiresIn = new Date(tokens.accessExpiresIn).getTime();
    }
  }

  private handleApiError(error: unknown): void {
    if (error instanceof Error) {
      console.error('Ошибка API:', error.message);
    } else if (typeof error === 'object' && error !== null && 'message' in error) {
      console.error('Ошибка API:', (error as ApiError).message);
    } else {
      console.error('Неизвестная ошибка API');
    }
    this.setStatus('Error');
  }
}

export default AuthStore;
