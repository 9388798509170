import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

export const CartProductsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ProductCardContainer = styled.div`
  display: block;
  width: calc(100% / 3 - 12px);
  margin-bottom: ${pxToVw(16)};
`;
