import { FC, FormEvent, useState } from 'react';
import { toast } from 'react-toastify';

import AgreePersonalData from 'components/AgreePersonalData/AgreePersonalData';

import rootStore from 'stores/RootStore';

import * as S from '../../styles';
import AuthHeader from '../AuthHeader';
import Timer from '../Timer';

interface ConfirmCodeProps {
  closeButton?: boolean;
}

const ConfirmCode: FC<ConfirmCodeProps> = ({ closeButton = true }) => {
  const [code, setCode] = useState('');
  const { authStore, modalStore } = rootStore;
  const { phoneNumber } = authStore;

  const handleCodeChange = async (numberCode: string): Promise<void> => {
    setCode(numberCode);
    if (numberCode.length === 4) {
      await authStore.approveLogin({ phone: phoneNumber, code: numberCode });
      if (authStore.status === 'Error') {
        toast.error(authStore.errorMessage || 'Произошла ошибка');
      } else if (authStore.status === 'Success') {
        toast.success('Вы успешно авторизовались');
        modalStore.closeAuthModalModal();
      }
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    await handleCodeChange(code);
  };

  const onReset = () => {
    authStore.handleReset();
  };

  const onResendSms = () => {
    console.log('resendSms');
  };

  return (
    <form onSubmit={handleSubmit}>
      <AuthHeader closeButton={closeButton} title="Код подтверждения" />

      <S.ModalText>
        Мы отправили код на&nbsp;+{phoneNumber}
        <br />
        <S.ModalEditBtn type="button" className="l-link fw-bold" onClick={onReset}>
          Изменить
        </S.ModalEditBtn>
      </S.ModalText>

      <S.ModalInput
        name="code"
        mask="0000"
        lazy={false}
        unmask={true}
        value={code}
        onAccept={(value: string) => handleCodeChange(value)}
        overwrite={true}
      />

      <AgreePersonalData />

      <Timer onResend={onResendSms} />
    </form>
  );
};

export default ConfirmCode;
