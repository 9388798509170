import { createBrowserRouter, RouteObject } from 'react-router-dom';
import App from 'App';
import { RoutesLinks } from 'RoutesLinks';

import { BrandPage, BrandsPage } from 'pages/Brands';
import CartPage from 'pages/Cart';
import Catalog from 'pages/Catalog';
import Compare from 'pages/Compare';
import ErrorBoundary from 'pages/ErrorBoundary';
import Favorite from 'pages/Favorites';
import HomePage from 'pages/HomePage';
import NewProducts from 'pages/NewProducts';
import NotFound from 'pages/NotFound';
import PopularProduct from 'pages/PopularProduct';
import Product from 'pages/Product';
import { Promotion, PromotionSingle } from 'pages/Promotion';
import { Search } from 'pages/Search';

import { appLoader } from 'shared/loaders/appLoader';
import { brandLoader } from 'shared/loaders/brandLoader';
import { brandsLoader } from 'shared/loaders/brandsLoader';
import { cartLoader } from 'shared/loaders/cartLoader';
import { catalogLoader } from 'shared/loaders/catalogLoader';
import { compareLoader } from 'shared/loaders/compareLoader';
import { productLoader } from 'shared/loaders/productLoader';
import { saleLoader } from 'shared/loaders/saleLoader';

export const routes: RouteObject[] = [
  {
    path: RoutesLinks.HOME,
    element: <App />,
    loader: appLoader,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: 'element/:productUrl',
        element: <Product />,
        loader: productLoader,
      },
      {
        path: 'catalog/*',
        element: <Catalog />,
        loader: catalogLoader,
      },
      {
        path: RoutesLinks.CART,
        element: <CartPage />,
        loader: cartLoader,
      },
      {
        path: RoutesLinks.BRANDS,
        element: <BrandsPage />,
        loader: brandsLoader,
      },
      {
        path: `${RoutesLinks.BRANDS}:brandUrl`,
        element: <BrandPage />,
        loader: brandLoader,
      },
      {
        path: `${RoutesLinks.SEARCH}`,
        element: <Search />,
      },
      {
        path: RoutesLinks.PROMOTION,
        element: <Promotion />,
      },
      {
        path: `${RoutesLinks.PROMOTION}:saleUrl`,
        element: <PromotionSingle />,
        loader: saleLoader,
      },
      {
        path: RoutesLinks.NEW,
        element: <NewProducts />,
      },
      {
        path: RoutesLinks.POPULAR,
        element: <PopularProduct />,
      },
      {
        path: RoutesLinks.COMPARE,
        element: <Compare />,
        loader: compareLoader,
      },
      {
        path: RoutesLinks.FAVORITE,
        element: <Favorite />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export const router = createBrowserRouter(routes);
