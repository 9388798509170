import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Badge from 'ui-kit/Badge';

import { pxToVw } from 'shared/helpers/pxToVw';

export const Heading = styled.div`
  margin-bottom: ${pxToVw(24)};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: ${pxToVw(24)};
  margin-bottom: 0;
`;

export const BtnLink = styled(Link)`
  margin-left: ${pxToVw(24)};
  font-size: ${pxToVw(14)};
  font-weight: 700;
  color: var(--primary);
  text-decoration: none;
  align-self: center;
`;

export const HeadingBadge = styled(Badge)`
  margin-left: ${pxToVw(8)};
`;
