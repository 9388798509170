import { FC, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { RoutesLinks } from 'RoutesLinks';
import { A11y, Navigation } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import Loader from 'ui-kit/Loader';
import SectionHeader from 'ui-kit/SectionHeader';

import PromotionsCard from 'components/PromotionsCard';
import SliderArrows from 'components/SliderArrows';

import rootStore from 'stores/RootStore';

import * as S from './styles';

export const bgColor = [
  '244DDF',
  'D2AA8D',
  '59BBED',
  'F86968',
  'FFA43E',
  'C13368',
  '342EDD',
  'CC3202',
  '95755D',
  '181818',
  '8359DF',
];

const PromotionsShowcase: FC = observer(() => {
  const { promotionsStore } = rootStore;
  const swiperRef = useRef<SwiperRef>(null);
  const prevProducts = `prev-products-PromotionsShowcase`;
  const nextProducts = `next-products-PromotionsShowcase`;

  useEffect(() => {
    promotionsStore.loadData();
  }, [promotionsStore]);

  if (promotionsStore.isLoading) return <Loader position="static" />;

  if (promotionsStore.isError) {
    return <div>Error: {promotionsStore.errorMessage}</div>;
  }

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <S.Section>
      <SectionHeader
        pageHeadingTitle="Скидки и акции"
        showAll={true}
        secondaryBtn={false}
        link={RoutesLinks.PROMOTION}
      />

      <S.Carousel>
        <Swiper
          modules={[Navigation, A11y]}
          spaceBetween={24}
          slidesPerView={5}
          navigation={{
            prevEl: `.${prevProducts}`,
            nextEl: `.${nextProducts}`,
          }}
          ref={swiperRef}
        >
          {promotionsStore.data.map((instance, index) => (
            <SwiperSlide key={instance.id}>
              <PromotionsCard data={instance} bgColor={bgColor[index % bgColor.length]} />
            </SwiperSlide>
          ))}
        </Swiper>

        <SliderArrows goPrev={goPrev} goNext={goNext} prevClass={prevProducts} nextClass={nextProducts} />
      </S.Carousel>
    </S.Section>
  );
});

export default PromotionsShowcase;
