import { makeAutoObservable, runInAction } from 'mobx';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';

import { ProductType } from 'shared/types/ProductType';

import api from 'stores/Api';
import { RootStore } from 'stores/RootStore';

export interface PromotionTypes {
  id: number;
  name: string;
  preview_picture: string;
  url: string;
  preview_text: string;
}

interface PromotionsResponse {
  data: PromotionTypes[];
}

class PromotionsStore {
  kiosk = localStorage.getItem('kiosk') || 'default';
  data: PromotionTypes[] = [];
  dataState: IPromiseBasedObservable<ProductType[]> | null = null;
  error: Error | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  async loadData() {
    try {
      const response = await api.get<PromotionsResponse>(`sale?kiosk=${this.kiosk}`);
      runInAction(() => {
        this.data = response.data.data;
        this.dataState = fromPromise(Promise.resolve(this.data));
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('Неизвестная ошибка');
        throw error;
      });
    }
  }

  get errorMessage() {
    return this.error ? this.error.message : null;
  }

  get isLoading() {
    return this.dataState?.state === 'pending';
  }

  get isError() {
    return this.dataState?.state === 'rejected';
  }
}

export default PromotionsStore;
