import api from 'stores/Api';

export const fetchProductsFromCartApi = async (offers?: string) => {
  const payload = offers ? { offers } : {};
  const res = await api.post(`basket`, payload);
  return res;
};

export const addProductToCartApi = async (id: string) => {
  const res = await api.post(`basket/add`, { id });
  return res;
};

export const deleteProductFromCartApi = async (id: string) => {
  const res = await api.post(`basket/remove`, { id });
  return res;
};

export const setQuantityApi = async (method: string, id: string) => {
  const res = await api.post(`basket/${method}`, { id });
  return res;
};
