import { FC, useEffect, useState } from 'react';

interface TimerComponentProps {
  onResend: () => void;
}

const Timer: FC<TimerComponentProps> = ({ onResend }) => {
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  return (
    <div>
      {timer === 0 ? (
        <span className="l-link fw-bold" onClick={onResend}>
          Отправить повторно
        </span>
      ) : (
        <div>
          Введите код подтверждения: <span className="fw-bold">{timer}</span> сек.
        </div>
      )}
    </div>
  );
};

export default Timer;
