import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'ui-kit/Button';
import Sprite from 'ui-kit/Sprite';

import { commonImageStyles } from 'shared/helpers/generalStyles';
import { pxToVw } from 'shared/helpers/pxToVw';
import { truncateMultiLine } from 'shared/helpers/truncateMultiLine';
import { ClassNameProps } from 'shared/types/ClassNameProps';

export const Card = styled.div`
  height: ${pxToVw(160)};
  padding: ${pxToVw(24)};
  margin: ${pxToVw(1)};
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: var(--white);
  border-radius: ${pxToVw(16)};
`;

export const CardMedia = styled(Link)`
  height: ${pxToVw(120)};
  margin-bottom: ${pxToVw(16)};
  display: flex;
`;

export const CardImg = styled.img`
  ${commonImageStyles}
`;

export const CardMeta = styled.div<ClassNameProps>`
  max-width: ${pxToVw(240)};
  height: 100%;
  margin-bottom: ${pxToVw(16)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: ${pxToVw(14)};
  line-height: 1;
`;

export const InputNumberBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const CardTitle = styled(Link)`
  display: inline-flex;
  font-size: ${pxToVw(18)};
  line-height: ${pxToVw(24)};
  ${truncateMultiLine(3)}
`;

export const RemainingItemsCounter = styled.div`
  margin-left: ${pxToVw(16)};
`;

export const PriceBlock = styled.div`
  width: ${pxToVw(180)};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Price = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: ${pxToVw(24)};
  line-height: 1;
  margin-right: auto;
  font-weight: 500;
  font-family: var(--font-family-loos);
`;

export const PriceOld = styled.del`
  font-weight: 700;
  color: var(--text-tertiary);
  font-family: var(--font-family-loos);
`;

export const CardMetaItem = styled(Button)<ClassNameProps>`
  background: none;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.2);
  }
  &.toggle {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: ${pxToVw(8)};

    + &.toggle {
      margin-left: ${pxToVw(2)};
    }

    .icon {
      fill: var(--text-disabled);
    }
  }
`;

export const CardMetaIcon = styled(Sprite)<ClassNameProps>`
  width: ${pxToVw(16)};
  height: ${pxToVw(16)};
  pointer-events: none;
`;

export const CardPrice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const IconBlock = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CardSticker = styled.div`
  position: absolute;
  top: ${pxToVw(16)};
  left: ${pxToVw(18)};
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;
