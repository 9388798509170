import styled, { css } from 'styled-components';
import Sprite from 'ui-kit/Sprite';

import { pxToVw } from 'shared/helpers/pxToVw';

import { ButtonProps } from './ButtonTypes';

export const Btn = styled.button<ButtonProps>`
  --btn-padding-x: 0.75rem;
  --btn-padding-y: 0.375rem;
  --btn-font-family: var(--body-font-family);
  --btn-font-size: #{pxToVw(16px)};
  --btn-font-weight: 700;
  --btn-line-height: 1.5;
  --btn-color: var(--body-color);
  --btn-bg: transparent;
  --btn-border-width: var(--border-width);
  --btn-border-color: transparent;
  --btn-border-radius: var(--border-radius);
  --btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --btn-disabled-opacity: 0.65;
  --btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--btn-focus-shadow-rgb), 0.5);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--btn-padding-y) var(--btn-padding-x);
  font-family: var(--btn-font-family);
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  line-height: var(--btn-line-height);
  color: var(--btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--btn-border-width) solid var(--btn-border-color);
  border-radius: var(--btn-border-radius);
  background-color: var(--btn-bg);
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  ${(props: { size: string }) =>
    props.size === 'lg' &&
    css`
      --btn-padding-y: ${pxToVw(32)};
      --btn-padding-x: ${pxToVw(32)};
      --btn-font-size: ${pxToVw(18)};
    `}

  ${(props: { size: string }) =>
    props.size === 'sm' &&
    css`
      --btn-padding-y: 0.25rem;
      --btn-padding-x: 0.5rem;
    `}

  ${(props: { disabled: string }) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: var(--btn-disabled-opacity);
      color: var(--btn-disabled-color);
      background-color: var(--btn-disabled-bg);
      border-color: var(--btn-disabled-border-color);
    `}

  ${(props: { variant: string }) => {
    if (props.variant) {
      switch (props.variant) {
        case 'primary':
          return css`
            --btn-bg: var(--primary);
            --btn-color: var(--white);
          `;
        case 'secondary':
          return css`
            --btn-bg: var(--secondary);
            --btn-border-color: var(--primary);
            --btn-color: var(--primary);
          `;
        case 'transparent':
          return css`
            --btn-bg: transparent;
          `;
        case 'light':
          return css`
            --btn-bg: var(--white);
            --btn-color: var(--primary);
          `;
        case 'disabled':
          return css`
            --btn-bg: var(--bg-disabled);
            --btn-color: var(--icons);
            pointer-events: none;
          `;
        default:
          return '';
      }
    }
  }}
`;

export const Icon = styled(Sprite)<ButtonProps>`
  width: ${pxToVw(24)};
  height: ${pxToVw(24)};
  margin-right: ${pxToVw(4)};
  flex-shrink: 0;

  ${(props: { variant: string }) => {
    if (props.variant) {
      switch (props.variant) {
        case 'primary':
          return css`
            fill: var(--white);
          `;
        default:
          return css`
            fill: var(--primary);
          `;
      }
    }
  }}
`;
