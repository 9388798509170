import { FC } from 'react';

import * as S from './styles';

interface ToggleProps {
  isVisible: boolean;
  onClick: () => void;
  text: string;
  iconName: string;
}

const ToggleComponent: FC<ToggleProps> = ({ isVisible, onClick, text, iconName }) => (
  <S.Toggle onClick={onClick}>
    {text}
    <S.Icon isVisible={isVisible} icon={iconName} />
  </S.Toggle>
);

export default ToggleComponent;
