import thankYouOrder from 'assets/img/media/thank-you-order.png';
import { observer } from 'mobx-react-lite';

import Container from 'components/Container';

import rootStore from 'stores/RootStore';

import * as S from './styles';

const ThankYouModal = observer(() => {
  const { cartStore } = rootStore;

  const handleCloseModal = () => {
    cartStore.closeThankYouModal();
  };

  return (
    <S.ThankYouModal>
      <Container>
        <S.ThankYouImg src={thankYouOrder} />
        <h1 className="mb-5 text-white">Спасибо за заказ!</h1>

        <S.ThankYouText>Обратитесь к сотруднику магазина для оформления заказа.</S.ThankYouText>

        <S.ThankYouClose onClick={handleCloseModal}>
          <S.ThankYouCloseIcon icon="close" />
        </S.ThankYouClose>
      </Container>
    </S.ThankYouModal>
  );
});

export default ThankYouModal;
