import AuthComponent from 'modules/Authorization';

import * as S from './styles';

const LoginRegisterForm = () => {
  return (
    <S.LoginRegisterContainer>
      <AuthComponent closeButton={false} />
    </S.LoginRegisterContainer>
  );
};

export default LoginRegisterForm;
