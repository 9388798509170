import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Header from 'modules/Header';
import Loader from 'ui-kit/Loader';

import Modals from 'components/Modals';
import InactivityModal from 'components/Modals/InactivityModal';
import NavBar from 'components/NavBar';

import useInactivityRedirect from 'shared/hooks/useInactivityRedirect';

import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  useInactivityRedirect();

  return (
    <HelmetProvider>
      <Header />
      <NavBar />
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
      <ToastContainer />
      <ScrollRestoration />
      <Modals />
      <InactivityModal />
    </HelmetProvider>
  );
};

export default App;
