import styled, { css } from 'styled-components';
import Badge from 'ui-kit/Badge';
import Button from 'ui-kit/Button';
import Sprite from 'ui-kit/Sprite';

import Container from 'components/Container';

import { commonImageStyles } from 'shared/helpers/generalStyles';
import { pxToVw } from 'shared/helpers/pxToVw';

interface QuickProductOrderProps {
  readonly $isFixed: boolean;
}

export const Quick = styled.nav<QuickProductOrderProps>`
  position: fixed;
  left: 0;
  z-index: 19;
  height: ${pxToVw(208)};
  width: 100%;
  padding: ${pxToVw(14)} 0 ${pxToVw(84)};
  bottom: 0;
  background-color: var(--white);
  border-radius: ${pxToVw(16)};
  box-shadow: 0 0 12px rgb(4 4 4 / 25%);
  overflow: hidden;
  transform: translateY(${(props: QuickProductOrderProps) => (props.$isFixed ? '0' : '100%')});
  transition: transform 0.2s ease-in-out;
  will-change: transform;
`;

export const QuickContainer = styled(Container)`
  display: flex;
  align-items: center;
`;

export const QuickMedia = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: ${pxToVw(96)};
  height: ${pxToVw(96)};
`;

export const QuickMediaImg = styled.img`
  ${commonImageStyles};
`;

export const QuickTitle = styled.div`
  max-width: 35%;
  margin-left: 4.5%;
  padding-right: ${pxToVw(20)};
  font-size: ${pxToVw(18)};
  line-height: ${pxToVw(24)};
`;

export const QuickPrices = styled.div`
  width: 100%;
  max-width: 16%;
  margin-right: 3%;
  margin-left: ${pxToVw(32)};
`;

export const QuickPricesRow = styled.div`
  width: ${pxToVw(165)};
  margin-right: ${pxToVw(18)};
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: ${pxToVw(4)};
  }
`;

export const QuickPrice = styled.div`
  font-size: ${pxToVw(24)};
  margin-right: auto;
  font-weight: 700;
  line-height: 1;
  font-family: var(--font-sans-loos);
`;

export const QuickDiscount = styled(Badge)``;

export const QuickPriceOld = styled.del`
  color: var(--text-tertiary);
  font-size: ${pxToVw(17)};
  font-family: var(--font-sans-loos);
`;

export const QuickBonus = styled.div`
  color: var(--primary);
  display: flex;
  align-items: center;
  margin-left: ${pxToVw(12)};
`;

export const QuickBonusIcon = styled(Sprite)`
  width: ${pxToVw(32)};
  position: relative;
  top: -2px;
  fill: var(--primary);
`;

export const QuickBtn = styled(Button)<{ isLoading: boolean }>`
  width: ${pxToVw(180)};
  height: ${pxToVw(52)};
  font-size: ${pxToVw(18)};
  margin-left: auto;
  ${({ isLoading }: { isLoading: boolean }) =>
    isLoading &&
    css`
      background-color: var(--blue-active);
    `}
`;
