import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';
import Badge from 'ui-kit/Badge';

import Container from 'components/Container';

import { hexToRgb } from 'shared/helpers/hexToRgb';
import { pxToVw } from 'shared/helpers/pxToVw';
import { colors } from 'shared/helpers/vars';
import { ClassNameProps } from 'shared/types/ClassNameProps';

export const TabsContainer = styled(Container)`
  width: 70%;
  padding-left: ${pxToVw(0)};
  padding-right: ${pxToVw(0)};
  margin: ${pxToVw(12)} ${pxToVw(0)};
  .prev,
  .next {
    background-color: rgba(${hexToRgb(colors.bgLight)}, 0.7);
  }
`;

export const NavBarInner = styled.div<ClassNameProps>`
  position: relative;
`;

export const TabContainer = styled(SwiperSlide)`
  padding: ${pxToVw(8)};
  width: fit-content;
  white-space: nowrap;
  &.active {
    font-weight: 700;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${pxToVw(2)};
    background-color: var(--primary);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &.active::after {
    opacity: 1;
  }
`;

export const TabTitle = styled.span`
  margin-right: 4px;
`;

export const TabCount = styled(Badge)`
  margin-left: ${pxToVw(8)};
`;
