import { FC, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { observer } from 'mobx-react-lite';
import GalleryCarousel from 'modules/Product/components/GalleryCarousel';
import { Swiper as SwiperType } from 'swiper';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

import FancyboxWrapper from 'components/FancyboxWrapper';

import rootStore from 'stores/RootStore';

import 'react-loading-skeleton/dist/skeleton.css';

import * as S from './styles';

const ProductCarousel: FC = observer(() => {
  const { baseProductPropertiesStore } = rootStore;
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);
  const [isLoadingImages, setIsLoadingImages] = useState(true);

  const galleryData = baseProductPropertiesStore.getGalleryData();

  useEffect(() => {
    const images = galleryData.map((src) => {
      const img = new Image();
      img.src = src;
      return img;
    });

    Promise.all(
      images.map((img) => {
        return new Promise<void>((resolve) => {
          img.onload = () => resolve();
        });
      })
    ).then(() => {
      setIsLoadingImages(false);
    });
  }, [galleryData]);

  return (
    <S.ProductCarousel>
      <FancyboxWrapper
        options={{
          Carousel: {
            infinite: false,
          },
        }}
      >
        <S.ProductMainCarousel
          modules={[FreeMode, Navigation, Thumbs]}
          spaceBetween={0}
          slidesPerView={1}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
        >
          {galleryData.map((imgUrl, index) => (
            <S.ProductMainItem key={index}>
              <S.ProductMainItemBox data-fancybox="gallery" href={imgUrl}>
                {baseProductPropertiesStore.isLoading || isLoadingImages ? (
                  <S.ProductCarouselSkeleton>
                    <Skeleton height="100%" />
                  </S.ProductCarouselSkeleton>
                ) : (
                  <S.ProductCarouselImg src={imgUrl} alt="" />
                )}
              </S.ProductMainItemBox>
            </S.ProductMainItem>
          ))}
        </S.ProductMainCarousel>
      </FancyboxWrapper>

      {galleryData.length > 1 && <GalleryCarousel data={galleryData} onSwiperCallback={setThumbsSwiper} />}
    </S.ProductCarousel>
  );
});

export default ProductCarousel;
