import { FC, ReactNode, useState } from 'react';

import * as S from './styles';

type AlertProps = {
  children: ReactNode;
  timeout?: number;
  clear?: boolean;
};

const Alert: FC<AlertProps> = ({ children, clear = true }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleCloseAlert = () => {
    setIsOpen(!isOpen);
  };

  return (
    <S.Alert $isOpenAlert={isOpen}>
      <div>
        <S.WarningIcon icon="warning" />
        {children}
      </div>
      {clear && (
        <S.Btn onClick={handleCloseAlert}>
          <S.ClearIcon icon="close" />
        </S.Btn>
      )}
    </S.Alert>
  );
};

export default Alert;
