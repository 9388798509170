import { FC, useRef } from 'react';
import { A11y, Navigation } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import SectionHeader from 'ui-kit/SectionHeader';

import ProductCard from 'components/ProductCard';
import SliderArrows from 'components/SliderArrows';

import { ProductType } from 'shared/types/ProductType';
import { SectionHeaderProps } from 'shared/types/SectionHeaderProps';

import * as S from './styles';

const CaseProducts: FC<SectionHeaderProps & { idSlider: string; products: ProductType[] }> = ({
  pageHeadingTitle,
  link,
  showAll,
  secondaryBtn,
  badgeCount,
  pageHeadingAllText,
  idSlider,
  products,
}) => {
  const swiperRef = useRef<SwiperRef>(null);
  const prevProducts = `prev-products-${idSlider}`;
  const nextProducts = `next-products-${idSlider}`;

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  if (products.length <= 1) {
    return null;
  }

  return (
    <S.Section>
      <SectionHeader
        pageHeadingTitle={pageHeadingTitle}
        showAll={showAll}
        secondaryBtn={secondaryBtn}
        badgeCount={badgeCount}
        pageHeadingAllText={pageHeadingAllText}
        link={link}
      />

      <S.Carousel>
        <Swiper
          modules={[Navigation, A11y]}
          spaceBetween={32}
          slidesPerView={3}
          navigation={{
            prevEl: `.${prevProducts}`,
            nextEl: `.${nextProducts}`,
          }}
          ref={swiperRef}
        >
          {products.map((instance) =>
            instance.cost_params?.cost === 0 ? null : (
              <SwiperSlide key={instance.id}>
                <ProductCard data={instance} />
              </SwiperSlide>
            )
          )}
        </Swiper>

        <SliderArrows goPrev={goPrev} goNext={goNext} prevClass={prevProducts} nextClass={nextProducts} />
      </S.Carousel>
    </S.Section>
  );
};

export default CaseProducts;
