import { Link } from 'react-router-dom';
import Checkbox from 'ui-kit/Checkbox';

import * as S from './styles';

const AgreePersonalData = () => {
  return (
    <Checkbox id="iAgreeConfirmCode" defaultChecked={true}>
      <S.Agree>
        Я выражаю согласие{' '}
        <Link
          to="https://vsesmart.ru/upload/docs/processing-of-personal-data-1.pdf"
          className="l-link"
          target="_blank"
          rel="noopener nofolow"
        >
          на&nbsp;обработку персональных данных
        </Link>
      </S.Agree>
    </Checkbox>
  );
};

export default AgreePersonalData;
