import { makeAutoObservable, runInAction } from 'mobx';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';

import { NavbarItem, NavbarResponse } from 'shared/types/NavbarProperty';

import api from 'stores/Api';

import { RootStore } from './RootStore';

class NavBarStore {
  data: NavbarItem[] | null = null;
  dataState: IPromiseBasedObservable<NavbarItem[] | null> | null = null;
  error: Error | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  async loadData() {
    try {
      const response = await api.get<NavbarResponse>('navbar');
      runInAction(() => {
        this.data = response.data.data;
        this.dataState = fromPromise(Promise.resolve(this.data));
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('Неизвестная ошибка');
        this.dataState = fromPromise(Promise.reject(this.error));
      });
    }
  }

  get errorMessage() {
    return this.error ? this.error.message : null;
  }

  get isLoading() {
    return this.dataState?.state === 'pending';
  }

  get isError() {
    return this.dataState?.state === 'rejected';
  }
}

export default NavBarStore;
