import { FC } from 'react';
import sprite from 'assets/img/icons.svg';

import { SpriteProps } from 'shared/types/SpriteProps';

const Sprite: FC<SpriteProps> = ({ icons = sprite, icon, className, styles }) => {
  return (
    <svg className={className} style={styles}>
      <use href={`${icons}#${icon}`} />
    </svg>
  );
};

export default Sprite;
