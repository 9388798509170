import { makeAutoObservable } from 'mobx';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';

import { FastTagElement } from 'shared/types/FastTagTypes';

import api from 'stores/Api';

import { RootStore } from './RootStore';

class FastTagStore {
  kiosk = localStorage.getItem('kiosk') || 'default';
  elements?: IPromiseBasedObservable<FastTagElement[] | void>;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  loadData() {
    const promise = api
      .get(`short-tag-sections?kiosk=${this.kiosk}`)
      .then((response) => {
        const backendElements: FastTagElement[] = response.data.data;

        const elements: FastTagElement[] = backendElements.map((element: FastTagElement) => ({
          id: element.id,
          name: element.name,
          preview_picture: element.preview_picture,
          url: element.url,
        }));

        return elements;
      })
      .catch((error) => {
        console.error(error);
        return [];
      });

    this.elements = fromPromise(promise);
  }
}

export default FastTagStore;
