import { fetchTradeOfferApi } from 'entities/Product/services/productApi';
import { makeAutoObservable, runInAction } from 'mobx';

import { Color, ProductOptions, Variation } from 'shared/types/ProductTradeOfferStoreTypes';

import { RootStore } from 'stores/RootStore';

class ProductTradeOfferStore {
  colors: Color[] = [];
  variations: Variation[] = [];
  isLoading: boolean = false;
  activeColorId: string | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setProducts(colors: Color[]) {
    this.colors = colors;
  }

  setVariations(variations: Variation[]) {
    this.variations = variations;
  }

  setActiveColorId(productId: string) {
    const activeColor = this.colors.find((color) => {
      const urlProductId = color.url.split('/').filter(Boolean).pop();
      return urlProductId === productId;
    });

    this.activeColorId = activeColor ? productId : null;
  }

  async fetchProducts(productId: string) {
    this.isLoading = true;

    try {
      const response = await fetchTradeOfferApi(productId);
      if (response) {
        runInAction(() => {
          const productOptions: ProductOptions = response.data.data;
          this.setProducts(productOptions.colors);
          this.setVariations(productOptions.variations);
          this.setActiveColorId(productId);
        });
      } else {
        console.warn(`Нет торговых предложений для продукта с ID ${productId}`);
        runInAction(() => {
          this.colors = [];
          this.variations = [];
        });
      }
    } catch (error) {
      console.error('Ошибка при получении товаров:', error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  isColorActive(color: Color) {
    const urlProductId = color.url.split('/').filter(Boolean).pop();
    return urlProductId === this.activeColorId;
  }
}

export default ProductTradeOfferStore;
