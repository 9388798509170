import { CatalogShowcaseStoreType, ChildCategory } from 'entities/Catalog/types';
import { makeAutoObservable, runInAction } from 'mobx';

import api from 'stores/Api';
import { RootStore } from 'stores/RootStore';

class CatalogShowcaseStore {
  kiosk = localStorage.getItem('kiosk') || 'default';
  data: ChildCategory[] | null = null;
  isLoading = false;
  error: Error | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  async loadData() {
    try {
      this.isLoading = true;
      const response = await api.get<CatalogShowcaseStoreType>(`main_cats?kiosk=${this.kiosk}`);
      runInAction(() => {
        this.data = response.data.data;
        this.isLoading = false;
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('Неизвестная ошибка');
        this.isLoading = false;
        throw error;
      });
    }
  }
}

export default CatalogShowcaseStore;
