import {
  fetchQuestionsApi,
  fetchReviewsApi,
  fetchReviewsMediaApi,
} from 'entities/ReviewsQuestions/services/reviewsQuestionsApi';
import { IMeta, IReviewQuestion } from 'entities/ReviewsQuestions/types';
import { makeAutoObservable, runInAction } from 'mobx';

import { RootStore } from 'stores/RootStore';

class ProductFeedbackStore {
  reviews: IReviewQuestion[] = [];
  questions: IReviewQuestion[] = [];
  media: string[] = [];
  meta: IMeta | null = null;
  error: Error | null = null;
  selectedIndex = 0;
  reviewsPage = 1;
  currentProductId: string | null = null;
  tempReviews: IReviewQuestion[] = [];
  lastPage: number | null = null;
  reviewsTotalNum: number | null = null;
  isLoadingReviews: boolean = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  async fetchReviews(productId: string) {
    // Проверка, необходима ли смена продукта или это подгрузка новых отзывов
    const isNewProduct = this.currentProductId !== productId;
    if (isNewProduct) {
      this.currentProductId = productId;
      this.reviewsPage = 1; // Сброс страницы отзывов при смене продукта
    }
    this.isLoadingReviews = true;

    try {
      const response = await fetchReviewsApi(productId, this.reviewsPage);
      runInAction(() => {
        // Добавляем загруженные отзывы во временное хранилище
        this.tempReviews = response.data.data;

        // Перемещаем отзывы из временного хранилища в основной список после загрузки
        // Это позволит избежать исчезновения отзывов во время загрузки
        if (this.reviewsPage > 1) {
          this.reviews = [...this.reviews, ...this.tempReviews];
        } else {
          this.reviews = [...this.tempReviews];
        }

        this.meta = response.data.meta;
        this.lastPage = response.data.meta.last_page;
        this.error = null;
        this.reviewsTotalNum = response.data.meta.total;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('Неизвестная ошибка');
        throw error;
      });
    } finally {
      runInAction(() => {
        this.isLoadingReviews = false;
        this.tempReviews = []; // Очищаем временное хранилище после обновления основного списка
      });
    }
  }

  async fetchQuestions(productId: string) {
    try {
      const response = await fetchQuestionsApi(productId);
      runInAction(() => {
        this.questions = response.data.data;
        this.meta = response.data.meta;
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('Неизвестная ошибка');
        throw error;
      });
    }
  }

  async fetchReviewsMedia(productId: string) {
    try {
      const response = await fetchReviewsMediaApi(productId);
      runInAction(() => {
        this.media = response.data.data;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('Неизвестная ошибка');
        throw error;
      });
    }
  }

  get totalReviews() {
    return this.reviewsTotalNum;
  }

  get totalQuestions() {
    return this.questions.length;
  }

  get totalMediaReviews() {
    return this.media.length;
  }

  get listMedias() {
    return this.media;
  }

  get listReviews() {
    return this.reviews;
  }

  get listQuestions() {
    return this.questions;
  }

  setSelectedIndex(index: number) {
    this.selectedIndex = index;
  }

  loadMoreReviews() {
    if (this.lastPage && this.reviewsPage < this.lastPage && this.currentProductId) {
      this.reviewsPage++;
      this.fetchReviews(this.currentProductId);
    }
  }
}

export default ProductFeedbackStore;
