import styled from 'styled-components';
import { BadgeProps } from 'ui-kit/Badge';

import { pxToVw } from 'shared/helpers/pxToVw';

const colorMap = {
  warn: 'var(--warn)',
  primary: 'var(--primary)',
  new: 'var(--lime)',
  hit: 'var(--teal)',
};

export const BadgeEl = styled.span<BadgeProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  text-align: center;
  border-radius: ${pxToVw(4)};
  cursor: pointer;
  color: var(--white);
  min-width: ${pxToVw(22)};
  ${(props: { color: keyof typeof colorMap }) => props.color && `background-color: ${colorMap[props.color]}`};

  ${(props: { size: string }) =>
    props.size !== 'large' &&
    `
    height: ${pxToVw(22)};
    padding: ${pxToVw(1)} ${pxToVw(4)};
    font-size: ${pxToVw(12)};
  `}

  ${(props: { size: string }) =>
    props.size === 'large' &&
    `
    height: ${pxToVw(26)};
    padding: ${pxToVw(1)} ${pxToVw(10)};
    font-size: ${pxToVw(16)};
  `}
`;
