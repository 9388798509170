import styled from 'styled-components';
import Sprite from 'ui-kit/Sprite';

import { pxToVw } from 'shared/helpers/pxToVw';

export const ModalHeader = styled.header`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${pxToVw(16)};
`;

export const ModalTitle = styled.h3`
  margin-bottom: 0;
  flex-grow: 1;
  padding-right: ${pxToVw(24)};
  font-size: ${pxToVw(18)};
  line-height: ${pxToVw(24)};
`;

export const ModalClose = styled.div`
  margin-top: ${pxToVw(-8)};
  margin-right: ${pxToVw(-8)};
  padding: ${pxToVw(8)};
`;

export const ModalCloseIcon = styled(Sprite)`
  width: ${pxToVw(24)};
  height: ${pxToVw(24)};
  fill: var(--text-tertiary);
`;
