import { fetchViewedProductsApi } from 'entities/Product/services/productApi';
import { makeAutoObservable, runInAction } from 'mobx';

import { RootStore } from 'stores/RootStore';

interface ViewedProduct {
  id: number;
  name: string;
  url: string;
}

class ViewedProductsStore {
  viewedData: ViewedProduct[] = [];
  isLoading = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  async fetchViewedProducts(productIds: number[]) {
    this.isLoading = true;

    try {
      const response = await fetchViewedProductsApi(productIds);
      runInAction(() => {
        this.viewedData = response.data.data;
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
    }
  }
}

export default ViewedProductsStore;
