import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import rootStore from 'stores/RootStore';

import CatalogSection from './CatalogSection';
import FiltersSection from './FiltersSection';
import * as S from './styles';

const CatalogFiltersSection = observer(() => {
  const { filterCatalogStore } = rootStore;
  const location = useLocation();

  useEffect(() => {
    const currentPathname = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    const currentQuery = searchParams.get('q') || '';

    if (filterCatalogStore.path !== currentPathname || filterCatalogStore.query !== currentQuery) {
      filterCatalogStore.updatePath(currentPathname, currentQuery);
    }
  }, [location.pathname, location.search, filterCatalogStore]);

  return (
    <S.Block>
      <FiltersSection />
      <CatalogSection />
    </S.Block>
  );
});

export default CatalogFiltersSection;
