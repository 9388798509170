import styled, { css } from 'styled-components';

import { pxToVw } from 'shared//helpers/pxToVw';

export const commonImageStyles = css`
  margin: auto;
  max-height: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: ${pxToVw(-8)};
  margin-right: ${pxToVw(-8)};
  padding-bottom: ${pxToVw(32)};
`;

export const Card = styled.div`
  width: 33.3333%;
  flex: 0 0 33.3333%;
  padding-left: ${pxToVw(8)};
  padding-right: ${pxToVw(8)};
  margin-bottom: ${pxToVw(16)};
`;

export const TextWarn = styled.span`
  color: var(--warn);
`;
