import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';

export const CatalogContainer = styled.div`
  padding-left: ${pxToVw(24)};
  flex-grow: 1;
`;

export const ProductContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ProductCardContainer = styled.div`
  width: calc(50% - 12px);
  margin-bottom: ${pxToVw(16)};
`;

export const DropContainer = styled.div`
  margin-bottom: ${pxToVw(16)};
`;

export const EmptyContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: ${pxToVw(16)};
`;

export const Title = styled.div`
  display: inline-flex;
  font-weight: 700;
  font-size: ${pxToVw(14)};
  line-height: ${pxToVw(24)};
`;
