import { Link } from 'react-router-dom';
import bg from 'assets/img/bg/not-fount.jpg';
import styled from 'styled-components';

import { Container } from 'components/Container/styles';

import { pxToVw } from 'shared/helpers/pxToVw';
import { ClassNameProps } from 'shared/types/ClassNameProps';

export const NotFound = styled.section<ClassNameProps>`
  position: relative;
  margin-top: ${pxToVw(-25)};
  padding-top: ${pxToVw(46)};
  color: #fff;
  height: 674px;
  background: url(${bg}) no-repeat center top;
`;

export const NotFoundContainer = styled(Container)`
  height: 100%;
  margin-bottom: ${pxToVw(40)};
`;

export const Title = styled.h1<ClassNameProps>`
  margin-bottom: ${pxToVw(14)};
  color: inherit;
  font-size: ${pxToVw(32)};
  line-height: ${pxToVw(40)};
`;

export const Text = styled.div<ClassNameProps>`
  margin-bottom: ${pxToVw(16)};
  width: ${pxToVw(550)};
  font-size: ${pxToVw(18)};
  line-height: ${pxToVw(24)};
`;

export const Links = styled(Link)<ClassNameProps>`
  color: inherit;
  text-decoration: underline;
  width: ${pxToVw(550)};
  font-size: ${pxToVw(18)};
  line-height: ${pxToVw(24)};
`;

export const Decor = styled.img<ClassNameProps>`
  position: relative;
  display: block;
  margin-right: auto;
  margin-left: auto;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  animation: rotating 2s linear infinite;
  left: ${pxToVw(40)};
  margin-top: ${pxToVw(86)};
  --px: ${pxToVw(-40)};
`;

export const Block = styled.div<ClassNameProps>``;
