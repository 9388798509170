import { observer } from 'mobx-react-lite';
import HeaderAccount from 'modules/Header/components/HeaderAccount';
import Alert from 'ui-kit/Alert';

import rootStore from 'stores/RootStore';

import FavoritesProductsContainer from '../components/FavoritesProductsContainer';
import * as S from './styles';

export const FavoritesPage = observer(() => {
  const { favoritesStore, authStore } = rootStore;

  if (favoritesStore.favoritesData.length < 1) {
    return (
      <S.Block>
        {!authStore.isAuth && (
          <Alert clear={false}>
            Чтобы не потерять все что вы собрали, лучше взять и <HeaderAccount variant="button" />
          </Alert>
        )}
        <S.Text>Пока здесь нет избранных товаров.</S.Text>
      </S.Block>
    );
  }

  return (
    <S.FlexBlock>
      <S.FavoritesContainer>
        <FavoritesProductsContainer />
      </S.FavoritesContainer>
    </S.FlexBlock>
  );
});
