export const propertyCodes = {
  brand: 'BRAND',
  countReviews: 'COUNT_REVIEWS',
  countRating: 'RATING',
  galleryPhoto: 'MORE_PHOTO',
  article: 'CML2_ARTICLE',
  stickers: 'STICKERS',
  colors: 'TSVET',
  newProduct: 'NEW_DATE_REMOVAL',
  emkostAkkumulyatoraMah: 'EMKOST_AKKUMULYATORA_MAH',
  navigatsiya: 'NAVIGATSIYA',
  datchiki: 'DATCHIKI',
  tipAkkumulyatora: 'TIP_AKKUMULYATORA',
  bistrayaZaryadka: 'BISTRAYA_ZARYADKA',
  tip: 'TIP',
  klassZashchityIp: 'KLASS_ZASHCHITY_IP',
  osobennosty: 'OSOBENNOSTY',
  tipDispleya: 'TIP_DISPLEYA',
  tylovayaKameraMpiks: 'TYLOVAYA_KAMERA_MPIKS',
  nfc: 'NFC',
  biometricheskayaZashita: 'biometricheskaya_zashita',
  avitoAdvantage: 'AVITO_ADVANTAGE',
  plotnostPikseley: 'PLOTNOST_PIKSELEY',
  godVypuska: 'GOD_VYPUSKA',
  cml2ManufacturerOld: 'CML2_MANUFACTURER_OLD',
  cml2BaseUnit: 'CML2_BASE_UNIT',
  operatsionnayaSistema: 'OPERATSIONNAYA_SISTEMA',
  podderzhkaOtg: 'PODDERZHKA_OTG',
  podderzhka2g: 'PODDERZHKA_2G',
  standartiSvyazi: 'STANDARTI_SVYAZI',
  cml2Traits: 'CML2_TRAITS',
  kolichestvoOsnovnyhKamer: 'KOLICHESTVO_OSNOVNYH_KAMER',
  firmenniyTsvet: 'FIRMENNIY_TSVET',
  cml2Taxes: 'CML2_TAXES',
  podderzhkaLte: 'PODDERZHKA_LTE',
  stranaProizvoditel: 'STRANA_PROIZVODITEL',
  portyIRazyemy: 'PORTY_I_RAZYEMY',
  starayaTsena: 'STARAYA_TSENA',
  versiyaBluetooth: 'VERSIYA_BLUETOOTH',
  versiyaOperatsionnoySistemy: 'VERSIYA_OPERATSIONNOY_SISTEMY',
  obemVnutrenneyPamyatiGb: 'OBEM_VNUTRENNEY_PAMYATI_GB',
  razreshenieEkrana: 'RAZRESHENIE_EKRANA',
  vesKg: 'VES_KG',
  operativnayaPamyatRam: 'OPERATIVNAYA_PAMYAT_RAM',
  new: 'NEW',
  standartWiFi: 'STANDART_WI_FI',
  kolichestvoYaderProtsessora: 'KOLICHESTVO_YADER_PROTSESSORA',
  frontalnayaKameraMpiks: 'FRONTALNAYA_KAMERA_MPIKS',
  nalichie3g: 'NALICHIE_3G',
  slotYDlyaKartyPamyati: 'SLOT_Y_DLYA_KARTY_PAMYATI',
  chastotaProtsessoraGhz: 'CHASTOTA_PROTSESSORA_GHZ',
  model: 'MODEL',
  vysotaMm: 'VYSOTA_MM',
  shirinaMm: 'SHIRINA_MM',
  glubinaMm: 'GLUBINA_MM',
  filterColor: 'FILTER_COLOR',
  vesG: 'VES_G',
  vstroennayaPamyat: 'VSTROENNAYA_PAMYAT',
  zashitaUstroystva: 'ZASHITA_USTROYSTVA',
  garantiya: 'GARANTIYA',
  gabariti: 'GABARITI',
  diagonal: 'DIAGONAL',
  cml2BarCode: 'CML2_BAR_CODE',
  inStock: 'IN_STOCK',
  DOCUMENTS: 'DOCUMENTS',
  CML2_BASE_UNIT: 'CML2_BASE_UNIT',
  SERVICE_SECTION: 'SERVICE_SECTION',
  AVAILABLE_REGION_CENTRAL: 'AVAILABLE_REGION_CENTRAL',
  AVAILABLE_REGION_0000073738: 'AVAILABLE_REGION_0000073738',
  AVAILABLE_REGION_0000103664: 'AVAILABLE_REGION_0000103664',
  AVAILABLE_REGION_0000445112: 'AVAILABLE_REGION_0000445112',
  AVAILABLE_REGION_0000293598: 'AVAILABLE_REGION_0000293598',
  AVAILABLE_REGION_0000812044: 'AVAILABLE_REGION_0000812044',
  AVAILABLE_REGION_0000386590: 'AVAILABLE_REGION_0000386590',
  AVAILABLE_REGION_0000600317: 'AVAILABLE_REGION_0000600317',
  AVAILABLE_REGION_0000650509: 'AVAILABLE_REGION_0000650509',
  AVAILABLE_REGION_0000394020: 'AVAILABLE_REGION_0000394020',
  AVAILABLE_REGION_0000896058: 'AVAILABLE_REGION_0000896058',
  AVAILABLE_REGION_0000949228: 'AVAILABLE_REGION_0000949228',
  AVAILABLE_REGION_0000670178: 'AVAILABLE_REGION_0000670178',
};
