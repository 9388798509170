import styled from 'styled-components';
import Sprite from 'ui-kit/Sprite';

import Container from 'components/Container';

import { pxToVw } from 'shared/helpers/pxToVw';

export const Nav = styled.nav`
  bottom: 0;
  display: flex;
  height: ${pxToVw(84)};
  left: 0;
  position: fixed;
  z-index: 20;
  align-items: center;
  background-color: var(--white);
  box-shadow: var(--shadow-Z300);
  overflow: hidden;
  width: 100%;
`;

export const NavContainer = styled(Container)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 ${pxToVw(16)};
`;

export const NavItem = styled.div`
  width: calc(100% / 5);
  color: var(--text-secondary);
  font-size: ${pxToVw(12)};
  line-height: ${pxToVw(16)};
  text-align: center;
`;

export const NavItemDisabled = styled(NavItem)`
  opacity: 0.2;
  pointer-events: none;
  cursor: default;
`;

export const NavItemBasket = styled(NavItem)`
  position: relative;

  &::before {
    width: ${pxToVw(60)};
    height: ${pxToVw(60)};
    border-radius: 50%;
    background-color: var(--primary);
    position: absolute;
    top: ${pxToVw(-6)};
    left: 50%;
    transform: translateX(-50%);
    content: '';
  }

  svg {
    width: ${pxToVw(40)};
    height: ${pxToVw(40)};
    top: ${pxToVw(5)};
    fill: var(--white);
  }
`;

export const NavItemBasketCount = styled.span`
  position: absolute;
  z-index: 1;
  top: ${pxToVw(-5)};
  right: ${pxToVw(-10)};
  width: ${pxToVw(18)};
  height: ${pxToVw(20)};
  border-radius: ${pxToVw(4)};
  font-size: ${pxToVw(14)};
  color: var(--white);
  background-color: var(--warn);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavItemInner = styled.div`
  position: relative;
  display: inline-block;
`;

export const NavItemIcon = styled(Sprite)`
  display: block;
  width: ${pxToVw(24)};
  height: ${pxToVw(24)};
  margin: 0 auto ${pxToVw(6)};
  fill: var(--text-tertiary);
  position: relative;
  z-index: 1;
`;
