import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BadgeEl } from 'ui-kit/Badge/styles';
import Button from 'ui-kit/Button';

import { Container } from 'components/Container/styles';

import { pxToVw } from 'shared/helpers/pxToVw';
import { ClassNameProps } from 'shared/types/ClassNameProps';

export const Header = styled.header<ClassNameProps>`
  background-color: var(--white);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 25;
  box-shadow: var(--shadow-Z100);

  ${BadgeEl} {
    margin-left: ${pxToVw(8)};
  }
`;

export const HeaderInner = styled(Container)`
  height: ${pxToVw(80)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderLogo = styled(Link)`
  width: ${pxToVw(171)};
  height: ${pxToVw(47)};
  flex-shrink: 0;
`;

export const HeaderLogoImg = styled.img`
  width: inherit;
  height: inherit;
`;

export const ToggleCategory = styled(Button)`
  margin-right: ${pxToVw(16)};
  margin-left: ${pxToVw(16)};
  width: ${pxToVw(48)};
  height: ${pxToVw(52)};
  flex-shrink: 0;
`;

export const ToggleCategoryDecor = styled.span<ClassNameProps>`
  position: relative;
  height: ${pxToVw(2)};
  margin: auto;
  background-color: var(--white);
  border-radius: ${pxToVw(4)};
  transition-delay: 0.2s;
  transition-duration: 0.2s;
  width: ${pxToVw(22)};

  &::before,
  &::after {
    position: absolute;
    left: 0;
    width: inherit;
    height: inherit;
    background-color: var(--white);
    border-radius: ${pxToVw(1)};
    transition:
      transform 0.2s,
      top 0.2s 0.2s;
    transition-duration: 0.2s;
    content: '';
  }

  &::before {
    top: ${pxToVw(-7)};
  }

  &::after {
    top: ${pxToVw(7)};
  }

  &.active {
    background: 0 0;
    transition-delay: 0.2s;
    transition-duration: 0.1s;

    &::after,
    &::before {
      top: 0;
      border-radius: ${pxToVw(4)};
      left: ${pxToVw(0)};
    }

    &::before {
      transform: rotateZ(-45deg);
      transition:
        top 0.2s,
        transform 0.2s 0.2s;
    }

    &::after {
      transform: rotateZ(45deg);
      transition:
        top 0.4s,
        transform 0.2s 0.2s;
    }
  }
`;

export const HeaderState = styled(Link)<ClassNameProps>`
  margin-right: ${pxToVw(16)};
  display: flex;
  align-items: center;
  flex-shrink: 0;

  &.active .icon {
    fill: var(--text-primary);
  }

  .icon {
    width: ${pxToVw(24)};
    height: ${pxToVw(24)};
    fill: var(--text-tertiary);
  }
`;

export const HeaderStateDisabled = styled(HeaderState)`
  opacity: 0.2;
  pointer-events: none;
  cursor: default;
`;
