import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import SectionHeader from 'ui-kit/SectionHeader';

import Container from 'components/Container';

import { appLoader } from 'shared/loaders/appLoader';

import rootStore from 'stores/RootStore';

import CartProductsContainer from '../components/CartProductsContainer';
import CartTotal from '../components/CartTotal';
import LoginRegisterForm from '../components/LoginRegisterForm';
import ThankYouModal from '../components/ThankYouModal';
import UserProfileInfo from '../components/UserProfileInfo';
import * as S from './styles';

export const CartPage = observer(() => {
  const { authStore, cartStore } = rootStore;

  useEffect(() => {
    const loadData = async () => {
      const url = window.location.href;
      const request = new Request(url);
      await appLoader({ request, params: {} });
    };

    loadData();
  }, [authStore.isAuth]);

  return (
    <Container>
      <SectionHeader
        showAll={false}
        badgeCount={cartStore.productsData.length}
        pageHeadingTitle={`Корзина${!cartStore.productsData.length ? ' пуста' : ''}`}
      />
      <S.Block>
        <S.CartContainer>
          <CartProductsContainer />
          {authStore.isAuth ? <UserProfileInfo userInfo={authStore.userInfo} /> : <LoginRegisterForm />}
        </S.CartContainer>

        <CartTotal />
      </S.Block>

      {cartStore.isOpenThankYouModal && <ThankYouModal />}
    </Container>
  );
});
