import { ComparisonResponse, ComparisonResponseMini } from 'modules/Comparison/types/types';

import api from 'stores/Api';

export const fetchProductsFromCompareApi = async (compareIds?: string) => {
  const params = compareIds ? { compare: compareIds } : {};

  const response = await api.post<ComparisonResponse>('compare', params);
  return response;
};

export const fetchProductsFromCompareMiniApi = async (compareIds?: string) => {
  const params = compareIds ? { compare: compareIds } : {};

  const response = await api.post<ComparisonResponseMini>('compare/mini', params);
  return response;
};

export const addProductToCompareApi = async (id: number) => {
  const response = await api.post<ComparisonResponse>('compare/add', { id });
  return response;
};

export const deleteProductFromCompareApi = async (id: number) => {
  const response = await api.post<ComparisonResponse>('compare/remove', { id });
  return response;
};
