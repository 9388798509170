import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import Loader from 'ui-kit/Loader';

import rootStore from 'stores/RootStore';

import * as S from './styles';

const UserControl = observer(() => {
  const { modalStore, authStore } = rootStore;

  const handleLogout = async () => {
    await authStore.logout();
    if (!authStore.isLoggingOut) {
      toast.info('Вы успешно вышли из аккаунта');
      modalStore.closeUserControl();
    }
  };

  return (
    <S.UserControl is_open={`${modalStore.isUserControlOpen}`} className="user-control">
      {authStore.isLoggingOut ? (
        <Loader />
      ) : (
        <>
          <S.UserControlName>{authStore.userInfo?.name}</S.UserControlName>
          <S.UserControlLogout onClick={handleLogout}>
            Выйти из аккаунта
            <S.UserControlArrow icon="arrow" />
          </S.UserControlLogout>
        </>
      )}
    </S.UserControl>
  );
});

export default UserControl;
