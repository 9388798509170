import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'ui-kit/Button';
import Sprite from 'ui-kit/Sprite';

import { commonImageStyles } from 'shared/helpers/generalStyles';
import { pxToVw } from 'shared/helpers/pxToVw';
import { truncateMultiLine } from 'shared/helpers/truncateMultiLine';

export const Card = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: ${pxToVw(1)};
  background-color: var(--white);
  border-radius: ${pxToVw(16)};
  transition: box-shadow 0.2s ease-in-out;
`;

export const Media = styled(Link)`
  flex-shrink: 0;
  width: 100%;
  max-width: ${pxToVw(125)};
  height: ${pxToVw(125)};
  padding: ${pxToVw(8)};
`;

export const Img = styled.img`
  display: block;
  ${commonImageStyles};
`;

export const Content = styled.div`
  padding: ${pxToVw(14)} ${pxToVw(16)};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Title = styled(Link)`
  display: block;
  width: 100%;
  margin-bottom: ${pxToVw(16)};
  color: inherit;
  font-size: ${pxToVw(13)};
  line-height: ${pxToVw(18)};
  ${truncateMultiLine(3)}
`;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: auto;
`;

export const Price = styled.div`
  flex-grow: 1;
  padding-right: ${pxToVw(4)};
  font-family: var(--font-family-loos);
  line-height: 1;
`;

export const Cost = styled.div`
  font-weight: 700;
  font-size: ${pxToVw(16)};
`;

export const CostOLd = styled.del`
  display: block;
  margin-top: ${pxToVw(6)};
  color: var(--text-tertiary);
  font-weight: 700;
  font-size: ${pxToVw(12)};
`;

export const Btn = styled(Button)`
  min-width: ${pxToVw(48)};
  height: ${pxToVw(32)};
`;

export const BtnCart = styled(Btn)`
  background-color: var(--primary);
  margin-right: ${pxToVw(-2)};
`;

export const Icon = styled(Sprite)`
  width: ${pxToVw(16)};
  height: ${pxToVw(16)};
  pointer-events: none;
  fill: var(--text-secondary);
`;

export const IconWhite = styled(Icon)`
  fill: var(--white);
`;
