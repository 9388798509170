import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import noPhoto from 'assets/img/no-photos.png';
import { observer } from 'mobx-react-lite';
import Badge from 'ui-kit/Badge';

import CardStickerItem from 'components/CardStickerItem';

import { ButtonState } from 'shared/enum/enums';
import { formatNumberWithSpaces } from 'shared/helpers/formatNumberWithSpaces';
import { TextWarn } from 'shared/helpers/generalStyles';
import { extractPreviewProperties } from 'shared/helpers/productHelpers';
import { ProductCardProps } from 'shared/types/ProductType';

import rootStore from 'stores/RootStore';

import * as S from './styles';

const ProductCard: FC<ProductCardProps> = observer(({ data }) => {
  const [buttonState, setButtonState] = useState(ButtonState.IDLE);
  const [favoritesState, setFavoritesState] = useState(false);
  const [compareState, setCompareState] = useState(false);

  const extractedProperties = extractPreviewProperties(data);
  const { cartStore, favoritesStore, compareStore } = rootStore;
  const navigate = useNavigate();
  const { name: title, preview_picture: imageUrl, url, cost_params, id } = data;

  const costOld = cost_params?.cost === cost_params?.old_cost ? false : cost_params?.old_cost;
  const urlProduct = `${url}`;
  const outOfStock = buttonState === ButtonState.OUT_OF_STOCK;
  const inCart = buttonState === ButtonState.IN_CART;

  useEffect(() => {
    if (!favoritesStore.isFirstProduct(id)) {
      setFavoritesState(true);
    }
    if (!compareStore.isFirstProduct(id)) {
      setCompareState(true);
    }
    if (!cost_params?.in_stock) {
      setButtonState(ButtonState.OUT_OF_STOCK);
    } else if (!cartStore.isFirstProduct(id)) {
      setButtonState(ButtonState.IN_CART);
    }
  }, [cartStore, id, cost_params?.in_stock, favoritesStore, compareStore]);

  const handleAddToCart = async () => {
    if (inCart) {
      navigate('/cart');
    } else if (outOfStock) {
      navigate(urlProduct);
    } else {
      setButtonState(ButtonState.LOADING);
      if (await cartStore.addProductToCart(data)) {
        setButtonState(ButtonState.IN_CART);
      } else {
        setButtonState(ButtonState.IDLE);
      }
    }
  };

  const handleAddToFavorites = () => {
    if (favoritesState) {
      favoritesStore.deleteProductFromFavorite(data);
      setFavoritesState(false);
    } else if (!favoritesState) {
      favoritesStore.addProductToFavorite(data);
      setFavoritesState(true);
    }
  };

  const handleAddToCompare = () => {
    if (compareState) {
      compareStore.deleteProductFromCompare(data);
      setCompareState(false);
    } else if (!compareState) {
      compareStore.addProductToCompare(data);
      setCompareState(true);
    }
  };

  const buttonVariant = outOfStock || inCart ? 'secondary' : 'primary';

  return (
    <S.Card>
      <S.CardMedia to={`${urlProduct}`}>
        <S.CardImg loading="lazy" src={imageUrl ? imageUrl : noPhoto} alt={title} />
      </S.CardMedia>

      <S.CardMeta className="list-unstyled">
        <S.CardMetaItem>
          <S.CardMetaLink to={urlProduct}>
            <S.CardMetaStar icon="star" />
            {extractedProperties?.rating}
          </S.CardMetaLink>
        </S.CardMetaItem>

        <S.CardMetaItem>
          <S.CardMetaLink to={urlProduct}>
            <S.CardMetaIcon icon="comment" className="comment" />
            {extractedProperties?.countReviews}
          </S.CardMetaLink>
        </S.CardMetaItem>

        <S.CardMetaItem onClick={handleAddToFavorites} className="toggle">
          <S.CardMetaIcon icon={favoritesState ? 'favorites' : 'favorite-stroke'} />
        </S.CardMetaItem>

        <S.CardMetaItem onClick={handleAddToCompare} className={'toggle'}>
          <S.CardMetaIconCompare icon="compare" className={compareState ? '' : 'icon'} />
        </S.CardMetaItem>
      </S.CardMeta>

      <S.CardContent>
        <div className="mb-auto">
          <S.CardTitle to={urlProduct}>{title}</S.CardTitle>
        </div>

        <S.CardBottom>
          <S.CardPrice>
            {cost_params?.cost && <S.CardCost>{formatNumberWithSpaces(cost_params?.cost)} ₽</S.CardCost>}
            {costOld ? <Badge color="warn">-{cost_params?.discount}%</Badge> : ''}
          </S.CardPrice>

          <S.CardPrice>
            {costOld ? <S.CardCostOLd>{formatNumberWithSpaces(costOld)} ₽</S.CardCostOLd> : ''}

            {Number(cost_params?.smarts) ? (
              <S.CardBonuses>
                +{cost_params?.smarts}
                <S.CardBonusesIcon icon="s" />
              </S.CardBonuses>
            ) : (
              ''
            )}
          </S.CardPrice>

          <S.CardFooter>
            <S.CardFooterContent>
              {cost_params?.in_stock ? 'В наличии' : <TextWarn>Нет в наличии</TextWarn>}
            </S.CardFooterContent>

            <S.CardBasket
              isLoading={buttonState === ButtonState.LOADING}
              className="add-to-cart-button"
              onClick={handleAddToCart}
              variant={buttonVariant}
              isIcon={true}
            >
              {inCart && ' В корзине'}
              {outOfStock && 'Подробнее'}
            </S.CardBasket>
          </S.CardFooter>
        </S.CardBottom>
      </S.CardContent>

      <S.CardSticker>
        {cost_params?.discount
          ? cost_params?.discount > 5 && (
              <CardStickerItem>
                <Badge color="warn">Распродажа</Badge>
              </CardStickerItem>
            )
          : ''}

        {extractedProperties.isNew && (
          <CardStickerItem>
            <Badge color="new">Новинка</Badge>
          </CardStickerItem>
        )}
      </S.CardSticker>
    </S.Card>
  );
});

export default ProductCard;
