import { ChangeEvent, FC } from 'react';
import { observer } from 'mobx-react-lite';
import Checkbox from 'ui-kit/Checkbox';

import CheckboxStore from 'stores/common/CheckboxStore';

import * as S from './styles';

type FilterWithSearchProps = {
  option: CheckboxStore;
  id: string;
  add: (value: CheckboxStore) => void;
  remove: (value: CheckboxStore) => void;
};

const FilterWithSearch: FC<FilterWithSearchProps> = observer(({ option, id, add, remove }) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    option.setValue(checked);
    checked ? add(option) : remove(option);
  };

  return (
    <S.Options disabled={option.active === false}>
      <Checkbox checked={option.value} onChange={onChange} id={id}>
        <S.Label>{option.label}</S.Label> <S.Count>{option.active_count ? option.active_count : option.count}</S.Count>
      </Checkbox>
    </S.Options>
  );
});

export default FilterWithSearch;
