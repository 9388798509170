import { FC } from 'react';
import spriteBg from 'assets/img/sprite-bg.svg';
import Sprite from 'ui-kit/Sprite';

import { PromotionsCardProps } from 'shared/types/PromotionsCardProps';

import * as S from './styles';

const bgColors = [
  '#1D00B0',
  '#0084BD',
  '#00A894',
  '#E27A18',
  '#D21C09',
  '#A22554',
  '#7048C6',
  '#E5AA01',
  '#282828',
  '#fc612e',
  '#8cca08',
];

const circleColors = [
  '#4652FF',
  '#59BBED',
  '#2FD1BE',
  '#FFA43E',
  '#F86968',
  '#E7558B',
  '#956CED',
  '#FFCA2A',
  '#cccccc',
  '#009e52',
  '#2fd1be',
];

const ShowcaseCard: FC<PromotionsCardProps> = ({ data, index }) => {
  const { url, image, name, fill = circleColors[0], bg = bgColors[0] } = data;
  return (
    <S.ShowcaseCard to={url} style={{ backgroundColor: index !== undefined ? bgColors[index] : bg }}>
      <S.ShowcaseCardTitle>{name}</S.ShowcaseCardTitle>

      <S.ShowcaseCardImg src={image} alt="" />

      <Sprite
        icons={spriteBg}
        icon="cat-bg"
        className="icon test"
        styles={{ fill: index !== undefined ? circleColors[index] : fill }}
      />
    </S.ShowcaseCard>
  );
};

export default ShowcaseCard;
