import styled from 'styled-components';
import Sprite from 'ui-kit/Sprite';

import { pxToVw } from 'shared/helpers/pxToVw';
import { ClassNameProps } from 'shared/types/ClassNameProps';

export const ProductPropertyContainer = styled.div`
  width: 100%;
  padding: ${pxToVw(12)} ${pxToVw(24)};
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: ${pxToVw(16)};
  color: var(--text-primary);
  gap: ${pxToVw(24)};
`;

export const CompareContainer = styled.div`
  min-width: ${pxToVw(450)};
  width: ${pxToVw(450)};
`;

export const CardMeta = styled.ul<ClassNameProps>`
  margin-bottom: ${pxToVw(16)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary);
  font-size: ${pxToVw(14)};
  line-height: 1;
`;

export const CardMetaItem = styled.li<ClassNameProps>`
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardMetaIcon = styled(Sprite)<ClassNameProps>`
  width: ${pxToVw(32)};
  height: ${pxToVw(32)};
  pointer-events: none;
  fill: var(--text-tertiary);
`;

export const CardMetaIconCompare = styled(CardMetaIcon)`
  width: ${pxToVw(20)};
`;
