import { FC, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { useButtonProps } from '@restart/ui/Button';

import { ButtonProps } from './ButtonTypes';
import { Btn, Icon } from './styles';

const Button: FC<ButtonProps> = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      to,
      as = to ? Link : 'button',
      children,
      variant = 'primary',
      size = 'sm',
      active,
      disabled = false,
      className,
      isLoading = false,
      isIcon = false,
      ...props
    },
    ref
  ) => {
    const [buttonProps] = useButtonProps({
      disabled,
      ...props,
    });

    return (
      <Btn
        as={as as never}
        {...buttonProps}
        {...props}
        ref={ref}
        variant={variant}
        size={size}
        active={active}
        className={className}
        disabled={disabled}
      >
        {isIcon ? <Icon icon="basket" variant={variant} /> : ''}
        {isLoading ? 'Добавление...' : children}
      </Btn>
    );
  }
);

export default Button;
