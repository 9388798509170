import { toast } from 'react-toastify';
import {
  addProductToFavoritesApi,
  deleteProductFromFavoritesApi,
  getFavoritesApi,
} from 'entities/Favorites/services/favoritesApi';
import { makeAutoObservable, runInAction } from 'mobx';

import { ProductData } from 'shared/types/ProductDetailsType';
import { ProductType, totalProps } from 'shared/types/ProductType';

import { RootStore } from 'stores/RootStore';

class FavoritesStore {
  favoritesData: ProductType[] = [];
  total: totalProps = { cost: 0, costOld: 0, quantity: 0 };
  isLoading = false;
  error: Error | null = null;

  constructor(private rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getFavorites = () => {
    const favorites = JSON.parse(localStorage.getItem('favorites') || '[]');
    runInAction(() => {
      this.favoritesData = favorites;
      this.getTotalFromFavorites(this.favoritesData);
      this.formattedProductsFromOffers(this.favoritesData);
    });
  };

  fetchFavoritesInAuth = async () => {
    try {
      this.isLoading = true;
      const response = await getFavoritesApi();
      const data = response.data.data;

      if (data.error) {
        this.error = new Error(data.error);
        this.isLoading = false;
        return;
      }
      runInAction(() => {
        this.favoritesData = data.elements;
        this.getTotalFromFavorites(data.elements);
        this.formattedProductsFromOffers(data.elements);
        this.isLoading = false;
      });
    } catch (error) {
      this.error = error instanceof Error ? error : new Error('An unexpected error occurred');
      this.isLoading = false;
    }
  };

  addProductToFavorite = async (productCard: ProductType): Promise<boolean> => {
    let isAddToFavorite = false;

    isAddToFavorite = true;
    this.favoritesData.push(productCard);
    this.getTotalFromFavorites(this.favoritesData);
    this.formattedProductsFromOffers(this.favoritesData);

    return isAddToFavorite;
  };

  isFirstProduct = (id: number) => {
    return !this.favoritesData.some((item) => item.id === id);
  };

  addProductToFavoriteInAuth = async (productCard: ProductType): Promise<boolean> => {
    let isAddToFavorite = false;
    this.isLoading = true;
    try {
      const response = await addProductToFavoritesApi(String(productCard.id));
      const data = response.data.data;
      if (data.error) {
        isAddToFavorite = false;
        this.isLoading = false;
        toast.error(data.error);
        this.error = new Error(data.error);
        return false;
      }
      runInAction(() => {
        isAddToFavorite = true;
        this.isLoading = false;
        this.favoritesData.push(productCard);
      });
    } catch (error) {
      this.isLoading = false;
      this.error = error instanceof Error ? error : new Error('An unexpected error occurred');
      isAddToFavorite = false;
    }

    return isAddToFavorite;
  };

  deleteProductFromFavorite = (productCard: ProductType | ProductData) => {
    const filterData = this.favoritesData.filter((item: ProductType) => item.id !== productCard.id);
    runInAction(() => {
      this.favoritesData = filterData;
      this.getTotalFromFavorites(filterData);
      this.formattedProductsFromOffers(filterData);
    });
  };

  deleteProductFromFavoriveInAuth = async (productCard: ProductType) => {
    try {
      this.isLoading = true;
      const response = await deleteProductFromFavoritesApi(String(productCard.id));
      const data = response.data.data;
      if (data.error) {
        this.isLoading = false;
        this.error = new Error(data.error);
        return;
      }
      runInAction(() => {
        this.isLoading = false;
        this.favoritesData = response.data.data;
        this.getTotalFromFavorites(response.data.data);
      });
    } catch (error) {
      this.isLoading = false;
      this.error = error instanceof Error ? error : new Error('An unexpected error occurred');
    }
  };

  getTotalFromFavorites = (productsData: ProductType[]) => {
    const total = productsData.reduce(
      (acc: totalProps, value) => {
        acc['quantity'] += 1;
        acc['cost'] += (value.cost_params?.cost || 0) * 1;
        acc['costOld'] += (value.cost_params?.old_cost || 0) * 1;
        return acc;
      },
      { cost: 0, costOld: 0, quantity: 0 }
    );

    runInAction(() => {
      this.total = total;
    });
  };

  formattedProductsFromOffers = (productsData: ProductType[]) => {
    const formatted = productsData.reduce((acc: ProductType[], value: ProductType) => {
      return [
        //временное решение, нужно чтобы getFavorites стал post запросом
        ...acc,
        {
          id: value.id,
          cost_params: value.cost_params,
          detail_picture: value.detail_picture,
          name: value.name,
          preview_picture: value.preview_picture,
          preview_properties: value.preview_properties,
          url: value.url,
          xml_id: value.xml_id,
        },
      ];
    }, []);

    localStorage.setItem('favorites', JSON.stringify(formatted));
  };
}

export default FavoritesStore;
