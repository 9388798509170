import { FC } from 'react';
import { ProductTypeFromCart } from 'entities/Cart/types';

import * as S from './styles';

type MiniCartItemProps = {
  item: ProductTypeFromCart;
  onClose: () => void;
};

const MiniCartItem: FC<MiniCartItemProps> = ({ item, onClose }) => {
  return (
    <S.StyledDrop key={item.id}>
      <S.ContentItemLink onClick={onClose} to={`${item.url}`}>
        <S.ImageBlock>
          <S.ContentImage src={item.preview_picture} />
        </S.ImageBlock>
        <S.ContentName>{item.name}</S.ContentName>
      </S.ContentItemLink>
    </S.StyledDrop>
  );
};

export default MiniCartItem;
