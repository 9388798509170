const MAX_PRODUCTS = 30;

export const updateViewedProducts = (productId: string): void => {
  const viewedProducts = JSON.parse(localStorage.getItem('viewedProducts') || '[]');
  const productIndex = viewedProducts.findIndex((id: string) => id === productId);

  // Удаляем продукт, если он уже был в списке, чтобы перенести его в начало
  if (productIndex > -1) viewedProducts.splice(productIndex, 1);

  // Добавляем новый ID в начало массива
  viewedProducts.unshift(productId);

  // Удаляем последний элемент, если превышен лимит
  if (viewedProducts.length > MAX_PRODUCTS) viewedProducts.pop();

  localStorage.setItem('viewedProducts', JSON.stringify(viewedProducts));
};
