import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { pxToVw } from 'shared/helpers/pxToVw';
import { ClassNameProps } from 'shared/types/ClassNameProps';

export const ShowcaseCard = styled(Link)<ClassNameProps>`
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: ${pxToVw(16)};
  height: ${pxToVw(167)};

  .icon {
    transform-origin: bottom right;
    right: ${pxToVw(-52)};
    bottom: ${pxToVw(-67)};
    width: ${pxToVw(233)};
    height: ${pxToVw(330)};
    position: absolute;
  }
`;

export const ShowcaseCardTitle = styled.div<ClassNameProps>`
  width: ${pxToVw(215)};
  padding: ${pxToVw(32)} ${pxToVw(24)} 0;
  line-height: ${pxToVw(28)};
  margin-bottom: ${pxToVw(12)};
  font-size: ${pxToVw(20)};
  letter-spacing: 0.02em;
  position: relative;
  font-weight: 500;
  color: var(--white);
  font-family: var(--font-family-loos);
  z-index: 2;
`;

export const ShowcaseCardImg = styled.img<ClassNameProps>`
  max-height: 90%;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: block;
  position: absolute;
`;
