import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Accordion from 'ui-kit/Accordion';

import { IFiltersStoreType } from 'shared/types/ProductType';

import FilterWithSearch from './FilterWithSearch';
import { FilterRange } from './PriceFilter';
import * as S from './styles';

type FiltersCatalogProps = {
  accordionItem?: IFiltersStoreType;
};

const FiltersCatalog: FC<FiltersCatalogProps> = observer(({ accordionItem }) => {
  // обработка значений для умного фильтра, switch(display_type)...
  if (!accordionItem) {
    return null;
  }

  switch (accordionItem.display_type) {
    case 'B':
      return (
        <S.SectionBlock>
          <FilterRange
            name={accordionItem.name}
            onChange={accordionItem.changeToRange}
            selectedValues={accordionItem.selectedValues}
            values={accordionItem.values}
          />
        </S.SectionBlock>
      );
    case 'F':
    case 'K':
      return (
        <Accordion key={accordionItem.id} title={accordionItem.name}>
          {accordionItem.values?.map((option, index: number) => (
            <FilterWithSearch
              add={accordionItem.addToFilter}
              remove={accordionItem.removeFromFilter}
              id={`${accordionItem.id}_${index}`}
              key={`${accordionItem.id}_${index}`}
              option={option}
            />
          ))}
        </Accordion>
      );
    default:
      return null;
  }
});

export default FiltersCatalog;
