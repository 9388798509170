import { FC } from 'react';

import * as S from './styles';

type ProductStickersProps = {
  className?: string;
  size?: 'large' | 'small';
};

const ProductStickers: FC<ProductStickersProps> = ({ className, size = 'large' }) => {
  return (
    <S.Stickers className={className}>
      <S.StickersBadge size={size} style={{ backgroundColor: '#fc612e' }}>
        Распродажа
      </S.StickersBadge>
      <S.StickersBadge size={size} style={{ backgroundColor: '#009e52' }}>
        Скидка на комплект 15%
      </S.StickersBadge>
      <S.StickersBadge size={size} style={{ backgroundColor: '#956ced' }}>
        Рассрочка 0-0-6
      </S.StickersBadge>
      <S.StickersBadge size={size} style={{ backgroundColor: '#fc612e' }}>
        Распродажа
      </S.StickersBadge>
      <S.StickersBadge size={size} style={{ backgroundColor: '#8cca08' }}>
        Новинка
      </S.StickersBadge>
      <S.StickersBadge size={size} style={{ backgroundColor: '#57d08e' }}>
        0-0-6 Рассрочка
      </S.StickersBadge>
      <S.StickersBadge size={size} style={{ backgroundColor: '#956ced' }}>
        Акция Aquara
      </S.StickersBadge>
      <S.StickersBadge size={size} style={{ backgroundColor: '#2fd1be' }}>
        5 мин назад купили
      </S.StickersBadge>
      <S.StickersBadge size={size} style={{ backgroundColor: '#4652ff' }}>
        -40% на Philps
      </S.StickersBadge>
    </S.Stickers>
  );
};

export default ProductStickers;
