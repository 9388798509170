import styled from 'styled-components';
import Sprite from 'ui-kit/Sprite';

import { pxToVw } from 'shared/helpers/pxToVw';

export const UserControl = styled.div<{ is_open: boolean }>`
  position: absolute;
  right: ${pxToVw(-32)};
  width: ${pxToVw(359)};
  padding: ${pxToVw(24)};
  background-color: var(--white);
  border-radius: ${pxToVw(16)};
  box-shadow: var(--shadow-Z100);
  top: 100%;
  z-index: 5;
  display: none;

  ${({ is_open }: { is_open: boolean }) => is_open && `display: block;`}

  &::before {
    top: ${pxToVw(-7)};
    right: ${pxToVw(40)};
    width: ${pxToVw(16)};
    box-shadow: var(--shadow-Z100);
    transform: rotate(45deg);
    position: absolute;
    height: ${pxToVw(16)};
    background-color: var(--white);
    border-radius: ${pxToVw(2)};
    content: '';
  }
`;

export const UserControlName = styled.div`
  display: block;
  margin-bottom: ${pxToVw(10)};
  padding-bottom: ${pxToVw(24)};
  border-bottom: 1px solid var(--stroke);
  color: var(--text-primary);
  font-weight: 700;
  font-size: ${pxToVw(18)};
  line-height: ${pxToVw(24)};
`;

export const UserControlLogout = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 2px 0;
  letter-spacing: 0.02em;
  color: var(--primary);
  font-weight: 700;
`;

export const UserControlArrow = styled(Sprite)`
  width: ${pxToVw(24)};
  height: ${pxToVw(24)};
  margin-left: ${pxToVw(8)};
  fill: var(--primary);
`;
